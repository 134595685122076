import React, { useEffect, useState } from "react";
import MainRoute from "./core/routes/routes";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/Theme";
import { CurrencyContextProvider } from "./context/CurrencyContext";
import { AlertContextProvider } from "./context/AlertContext";
import { CountryContextProvider } from "./context/CountryContext";
import { queryClient } from "./core/react-query/queryClient";
import { QueryClientProvider } from "@tanstack/react-query";
import { CookiesProvider } from "react-cookie";
import { UserContextProvider } from "./context/UserContext";
import { GlobalProvider } from "./context/GlobalContext";
import { LanguageProvider } from "./context/LanguageContext";
import {
  Experimental_CssVarsProvider as CssVarsProvider,
  experimental_extendTheme as extendMuiTheme,
} from "@mui/material/styles";
import i18n from "./i18n";

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CookiesProvider>
        <CssVarsProvider>
          <GlobalProvider>
            <LanguageProvider>
              <UserContextProvider>
                <QueryClientProvider client={queryClient}>
                  <AlertContextProvider>
                    <CountryContextProvider>
                      <CurrencyContextProvider>
                        <MainRoute />
                      </CurrencyContextProvider>
                    </CountryContextProvider>
                  </AlertContextProvider>
                </QueryClientProvider>
              </UserContextProvider>
            </LanguageProvider>
          </GlobalProvider>
        </CssVarsProvider>
      </CookiesProvider>
    </ThemeProvider>
  );
};

export default App;
