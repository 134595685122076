import { Link, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import RootLayout from "./rootLayout/RootLayout";

export const ProtectedLayout = () => {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <RootLayout>
      <Outlet />
    </RootLayout>
  );
};
