import React from "react";
import "./sidebox.scss";
import { Skeleton } from "@mui/material";
import NoImageFound from "../../assests/Images/NoImageFound.png";
import { useTranslation } from "react-i18next";
import { colors } from "../../core/variables/static";
import { MedalWOBackgroundSVG, RecyclableSVG } from "../../assests/icons/SVG";
import { useGlobalLangValues } from "../../context/LanguageContext";
import { CConverter } from "../../core/functions/CurrencyConversion";
import ShowMoreText from "../textExpanded/ShowMoreText";

const SideBoxComponent = (props) => {
  // Destructuring props and hooks
  const { t } = useTranslation(); // Translation function
  const {
    array,
    deviceImage,
    title,
    loading,
    condition,
    price,
    attributes,
    subcategory,
    product,
  } = props; // Destructuring props

  const { selectedLanguage, isArabic } = useGlobalLangValues(); // Custom hook for global language values

  return (
    // Container div with conditional class based on language
    <div className={`sideBox-component ${isArabic ? "arabic-component" : ""}`}>
      {/* Title section with translation or skeleton loader if loading */}
      <div className="sideBox-title">
        {loading ? <Skeleton width="200px" /> : t(title)}
      </div>

      {/* Device image section with image or placeholder if loading */}
      {loading ? (
        <Skeleton
          className="img"
          variant="rectangular"
          width="90%"
          height="40%"
        />
      ) : (
        <img
          src={
            deviceImage
              ? process.env.REACT_APP_IMAGE_URL + deviceImage
              : NoImageFound
          }
          alt="Device"
          className="sideBox-img"
        />
      )}

      {/* Condition section with medal or recyclable SVG based on condition ID */}
      <div className="sidebox-Condition">
        {condition?.id != 7 ? (
          <MedalWOBackgroundSVG
            className="medalsvg"
            color={colors.find((e) => e?.label === condition.name)?.color}
          />
        ) : (
          <RecyclableSVG className="RecyclableSVG" />
        )}

        {/* Condition name and price display with translation */}
        <p className="condition-val">
          {condition?.id == 7
            ? t("Does not turn on")
            : t(condition?.name) + " "}
          {CConverter(price) || "N/A"}
        </p>
      </div>

      {/* Bottom section displaying dynamic key-value pairs or skeleton loaders if loading */}
      <div className="bottom">
        {!loading && array
          ? Object.entries(array).map(([key, value]) => (
              <div className="Row" key={key}>
                <div className={isArabic ? "key width40" : "key"}>
                  {/* Translating the key (or 'Model' if it's 'subcategory') */}
                  {t(key === "subcategory" ? "" : key)}
                </div>
                {key === "subcategory" ? undefined : (
                  <ShowMoreText
                    className="Value"
                    initialText={key === "product"  ? title : t(value)}
                    maxLength={20}
                  />
                )}
              </div>
            ))
          : // Render 6 skeleton rows if loading
            [...Array(6)].map((_, index) => (
              <Skeleton
                key={index}
                variant="text"
                className="Row"
                width="90%"
              />
            ))}
        {attributes &&
          Object.entries(attributes).map(([key, value]) => (
            <div className="Row" key={key}>
              <div className={isArabic ? "key width40" : "key"}>
                {value.question?.[selectedLanguage]}
              </div>

              <ShowMoreText
                className="Value"
                initialText={value.name?.[selectedLanguage]}
                maxLength={20}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default SideBoxComponent;
