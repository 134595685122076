import React, { Fragment, useContext, useRef, useState } from "react";
import "./PaymentMethod.scss";
import Button from "../../components/button/Button";
import ReactToPrint from "react-to-print";
import { Disclaimer } from "../../components/disclaimer/Disclaimer";
import { useLocation, useNavigate } from "react-router-dom";
import { BackgroundSVG, GiftSVG } from "./paymentMethodSVG";
import { PostpaidSVG, PrepaidSVG } from "./paymentMethodSVG";
import { LogoWithTextSVG } from "../../assests/icons/SVG";
import Barcode from "react-barcode";
import { Barcodee } from "../inspection/fillForm/du/PrintedBarcode";
import { selldevice } from "../../core/apis/sellmydevice";
import { DialogTitle, Button as MUIButton } from "@mui/material";
import { Dialog, DialogActions } from "@mui/material";
import { senddisclaimer } from "../../core/apis/transactions";
import { AlertContext } from "../../context/AlertContext";

const PaymentMethod = () => {
  const { setAlert } = useContext(AlertContext);
  let navigate = useNavigate();
  let { state } = useLocation();
  const printRef = useRef();
  const componentRef = useRef();
  const [step, setStep] = useState(2);
  const [result, setResult] = useState({});
  const [areyousure, setareyousure] = useState(false);
  const [loading, setLoading] = useState(false);

  let useremail = state?.payload?.fill_form?.email;


  const barcodeRef = useRef();
  const barprintRef = useRef();

  const handleContinue = () => {
    setareyousure(false);
    setLoading(true);
    let payload = {
      ...state?.payload,
    };
    payload.fill_form["du_plan_id"] = step;
    selldevice(payload).then((res) => {
      setResult(res?.data?.data || {});
      setAlert({
        visible: true,
        type: !res.data.success ? "error" : "success",
        text: !res.data.success
          ? res.data.message
          : "Your sales has been Successfully Placed ",
      });
      res.data.success &&
        setTimeout(() => {
          handlesendbyemail();
          //printRef?.current?.handleClick();
        }, 50);
      setLoading(false);
    });
  };
  const text = result?.transaction_details?.PaynetTransaction?.receipt.info
    .split("\n")
    .map((i) => i.split(": "))
    .flat();
  const receiptNumber =
    result?.transaction_details?.PaynetTransaction?.receipt.footer
      .split("RECEIPT NUMBER: ")?.[1]
      .slice(0, 19);
  const userDetails = result?.transaction_details?.fill_form;
  const retailerDetails = result?.transaction_details?.user;

  const handlesendbyemail = () => {
    var Day = document.createElement("div");
    Day.appendChild(document.getElementById("tobesent"));
    let data = {
      text: Day.innerHTML,
      email: useremail,
    };
    senddisclaimer(data).then((res) => {
      if (res?.data?.success) {
        printRef?.current?.handleClick();
      } else alert(res.data.message);
    });
  };

  return (
    <>
      <Disclaimer ref={componentRef} header>
        <div className="PrepaidPaperPrint" id="PrepaidPaperPrint">
          <h1>Order #{result?.transaction_details?.id}</h1>
          <h4>Congratulations!</h4>
          {step === 1 ? (
            <div className="postPaidInfo" id="postPaidInfo">
              <div className="entity">
                <span>{text?.[2]}</span>
                <span>{text?.[3]}</span>
              </div>
              <div className="entity">
                <span>{text?.[4]}</span>
                <span>{text?.[5]}</span>
              </div>
              <div className="entity">
                <span>{text?.[6]}</span>
                <span>{text?.[7]}</span>
              </div>
              <div className="entity">
                <span>{text?.[8]}</span>
                <span>{text?.[9]}</span>
              </div>
              <div className="entity">
                <span>{"Receipt Number"}</span>
                <span>{receiptNumber}</span>
              </div>
            </div>
          ) : (
            <>
              <div className="voucher" id="voucher">
                <div className="voucherBody">
                  <BackgroundSVG className="backgroundSVG" />
                  <GiftSVG className="GiftSVG" />
                  <LogoWithTextSVG className="LogoWithTextSVG" />
                  <i>
                    <h4>
                      <b className="currency">
                        {" "}
                        {
                          result?.transaction_details?.inspection[0]
                            ?.inspection_value
                        }
                      </b>
                    </h4>
                  </i>
                  <h4>Congratulations!</h4>
                </div>
                <hr />
                <div className="voucherCode">
                  <span>Voucher Code</span>
                  <span>{result?.transaction_details?.barcode}</span>
                </div>
              </div>
            </>
          )}
          <p className="barcode">
            <Barcode
              value={result?.transaction_details?.barcode}
              textPosition={"left"}
              fontSize={10}
              height={25}
              margin={0}
            />
          </p>

          <div className="tablePaperPrint">
            <span className="tablePaperPrintHeader">User Details</span>
            <span className="tablePaperPrintBody">
              <div className="entity">
                <p>FullName</p>
                <p>{userDetails?.full_name} </p>
              </div>
              <div className="entity">
                <p>Email</p>
                <p>{userDetails?.email} </p>
              </div>
              <div className="entity">
                <p>Phone Number</p>
                <p>{userDetails?.phone_number}</p>
              </div>
              <div className="entity">
                <p>ID or Passport Number</p>
                <p>{userDetails?.id_or_passport_number}</p>
              </div>
            </span>
          </div>
          <div className="tablePaperPrint">
            <span className="tablePaperPrintHeader">2USE Details</span>
            <span className="tablePaperPrintBody">
              <div className="entity">
                <p>Name</p>
                <p className="barcode">{retailerDetails?.full_name} </p>
              </div>
              <div className="entity">
                <p>Email</p>
                <p className="barcode">{retailerDetails?.email} </p>
              </div>
              <div className="entity">
                <p>Phone Number</p>
                <p className="barcode">
                  {retailerDetails?.country?.dial_code +
                    retailerDetails?.phone_number}
                </p>
              </div>
            </span>
          </div>
          <div className="tobesent" id="tobesent">
            <h1>Order #{result?.transaction_details?.id}</h1>
            <h4>Congratulations!</h4>
            {step === 1 ? (
              <div className="postPaidInfo" id="postPaidInfo">
                <div className="entity">
                  <span>{text?.[2]}</span>
                  <span className="mx-2">{text?.[3]}</span>
                </div>
                <div className="entity">
                  <span>{text?.[4]}</span>
                  <span className="mx-2">{text?.[5]}</span>
                </div>
                <div className="entity">
                  <span>{text?.[6]}</span>
                  <span className="mx-2">{text?.[7]}</span>
                </div>
                <div className="entity">
                  <span>{text?.[8]}</span>
                  <span className="mx-2"> {text?.[9]}</span>
                </div>
                <div className="entity">
                  <span>{"Receipt Number"}</span>
                  <span className="mx-2">{receiptNumber}</span>
                </div>
              </div>
            ) : (
              <>
                <div className="voucher" id="voucher">
                  <div className="voucherBody">
                    <i>
                      <h3>
                        <b>Amount </b>
                        <b className="currency ">
                          {
                            result?.transaction_details?.inspection[0]
                              ?.inspection_value
                          }
                        </b>
                      </h3>
                    </i>
                  </div>
                  <hr />
                  <div className="voucherCode">
                    <span>
                      <b>Voucher Code </b>
                      <span>{result?.transaction_details?.barcode}</span>
                    </span>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Disclaimer>

      <ReactToPrint
        trigger={() => <Fragment />}
        content={() => componentRef.current}
        ref={printRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            barprintRef.current?.handleClick();
          }, 500);
        }}
      />
      <ReactToPrint
        trigger={() => <Fragment />}
        content={() => barcodeRef.current}
        ref={barprintRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            navigate("/sell");
          }, 500);
        }}
      />
      <Barcodee ref={barcodeRef} data={result}></Barcodee>

      <div className="PaymentMethod">
        <h1>Payment Method</h1>
        <div className="boxes">
          <MUIButton
            aria-label="navigate to Prepaid"
            className={`box ${step === 2 ? "selected" : ""} `}
            onClick={() => setStep(2)}
          >
            <PrepaidSVG width="250" height="250" className="picture" />
            <p> Prepaid</p>
          </MUIButton>
          <MUIButton
            aria-label="navigate to Pospaid"
            className={`box ${step === 1 ? "selected" : ""} `}
            onClick={() => setStep(1)}
          >
            <PostpaidSVG width="250" height="250" className="picture" />
            <p> Postpaid</p>
          </MUIButton>
        </div>
        <Button
          className="continueButton"
          name="Place Order"
          onClick={() => setareyousure(!areyousure)}
          selected
          width="30%"
          loading={loading}
        />
      </div>

      <Dialog
        open={areyousure}
        keepMounted
        className="selectdialogDisclaimerr"
        maxWidth="md"
      >
        <DialogTitle className="dialogTitle m-4">
          Are you sure you want to continue ?
        </DialogTitle>
        <DialogActions className="dialogAction my-1">
          <Button
            selected
            name="Continue"
            width="100%"
            onClick={handleContinue}
          />
          <Button
            name="Cancel"
            width="100%"
            onClick={() => navigate("/sell")}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentMethod;
