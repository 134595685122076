import { useContext } from "react";
import { Skeleton } from "@mui/material";
import { defaultCurrencyCode } from "../variables/variables";
import { CurrencyContext } from "../../context/CurrencyContext";

export const CConverter = (price, noUnit) => {
  const { currency, rate, loadingCurrency } = useContext(CurrencyContext);

  return !loadingCurrency ? (
    ` ${
      currency?.code === defaultCurrencyCode
        ? +Number(price)?.toFixed(0) + " "
        : ((+price * +rate) / 1)?.toFixed(0) + " "
    }  ${noUnit ? "" : currency?.code}`
  ) : (
    <Skeleton width={70} />
  );
};
