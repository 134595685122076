import { api } from "./main";

export const getCategoryByBrandSlug = async (slug, payload) => {
  return await api
    .get(`retailer/get-category-by-brand-slug/${slug}`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getCategoryBySlug = async (slug, payload) => {
  return await api
    .get(`retailer/get-category-by-slug/${slug}`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getSubCategoryBySlug = async (sub_category_slug, payload) => {
  return await api
    .get(
      `retailer/products/${sub_category_slug}?country_id=${payload.country_id}&brand_slug=${payload.brand_slug}`
    )
    .then((res) => res)
    .catch((err) => console.error(err));
};
