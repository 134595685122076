import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { LogoutSVG, UKFlagSVG, TranslationSVG } from "../../assests/icons/SVG";
import {
  RoundedUser,
  Bellsvg,
  AccountDetailsSVG,
} from "../../assests/icons/SVG";

import { Button, Popover } from "@mui/material";
import { isUserLoggedIn } from "../../core/apis/auth";
import DialogComponent from "../../components/modal/Modal";
import "./Header.scss";
import { useAuth } from "../../hooks/useAuth";
import Logo from "../../assests/Images/2useLogo.png";
import Image from "../../components/image/Image";
import { useGlobalValues } from "../../context/GlobalContext";
import { useGlobalLangValues } from "../../context/LanguageContext";
import { UserContext } from "../../context/UserContext";
import { useTranslation } from "react-i18next";
import SDFlag from "../../assests/Images/image.png";
import WorldFlag from "../../assests/Images/worldwide.png";
import franceFlag from "../../assests/Images/france.png";
import NotificationList from "../../pages/Notifications/notificationsList";

const Header = () => {
  const { logout } = useAuth();

  const { i18n, t } = useTranslation();

  const { selectedLanguage, isArabic, enabledLanguages } =
    useGlobalLangValues();

  const { Oragnizationimages } = useGlobalValues();

  const { userName } = useContext(UserContext);

  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [userAnchorEl, setUserAnchorEl] = useState(null);
  const [notificationAnchorEl, setnotificationAnchorEl] = useState(null);
  const [langAnchorEl, setLangAnchorEl] = useState(null);
  const [show, setShow] = useState(false);

  const handleUserPopoverClick = (event) =>
    setUserAnchorEl(event.currentTarget);
  const handleLangPopoverClick = (event) =>
    setLangAnchorEl(event.currentTarget);

  const handleNotificationPopoverClick = (event) =>
    setnotificationAnchorEl(event.currentTarget);

  const handleUserPopoverClose = () => setUserAnchorEl(null);
  const handleLangPopoverClose = () => setLangAnchorEl(null);
  const handleNotificationPopoverClose = () => setnotificationAnchorEl(null);

  const handleModal = async () => {
    setLoading(true);
    logout().then((res) => {
      res && navigate("/login");
      res && setShow(false);
      setLoading(false);
    });
  };

  const [arabicComponent, setArabicComponent] = useState(false);

  useEffect(() => {
    setArabicComponent(i18n.language === "ar");
  }, [i18n.language]);

  return (
    <div className={`headerr ${arabicComponent ? "arabic-component" : ""}`}>
      <div className="maincompany-logo">
        <NavLink
          to={isUserLoggedIn() ? "/" : "/login"}
          aria-label="navigate to Sell Flow"
        >
          <Image width="150" className="picture" src={Logo} alt="logo" />
        </NavLink>
      </div>

      {isUserLoggedIn() && Oragnizationimages ? (
        <div
          className={`secondcompany-logo ${
            arabicComponent ? "mrRight" : "mleft"
          }`}
        >
          <Image
            className="company_logo"
            src={Oragnizationimages?.logo}
            alt="logo"
          />
        </div>
      ) : undefined}

      {isUserLoggedIn() ? (
        <div className="third-section">
          <>
            <Button
              onClick={handleLangPopoverClick}
              className={`name ${arabicComponent ? "item-right" : "item"}`}
            >
              {selectedLanguage === "en" ? (
                <>
                  <span className="langCode">
                    {selectedLanguage.toUpperCase()}
                  </span>
                  <UKFlagSVG />
                </>
              ) : selectedLanguage === "ar" ? (
                <>
                  <img src={SDFlag} width="30" />
                  <span className="langCode">عربي</span>
                </>
              ) : selectedLanguage === "fr" ? (
                <>
                  <span className="langCode">
                    {selectedLanguage.toUpperCase()}
                  </span>
                  <img src={franceFlag} width="30" />
                </>
              ) : (
                <>
                  <span className="langCode">
                    {selectedLanguage.toUpperCase()}
                  </span>
                  <TranslationSVG style={{ color: "#fff" }} />
                </>
              )}
            </Button>
            <Popover
              open={Boolean(langAnchorEl)}
              anchorEl={langAnchorEl}
              onClose={handleLangPopoverClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <div
                className={
                  isArabic
                    ? "languagePopover direction-right"
                    : "languagePopover"
                }
                style={{ display: "flex" }}
              >
                {enabledLanguages?.map((item) => (
                  <div key={item.code}>
                    {item.code === "en" && <UKFlagSVG />}
                    {item.code === "ar" && <img src={SDFlag} width="30" />}
                    {item.code === "fr" && <img src={franceFlag} width="30" />}
                    {item.code !== "ar" &&
                      item.code !== "en" &&
                      item.code !== "fr" && <img src={WorldFlag} width="30" />}
                    <div
                      onClick={() => {
                        i18n.changeLanguage(item.code);
                        handleLangPopoverClose();
                      }}
                      type="button"
                    >
                      {t(item.name)}
                    </div>
                  </div>
                ))}
              </div>
            </Popover>
          </>

          <Button
            size="small"
            onClick={handleNotificationPopoverClick}
            className={`name showItems ${
              arabicComponent ? "item-right" : "item"
            }`}
          >
            <Bellsvg style={{ color: "#fff" }} />
          </Button>

          <Popover
            open={Boolean(notificationAnchorEl)}
            anchorEl={notificationAnchorEl}
            onClose={handleNotificationPopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div
              className={
                isArabic
                  ? "notificationPopover direction-right"
                  : "notificationPopover"
              }
            >
              <NotificationList
                isPage={false}
                handleNavigate={() => {
                  navigate("/notifications");
                  handleNotificationPopoverClose();
                }}
              />
            </div>
          </Popover>
          <Button
            onClick={handleUserPopoverClick}
            size="small"
            className={`name  noitempad ${
              arabicComponent ? "item-right" : "item "
            }`}
          >
            {" "}
            <span
              className={`username showItems ${
                arabicComponent ? "item-right" : "item"
              }`}
            >
              {t("Hi") + " "}
              {userName ? userName : ""}
            </span>
            <RoundedUser style={{ color: "#fff" }} />
          </Button>
          <Popover
            open={Boolean(userAnchorEl)}
            anchorEl={userAnchorEl}
            onClose={handleUserPopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div
              style={{ display: "flex" }}
              className={
                isArabic ? "logoutPopover direction-right" : "logoutPopover"
              }
            >
              <div>
                <AccountDetailsSVG style={{ width: "25px", height: "unset" }} />
                <Link
                  to="/account-details/personal-information"
                  onClick={handleUserPopoverClose}
                >
                  {t("Account Details")}
                </Link>
              </div>
              <div
                style={{ borderBottom: "1px solid #a8a8a8" }}
                className="showBell"
              >
                <Bellsvg color="#4ba4f0" />
                <Link onClick={handleUserPopoverClose} to="/notifications">
                  {t("Notifications")}
                </Link>
              </div>
              <div
                style={{ cursor: "pointer" }}
                disabled
                onClick={() => {
                  handleUserPopoverClose();
                  setShow(true);
                }}
              >
                <LogoutSVG />
                {t("Log out")}
              </div>
            </div>
          </Popover>

          <DialogComponent
            open={show}
            handleOpenClose={() => setShow(!show)}
            buttonLabel={t("Log out")}
            handleModal={handleModal}
            loading={loading}
          >
            {t("Are you sure you want to log out?")}
          </DialogComponent>
        </div>
      ) : (
        <>
          <Button onClick={handleLangPopoverClick} className="name">
            {selectedLanguage === "en" ? (
              <>
                <span className="langCode">
                  {selectedLanguage.toUpperCase()}
                </span>
                <UKFlagSVG />
              </>
            ) : selectedLanguage === "ar" ? (
              <>
                <img src={SDFlag} width="30" />
                <span className="langCode">عربي</span>
              </>
            ) : selectedLanguage === "fr" ? (
              <>
                <span className="langCode">
                  {selectedLanguage.toUpperCase()}
                </span>
                <img src={franceFlag} width="30" />
              </>
            ) : (
              <>
                <span className="langCode">
                  {selectedLanguage.toUpperCase()}
                </span>
                <TranslationSVG style={{ color: "#fff" }} />
              </>
            )}
          </Button>
          <Popover
            open={Boolean(langAnchorEl)}
            anchorEl={langAnchorEl}
            onClose={handleLangPopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <div
              className={
                isArabic ? "languagePopover direction-right" : "languagePopover"
              }
              style={{ display: "flex" }}
            >
              {enabledLanguages?.map((item) => (
                <div key={item.code}>
                  {item.code === "en" && <UKFlagSVG />}
                  {item.code === "ar" && <img src={SDFlag} width="30" />}
                  {item.code === "fr" && <img src={franceFlag} width="30" />}
                  {item.code !== "ar" &&
                    item.code !== "en" &&
                    item.code !== "fr" && <img src={WorldFlag} width="30" />}
                  <div
                    onClick={() => {
                      i18n.changeLanguage(item.code);
                      handleLangPopoverClose();
                    }}
                    type="button"
                  >
                    {t(item.name)}
                  </div>
                </div>
              ))}
            </div>
          </Popover>
        </>
      )}
    </div>
  );
};

export default Header;
