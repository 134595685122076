import React, { forwardRef, useState, useEffect } from "react";
import NoImageFound from "../../assests/Images/NoImageFound.png";

const Image = forwardRef((props) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(false);
    setTimeout(() => {
      setLoaded(true);
    }, 600);
  }, []);

  return (
    <img
      loading="lazy"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = NoImageFound;
      }}
      {...props}
      style={{
        opacity: loaded ? 1 : 0,
        transition: "opacity 0.5s ease",
      }}
    />
  );
});

export default Image;
