import { createContext, useContext, useMemo } from "react";
import { useCookies } from "react-cookie";
import { AlertContext } from "../context/AlertContext";
import { UserContext } from "../context/UserContext";
import { CurrencyContext } from "../context/CurrencyContext";
import { api } from "../core/apis/main";
import { useTranslation } from "react-i18next";

const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
  const { setAlert } = useContext(AlertContext);
  const { getSelfUser } = useContext(UserContext);
  const [cookies, setCookies] = useCookies(["user"]);
  const { setCurrency } = useContext(CurrencyContext);

  const { t } = useTranslation();

  // call this function when you want to authenticate the user
  const login = async (data) => {
    return await api.post("auth/login", data).then((res) => {
      !Boolean(res?.data?.data) &&
        setAlert({
          visible: true,
          type: "error",
          text: t(res?.data?.message),
        });

      setCookies("user", Boolean(res?.data?.data));
      setCurrency(res?.data?.data?.currency);
      res?.data?.data && getSelfUser();

      return res;
    });
  };

  const logout = async () => {
    return await api.get("auth/logout").then((res) => {
      setCookies("user", false);
      return res;
    });
  };
  const value = useMemo(
    () => ({
      user: Boolean(JSON.parse(cookies?.user || null)),
      login,
      logout,
    }),
    [cookies?.user]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};
