import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  Fragment,
} from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Skeleton,
} from "@mui/material";
import { useGlobalValues } from "../../../context/GlobalContext";
import { getPiceaToken, getPiceaDeviceInfo } from "../../../core/apis/newapis";
import { selldevice } from "../../../core/apis/sellmydevice";
import { AlertContext } from "../../../context/AlertContext";
import { Barcode } from "../../../components/disclaimer/barcode";
import { Disclaimer } from "../../../components/disclaimer/Disclaimer";
import ReactToPrint from "react-to-print";
import Swal from "sweetalert2";

const WipingFlow = () => {
  let navigate = useNavigate();

  let location = useLocation();

  const { piceaWipeOrganizationWorkFlow, twoUseWipeWorkFlow , thirdPartyValue} =
    useGlobalValues();

  const [isLoading, setisLoading] = useState(false);

  const { setAlert } = useContext(AlertContext);

  const [result, setResult] = useState();

  const [Payload, setPayload] = useState({});

  const [deviceInfo, setdeviceInfo] = useState();

  const componentRef = useRef();
  const barcodeRef = useRef();
  const printRef = useRef();
  const barprintRef = useRef();

  let langu = sessionStorage.getItem("lang");
  let DuIntegration = +localStorage.getItem("integration") === 2;

  const [ModelMsg, setModelMsg] = useState(
    "Due to a test failure, the process has been declined. Please check your phone to find out why"
  );

  const onwipingCompleted = async (tx) => {
    if (tx.status == 0) {
      setResult(tx);
      getDeviceInfo(tx);
    } else if (tx.status == 2) {
      showErrorPopup(
        "Due to a test failure, the process has been declined. Please check your phone to find out why"
      );
    } else if (tx.status == 1) {
      navigate("/sell");
    }
  };

  useEffect(() => {
    onVerifyCompleted();
  }, []);

  //recheck it here category
  const getDeviceInfo = async (tx) => {
    let payload = {
      category: location?.state?.category || "mobiles",
      piceaWidgetData: tx,
    };
    getPiceaDeviceInfo(payload).then((res) => {
      if (res.data.data.length != 0) {
        setdeviceInfo(res.data.data[0]);
        placeOrder(location.state.state, tx, res.data.data[0].id);
      } else {
        showErrorPopup(
          "This phone is not the same phone selected in the question flow. Please try again later or contact our help team"
        );
      }
    });
  };

  const placeOrder = async (data, tx, recivedID) => {
    let productId = location.state.isPicea ? data.product_id : data.product.id;
    let deviceInfoID = recivedID;
    if (productId == deviceInfoID) {
      await selldevice(data).then((res) => {
        setPayload(res.data.data);
        setAlert({
          visible: true,
          type: !res?.data?.success ? "error" : "success",
          text: !res?.data?.success
            ? res?.data?.message
            : "Your sales has been Successfully Placed ",
        });
        new Promise((resolve) => setTimeout(resolve, 5000));
        handlePrint();
      });
    } else {
      showErrorPopup(
        "This phone is not the same phone selected in the question flow. Please try again later or contact our help team"
      );
    }
  };

  const onVerifyCompleted = async (tx) => {
    let payload = {
      picea_workflow_id: location.state.isPicea
        ? piceaWipeOrganizationWorkFlow[0]?.picea_workflow.picea_workflow_id
        : twoUseWipeWorkFlow[0]?.picea_workflow.picea_workflow_id,
    };

    let id = location.state.isPicea
      ? piceaWipeOrganizationWorkFlow[0]?.picea_workflow.picea_workflow_id
      : twoUseWipeWorkFlow[0]?.picea_workflow.picea_workflow_id;

    setisLoading(true);

    getPiceaToken(payload).then((res) => {
      if (res?.data?.data?.token) {
        window.picea.run(
          {
            token: res?.data?.data?.token,
            autorun: parseInt(id),
            responseVersion: 2,
          },
          onwipingCompleted
        );
        setisLoading(false);
      } else if (res.data.data == null) {
        alert("token is null");
        navigate("/sell");
      }
    });
  };

  const handlePrint = () => {
    setTimeout(() => {
      printRef?.current?.handleClick();
    }, 50);
  };

  const handleprintcode = () => {
    barprintRef.current?.handleClick();
  };

  const showErrorPopup = (text) => {
    let timerInterval;
    Swal.fire({
      icon: "error",
      title: "Error !",
      html: text,
      timer: 5000,
      timerProgressBar: true,
      confirmButtonText: "continue",
      showClass: {
        popup: "swal2-show",
        backdrop: "swal2-backdrop-show",
        icon: "swal2-icon-show",
      },
      customClass: {
        container: "custom-swal-container",
        background: "custom-swal-background",
      },
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const timeLeft = Math.ceil(Swal.getTimerLeft() / 1000);
        }, 10);
      },
      willClose: () => {
        navigate("/sell");
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton className="box" height={225} />
          <Skeleton className="box" height={225} />
          <Skeleton className="box" height={225} />
          <Skeleton className="box" height={225} />
        </>
      ) : (
        <div></div>
      )}

      <Disclaimer
        ref={componentRef}
        data={Payload}
        noTransaction={thirdPartyValue === 2} //DU is 2
        arabic={langu === "arabic"}
        isPicea={location.state.isPicea}
        isWiping={false}
        imeiNo={result?.data?.device ? result.data.device.attributes?.imei : ""}
      />

      <Barcode ref={barcodeRef} data={Payload} />
      <ReactToPrint
        trigger={() => <Fragment />}
        content={() => componentRef.current}
        ref={printRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            thirdPartyValue !== 2
              ? handleprintcode()
              : navigate("/payment-method", { state: { payload: Payload } });
          }, 500);
        }}
      />
      <ReactToPrint
        trigger={() => <Fragment />}
        content={() => barcodeRef.current}
        ref={barprintRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            thirdPartyValue !== 2
              ? navigate("/sell")
              : navigate("/payment-method", { state: { payload: Payload } });
          }, 500);
        }}
      />
    </>
  );
};

export default WipingFlow;
