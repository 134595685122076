import { api } from "./main";

export const getAllCategories = async (payload) => {
  return await api
    .get(`/v2/retailer/estimation-flow/get-categories`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getAllBrandsByCategory = async (payload) => {
  return await api
    .post(`/v2/retailer/estimation-flow/get-brands-by-category`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getPiceaToken = async (payload) => {
  return await api
    .post(`/v2/retailer/estimation-flow/get-picea-workflow-token`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getPiceaDeviceInfo = async (payload) => {
  return await api
    .post(`/v2/retailer/get-picea-device-info`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getCategoryById = async (id) => {
  return await api
    .get(`/admin/get-category-by-id/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getworkflowByType = async (id) => {
  return await api
    .get(`/v2/retailer/get-workflows-by-type/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getCurrencyFromDb = async (id) => {
  return await api
    .get(`/retailer/get-currency-conversion-rate/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getalllanguages = async () => {
  return await api
    .get("get-all-languages", {
      params: {
        is_enabled: 1,
      },
    })
    .then((res) => res)
    .catch((err) => console.error(err));
};
