import { api } from "./main";

export const evaluate = (payload) => {
  return api
    .post(`retailer/evaluate-conditions`, payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const selldevice = (data) => {
  return api
    .post(`retailer/sell-device`, data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getConditionPrice = async (id, payload) => {
  return await api
    .get(`retailer/get-product-condition-price/${id}`, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
