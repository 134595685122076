import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  Fragment,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ReactToPrint from "react-to-print";
import {
  DialogTitle,
  Button as MUIButton,
  Dialog,
  DialogActions,
} from "@mui/material";

import Button from "@mui/material/Button";
import PrintIcon from "@mui/icons-material/Print";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import AddTaskIcon from "@mui/icons-material/AddTask";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import SideBoxComponent from "../../components/sideBox/side-box";
import { steps, Fullsteps } from "../../core/variables/static";
import VerificationStep from "./Step1-Verification/VerificationStep";
import FillFormDetails from "./FormDetails/formdetails";
import EmployeeForm from "./employeForm/employeForm";
import DoneStep from "./DoneStep/DoneStep";

import { Disclaimer } from "../../components/disclaimer/Disclaimer";
import { Barcode } from "../../components/disclaimer/barcode";

import { useCookies } from "react-cookie";

import { AlertContext } from "../../context/AlertContext";
import { useGlobalValues } from "../../context/GlobalContext";
import { useGlobalLangValues } from "../../context/LanguageContext";
import { useTranslation } from "react-i18next";

import { selldevice } from "../../core/apis/sellmydevice";

import "./customerform.scss";

// stepper
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";

const CustomerForm = () => {
  // Hooks for localization and global values
  const { t } = useTranslation();
  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const { employeInfoEnabled, thirdPartyValue, branchcountryID } =
    useGlobalValues();
  const { setAlert } = useContext(AlertContext);

  // Cookies
  const [cookies] = useCookies(["user_info", "user", "nlog"]);

  // Hooks for routing and component state
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  // REACT PRINT Ref
  const componentRef = useRef();
  const barcodeRef = useRef();
  const printRef = useRef();
  const barprintRef = useRef();

  // Component state
  const [activeStep, setActiveStep] = useState(0);
  const [stepperSteps, setStepperSteps] = useState(steps);
  const [savedData, setSavedData] = useState([]);
  const [categoryValue, setcategoryValue] = useState();
  const [Payload, setPayload] = useState({});
  const [result, setResult] = useState({});
  const [popup, setpopup] = useState(false);

  const [serialNumber, setserialNumber] = useState("");

  const [veficationResponse, setVeficationResponse] = useState(false);
  const [loading, setloading] = useState(true);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth < 801;

  let DuIntegration = thirdPartyValue == 2;

  // Extracting necessary data from location state
  const { price } = location?.state?.state;
  const attributes = location?.state?.state.attributes;
  const deviceImage =
    location?.state?.state.product?.product_image?.[0]?.image_path;

  // Effect to navigate back to '/sell' if location state is missing
  useEffect(() => {
    !location.state && navigate("/");
  }, [location.state, navigate]);

  // Effect to handle window resize and update window width state
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Effect to update stepper steps based on employeInfoEnabled value
  useEffect(() => {
    if (employeInfoEnabled) {
      setStepperSteps(Fullsteps);
    } else {
      setStepperSteps(steps);
    }
    if (params.category) {
      setcategoryValue(params.category);
    } else {
      setcategoryValue(location.state.category);
    }
  }, [employeInfoEnabled]);

  // Handler function to update active step
  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // Handle Set Verfication Response
  const handleSetResponse = (response) => {
    setVeficationResponse(response);
  };

  // Handler function to handle next step and save data
  const handleNext = (payload) => {
    setSavedData((prevData) => [...prevData, payload]);
    setActiveStep((prevStep) => prevStep + 1);

    if (activeStep == 2 && employeInfoEnabled) {
      handleSubmisson(payload);
    } else {
      if (activeStep == 1 && !employeInfoEnabled) {
        handleSubmisson(payload);
      }
    }
  };

  // handle submisson and Proceed
  const handleSubmisson = async (payload) => {
    setloading(true);
    const otp = savedData[0].verficationstep;
    const customerInfo = employeInfoEnabled
      ? savedData[1]?.customerInfo
      : payload.customerInfo;
    const employeInfo = payload?.employeInfo;
    const verfied = veficationResponse;
    const data = {
      ...location.state.state,
      country_id: branchcountryID,
      product_id: location.state.isPicea
        ? location.state.deviceInfo.id
        : location.state.state.product.id,
      fill_form: {
        full_name: `${customerInfo?.first_name} ${customerInfo?.middle_name} ${customerInfo?.last_name}`,
        address_line: `${customerInfo?.full_address} ${customerInfo?.city}`,
        email: customerInfo?.email,
        id_or_passport_number: customerInfo?.id_or_passport_number,
        phone_number: DuIntegration
          ? customerInfo?.dialCode + customerInfo?.phone_number
          : customerInfo?.phone_number,
        postal_code: "",
        country_id: customerInfo?.nationality?.id,
        employee_name: employeInfo?.employeeName
          ? employeInfo?.employeeName
          : "",
        employee_code: employeInfo?.employeeID ? employeInfo?.employeeID : "",
        otp: otp.otp,
        send_type_id: otp.send_type_id,
      },
    };

    setPayload(data);

    if (verfied && thirdPartyValue === 2) {
      setResult({
        disclaimer: {
          seller_name: `${customerInfo.first_name} ${customerInfo.middle_name} ${customerInfo.last_name}`,
          id_or_passport_number: customerInfo?.id_or_passport_number,
          nationality: customerInfo.nationality.name,
          product_name: location?.state?.isPicea
            ? location?.state?.deviceInfo?.name?.en
            : location?.state?.state?.product?.name?.en,
          buyer_name: cookies.user_info.full_name,
          date: new Date(),
        },
      });

      setTimeout(() => {
        setpopup(!popup);
      }, 500);
    }
    //If it is a retailer without 3rd party integration and without wiping
    if (
      (verfied || otp.otp) &&
      thirdPartyValue !== 2 &&
      !location.state.isWiping
    ) {
      await selldevice(data).then((res) => {
        setResult(res?.data?.data || {});
        setAlert({
          visible: true,
          type: !res?.data?.success ? "error" : "success",
          text: !res?.data?.success
            ? res?.data?.message
            : t("Your sales has been Successfully Placed "),
        });
        res?.data?.success && setloading(false);
        setTimeout(() => {
          setpopup(!popup);
        }, 2000);
      });
    } else if (
      (verfied || otp.otp) &&
      thirdPartyValue !== 2 &&
      location.state.isWiping &&
      (categoryValue != "mobiles" || categoryValue != "tablets")
    ) {
      await selldevice(data).then((res) => {
        setResult(res?.data?.data || {});
        setAlert({
          visible: true,
          type: !res?.data?.success ? "error" : "success",
          text: !res?.data?.success
            ? res?.data?.message
            : t("Your sales has been Successfully Placed "),
        });
        res?.data?.success &&
          setTimeout(() => {
            setpopup(!popup);
            setloading(false);
          }, 500);
      });
    } else if (
      (verfied || otp.otp) &&
      thirdPartyValue !== 2 &&
      location.state.isWiping &&
      (categoryValue === "mobiles" || categoryValue === "tablets")
    ) {
      setResult({
        disclaimer: {
          seller_name: `${customerInfo.first_name} ${customerInfo.middle_name} ${customerInfo.last_name}`,
          id_or_passport_number: customerInfo?.id_or_passport_number,
          nationality: customerInfo.nationality.name,
          product_name: location?.state?.isPicea
            ? location?.state?.deviceInfo?.name?.en
            : location?.state?.state?.product?.name?.en,
          buyer_name: cookies.user_info.full_name,
          date: new Date(),
        },
      });
      setTimeout(() => {
        setpopup(!popup);
      }, 500);
    }
  };

  const handleprintcode = () => {
    barprintRef.current?.handleClick();
  };

  const handlePrint = () => {
    setpopup(!popup);
    setTimeout(() => {
      printRef?.current?.handleClick();
    }, 50);
  };
  const handlesendbyemail = () => {
    setpopup(!popup);
    navigate("/sign", {
      state: {
        payload: Payload,
        result: result,
        thirdParty: { id: thirdPartyValue },
      },
    });
  };

  const handleserialNumber = (value) => {
    setserialNumber(value);
  };

  return (
    <div className="main-sub-body">
      <div className={isArabic ? "arabic-component" : ""}>
        <div className="main-formTitle">{t("Fill Form")}</div>
        <div className="main-customerForm">
          <div className="side-deviceInfo">
            <SideBoxComponent
              price={price.price}
              title={location?.state?.state?.product?.name?.[selectedLanguage]}
              condition={location?.state?.state?.price.condition}
              category={categoryValue}
              deviceImage={deviceImage}
              array={params}
              attributes={attributes}
            />
          </div>
          <div className="side-form">
            <Box sx={{ width: "100%" }}>
              <Stepper activeStep={activeStep} orientation={"horizontal"}>
                {stepperSteps?.map((step, index) => (
                  <Step key={step.label} completed={index <= activeStep}>
                    <StepButton
                      icon={
                        !isMobile
                          ? index <= activeStep
                            ? step.activeicon
                            : step.icon
                          : " "
                      }
                      color="inherit"
                      onClick={handleStep(index)}
                      disabled={true}
                      className="step-button"
                    >
                      {!isMobile ? t(step.label) : index + 1}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
            </Box>
            {activeStep === 0 && (
              <VerificationStep
                onSuccess={handleNext}
                response={handleSetResponse}
              />
            )}
            {activeStep === 1 && (
              <FillFormDetails
                onSuccess={handleNext}
                sendSerial={handleserialNumber}
                receivedemail={savedData[0]?.verficationstep?.email}
                receivedphonenumber={
                  savedData[0]?.verficationstep?.phone_number
                }
              />
            )}

            {activeStep === 2 && employeInfoEnabled ? (
              <EmployeeForm onSuccess={handleNext} />
            ) : null}

            {activeStep === 2 && !employeInfoEnabled ? (
              <DoneStep onSuccess={handleNext} loading={loading} />
            ) : null}

            {activeStep === 3 && employeInfoEnabled ? (
              <DoneStep onSuccess={handleNext} loading={loading} />
            ) : null}
          </div>
        </div>
      </div>
      <Disclaimer
        ref={componentRef}
        data={result}
        noTransaction={thirdPartyValue === 2} //DU is 2
        arabic={selectedLanguage != "ar" ? false : true}
        serialNumber={serialNumber || ""}
        isPicea={location.state.isPicea}
        isWiping={
          (location.state.isWiping && categoryValue == "mobiles") ||
          (location.state.isWiping && categoryValue == "tablets")
            ? true
            : false
        }
      />

      <Barcode ref={barcodeRef} data={result} />
      <ReactToPrint
        trigger={() => <Fragment />}
        content={() => componentRef.current}
        ref={printRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            const isMobileOrTablet = ["mobiles", "tablets"].includes(
              categoryValue
            );
            const isWiping = location.state.isWiping;

            if (
              (thirdPartyValue !== 2 && isWiping && isMobileOrTablet) ||
              (thirdPartyValue === 2 && isWiping && isMobileOrTablet)
            ) {
              navigate("/wiping", {
                state: {
                  state: Payload,
                  category: params.category,
                  piceaVerifydata: location.state.piceaVerifyData,
                  isPicea: location.state.isPicea,
                  preData: location.state.state,
                },
              });
            } else if (thirdPartyValue === 2 && !isWiping) {
              navigate("/payment-method", { state: { payload: Payload } });
            } else {
              navigate("/")
            }
          }, 500);
        }}
      />
      {/* <ReactToPrint
        trigger={() => <Fragment />}
        content={() => barcodeRef.current}
        ref={barprintRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            thirdPartyValue !== 2
              ? navigate("/")
              : navigate("/payment-method", { state: { payload: Payload } });
          }, 500);
        }}
      /> */}
      <Dialog
        open={popup}
        keepMounted
        onClose={() => setpopup({})}
        className="printModel"
        maxWidth="sm"
      >
        <DialogTitle className="dialog-title my-2">
          <CheckCircleOutlineIcon className="main-print-icon" />
          {t("Would you like to print or send by email?")}
        </DialogTitle>
        <DialogActions className="dialog-action">
          <div className="buttonsMsg">
            <Button
              variant="contained"
              endIcon={<AttachEmailIcon />}
              onClick={handlesendbyemail}
              className="responsivnessbtn"
              sx={{ backgroundColor: "#4ba4f0", width: "150px" }}
            >
              {t("Send")}
            </Button>
            <Button
              variant="contained"
              endIcon={<PrintIcon />}
              onClick={handlePrint}
              className="responsivnessbtn"
              sx={{ backgroundColor: "#71e4ac", width: "150px" }}
            >
              {t("Print")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomerForm;
