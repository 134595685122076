import { api } from "./main";

export const getallretailerhomepagecards = async () => {
  return await api
    .get("/get-all-retailerhomepage-cards")
    .then((res) => res)
    .catch((err) => console.error(err));
};


export const getallretailerInfocards = async () => {
  return await api
    .get("/get-all-headline-info")
    .then((res) => res?.data?.data)
    .catch((err) => console.error(err));
};

