import { styled, TextField } from "@mui/material";
import { InputBase } from "@mui/material";
import { createTheme } from "@mui/material";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    // borderRadius: 4,
    position: "relative",
    borderBottom: "1px solid #041C3F",
    fontSize: 16,
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      borderBottom: "1px solid #86b7fe",
    },
  },
}));
export const theme = createTheme({
  palette: {
    primary: {
      main: "#4ba4f0",
    },
    secondary: {
      main: "#041c3f",
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          margin: "0 !important",
        },
        input: {
          "&:focus": {
            backgroundColor: "white !important",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            paddingRight: "10px",
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "#ff0000",
          margin: "5px 0px 0px 0px",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        text: {
          fill: "white",
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: { paddingRight: "0 !important" },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: { width: "100%" },
      },
    },

    MuiRadio: {
      styleOverrides: {
        root: {
          color: "rgba(0, 0, 0, 0.6) ",
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          color: "white",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "white !important",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: "0 !important",
          minWidth: "120px !important",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 4px rgb(222 228 237 / 80%) !important",
          "&:not(:last-child)": {
            borderBottom: 0,
          },
          "&:before": {
            display: "none",
          },
          "&.MuiAccordion-root": {
            margin: "0 0 1%",
            borderRadius: "10px !important",
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            borderBottom: "1px solid #C4C4C4",
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: "black",
          fontWeight: "bold",
        },
        label: {
          fontSize: "14px",
          "@media (max-width:1501px)": { fontSize: "14px" },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: "43px",
        },
      },
    },
  },
});

export const CssTextField = styled(TextField, {
  shouldForwardProp: (props) => props !== "focusColor",
})((p) => ({
  // input label when focused
  "& label.Mui-focused": {
    color: p.focusColor,
    borderBottom: "1px solid red",
  },
  // focused color for input with variant='standard'
  "& .MuiInput-underline:after": {
    borderBottomColor: p.focusColor,
    borderBottom: "1px solid #86b7fe",
  },
  // focused color for input with variant='filled'
  "& .MuiFilledInput-underline:after": {
    borderBottomColor: p.focusColor,
    borderBottom: "1px solid #86b7fe",
  },

  // focused color for input with variant='outlined'
  "& .MuiOutlinedInput-root": {
    "&.Mui-focused fieldset": {
      borderColor: p.focusColor,
    },
  },
}));
