import React, { Suspense, useContext, useEffect, useState } from "react";
import "./step-0-category.scss";
import { Link } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";
import NewBuyBack from "../../../components/buyBack/BuyBack";
import { CountryContext } from "../../../context/CountryContext";
import { UserContext } from "../../../context/UserContext";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import Image from "../../../components/image/Image";
import { getAllCategories } from "../../../core/apis/newapis";
import { useGlobalValues } from "../../../context/GlobalContext";
import { Tooltip, tooltipClasses, styled } from "@mui/material";
import { IconButton, SvgIcon } from "@mui/material";
import informationSvg from "../../../assests/Images/information.png";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const CategoryStep = () => {
  const { country } = useContext(CountryContext);
  const { infoHeadLine } = useContext(UserContext);
  const { t } = useTranslation();

  const { setItemId, piceaOrganizationState, twoUseWorkFlowState } =
    useGlobalValues();

  const { selectedLanguage, isArabic } = useGlobalLangValues();

  const handleButtonClick = (item) => {
    setItemId(item.id);
  };
  const [category, setCategory] = useState();
  const [isLoading, setLoading] = useState(true);
  const [showText, setshowText] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth < 801;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    const response = await getAllCategories({
      country_id: country?.id,
    });
    const sortedProductArray = response?.data?.data
      .slice()
      .sort((a, b) => a.id - b.id);

    setCategory(sortedProductArray);
    setLoading(false);
    setTimeout(() => {
      setshowText(true);
    }, 900);
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <>
      <div className="main-sub-body">
        <div
          style={{ display: "flex", alignItems: "left" }}
          className={isArabic ? "arabic-component" : ""}
        >
          <h1
            style={{ color: "white" }}
            className={isArabic ? "arabic-component" : ""}
          >
            {t("Chooseacategory")}
          </h1>

          <LightTooltip
            style={{ margin: "0 10px 10px" }}
            className="conditions-tooltip"
            title={
              <div className={isArabic ? "arabic-component" : ""}>
                <h6 style={{ color: "#4BA4F0" }}>{t("Category")}</h6>
                <h6 style={{ color: "#041C3F" }}>
                  {infoHeadLine
                    ? infoHeadLine[1]?.information?.[selectedLanguage] ||
                      infoHeadLine[1]?.information?.en
                    : ""}
                </h6>
              </div>
            }
            arrow
            placement={isMobile ? "bottom" : "right"}
          >
            <IconButton
              aria-label="info"
              color="primary"
              style={{ margin: "10px 0px" }}
            >
              <img src={informationSvg} />
            </IconButton>
          </LightTooltip>
        </div>

        <div
          className={
            isArabic ? "selectCategory  arabic-component" : "selectCategory "
          }
        >
          {!isLoading && category? (
            category?.length ? (
              category?.map((item) =>
                item?.is_active && item.is_sell ? (
                  <Button
                    key={item.id}
                    component={item.is_coming_soon ? "button" : Link}
                    className={`box ${
                      item.is_coming_soon ? "boxdisabled" : ""
                    }`}
                    to={
                      item.picea_enabled && piceaOrganizationState
                        ? `/sell/${item?.slug}/chooseamethod`
                        : `/sell/${item?.slug}`
                    }
                    onClick={() => handleButtonClick(item)}
                  >
                    {item.is_coming_soon && (
                      <p className="comingSoon">Coming Soon</p>
                    )}

                    <Image
                      className="picture"
                      src={process.env.REACT_APP_IMAGE_URL + item?.image_path}
                      alt={item?.name?.[selectedLanguage]}
                    />
                
                      <p>
                        {item?.name?.[selectedLanguage]
                          ? item?.name?.[selectedLanguage]
                          : item?.name?.en}
                      </p>
              
                  </Button>
                ) : null
              )
            ) : (
              <h1 className="No-Data-Found absolute">{t("No Data Found")}</h1>
            )
          ) : (
            [...Array(6)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                width={250}
                height={250}
                className="box-Skeleton"
              ></Skeleton>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default CategoryStep;
