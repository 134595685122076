import React, { useContext, useEffect, useRef, useState } from "react";
import "./TransactionDetails.scss";
import Collapsible from "react-collapsible";
import { getSalesDetailsById } from "../../../core/apis/transactions";
import Button from "../../../components/button/Button";
import { ArrowSVG, PrintoutSVG } from "../../../assests/icons/SVG";
import { useParams } from "react-router-dom";
import { sellAcceptOffer } from "../../../core/apis/products";
import { ButtonBase, SvgIcon } from "@mui/material";
import { Disclaimer } from "../../../components/disclaimer/Disclaimer";
import ReactToPrint from "react-to-print";
import Barcode from "react-barcode/lib/react-barcode";
import moment from "moment";
import { AlertContext } from "../../../context/AlertContext";
import { useTranslation } from "react-i18next";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import translateToArabicCalendar from "../../../core/functions/moment";
import { useGlobalValues } from "../../../context/GlobalContext";
import loaderSpinner from "../../../assests/Images/loaderspinner.png";

const TransactionDetails = () => {
  let { id } = useParams();
  const DisclaimerRef = useRef();
  const { setAlert } = useContext(AlertContext);
  const [isLoading, setLoading] = useState(true);
  const [details, setDetails] = useState([]);
  const [piceaDetails, setpiceaDetails] = useState();
  const [diagnositicDetails, setdiagnositicDetails] = useState();
  const [verifyDetails, setverifyDetails] = useState();
  const { t, i18n } = useTranslation();
  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const { employeInfoEnabled, thirdPartyValue } = useGlobalValues();

  const [arabicComponent, setArabicComponent] = useState(false); // State to track Arabic language

  const handletrime = (value) => {
    return value?.match(/\d+/)[0];
  };

  const fetchSalesDetails = () => {
    getSalesDetailsById(id).then((res) => {
      setDetails(res);
      setLoading(false);
    });
  };

  useEffect(() => {
    getSalesDetailsById(id).then((res) => {
      if (res?.picea_transaction_details) {
        const piceaObject = JSON.parse(res?.picea_transaction_details);
        let diagnositicData = piceaObject?.events.filter(
          (item) => item.title === "Diagnostics"
        );

        let verifyData = piceaObject?.events.filter((item) =>
          item.title.includes("Verify")
        );
        setpiceaDetails(piceaObject);

        const updatedArray = diagnositicData?.map((item) => {
          const match =
            item.description.match(/\bfailed\b/i) ||
            item.description.match(/\passed\b/i) ||
            item.description.toLowerCase().match(/\ Broken but Working\b/i);

          const result = match ? match[0] : null;

          const matchh = item?.description.match(
            /^(.*?)(?: passed| failed| Result| $)(.*)/i
          );
          const testName = matchh ? matchh[1].trim() : item?.description.trim();

          // Update the item with separate test and result properties
          return {
            ...item,
            test: testName,
            result: result,
          };
        });

        setdiagnositicDetails(updatedArray);

        const upddateVerifyArray = verifyData?.map((item) => {
          const match =
            item?.description.toLowerCase().match(/\bfailed\b/i) ||
            item?.description.toLowerCase().match(/\bsuccess\b/i);

          const result = match ? match[0] : null;

          const matchh = item?.description
            .toLowerCase()
            .match(/^(.*?)(?: success| failed| $)(.*)/i);
          const testName = matchh ? matchh[1].trim() : item?.description.trim();

          // Update the item with separate test and result properties
          return {
            ...item,
            test: testName,
            result: result,
          };
        });
        setverifyDetails(upddateVerifyArray);
      }
      setDetails(res);
      setLoading(false);
    });
  }, []);

  const summary = [
    {
      title: t("Customer Details"),
      items: [
        { label: t("Customer Name"), value: details?.fill_form?.full_name },
        { label: t("Phone Number"), value: details?.fill_form?.phone_number },
        {
          label: t("Address"),
          value: details?.fill_form?.address_line,
          className: "address",
        },
      ],
    },
    {
      title: t("Order Summary"),
      items: [
        {
          label: t("Order Date"),
          value: isArabic
            ? translateToArabicCalendar(details?.created_at)
            : moment(details?.created_at).locale("en").calendar(),
        },
        {
          label: t("Payment Method"),
          value: t(details?.payment_gateway?.name?.en),
        },
        {
          label: t("Total"),
          value:
            details?.inspection?.[0]?.inspection_value +
            " " +
            details?.currency?.code,
        },
      ],
    },
  ];

  if (employeInfoEnabled) {
    summary.push({
      title: t("Employee Details"),
      items: [
        {
          label: t("Employee Name"),
          value: details?.fill_form?.employee_name
            ? details?.fill_form?.employee_name
            : t("no data"),
        },
        {
          label: t("Employee Code"),
          value: details?.fill_form?.employee_code
            ? details?.fill_form?.employee_code
            : t("no data"),
        },
      ],
    });
  }

  // Effect to set Arabic component class
  useEffect(() => {
    setArabicComponent(i18n.language === "ar");
  }, [i18n.language]);

  // handle accept/reject inspection sent by admin
  const handleAccept = (next_action_id, itemId) => () => {
    let payload = {
      next_action_id: +next_action_id,
      inspection_id: details?.inspection?.find((item) => item.is_offered).id,
    };
    sellAcceptOffer(itemId, payload).then((res) =>
      setAlert({
        visible: true,
        type: res.data.success ? "success" : "error",
        text: res.data.message,
      })
    );
    fetchSalesDetails();
  };

  return (
    <div className="main-sub-body">
      {isLoading ? (
        <div>
          {" "}
          <div className="loadingContent">
            <img src={loaderSpinner} alt="spinner" className="spinner spin" />
          </div>
        </div>
      ) : (
        <div
          className={`Order_details  ${
            arabicComponent ? "arabic-component" : ""
          }`}
        >
          <h1 style={{ color: "#fff" }}>{t("Sales Details")}</h1>
          <h4>
            {t("Items Summary")}
            <ReactToPrint
              trigger={() => (
                <ButtonBase
                  variant="contained"
                  className="PrintoutSVG"
                  aria-label="filter button"
                >
                  <SvgIcon
                    component={PrintoutSVG}
                    inheritViewBox
                    sx={{ fontSize: 30 }}
                  />
                </ButtonBase>
              )}
              content={() => DisclaimerRef.current}
              removeAfterPrint
            />
          </h4>
          <Disclaimer
            ref={DisclaimerRef}
            data={details}
            events={piceaDetails?.events}
            piceaDetails={piceaDetails}
            verifyDetails={verifyDetails}
            diagnositicData={diagnositicDetails}
            arabic={selectedLanguage != "ar" ? false : true}
            imeiNo={piceaDetails?.device?.imei}
          />

          <div className="Items_summary">
            <div className="accordions headerAccor">
              <span className="headerAccordion">{t("Product")} </span>
              <span className="headerAccordion">{t("Price")}</span>
            </div>
            {details?.inspection?.map((item, index) => (
              <div
                className={!index ? "accordions " : "accordions printHide"}
                key={item.id}
              >
                <Collapsible
                  triggerDisabled={false}
                  openedClassName={`accordion-opened `}
                  trigger={
                    <>
                      <span className="accordion_list_wrapper">
                        <div className="accordion_list list1">
                          <span>{t("Product")}</span>
                          <span>{t("Price")}</span>
                        </div>
                        <div className={"accordion_list list2 borders"}>
                          <span
                            className="sale_number  "
                            style={{
                              textAlign: selectedLanguage != "ar" ? "left" : "",
                            }}
                          >
                            {item?.item?.product?.name?.[selectedLanguage]
                              ? item?.item?.product?.name?.[selectedLanguage]
                              : item?.item?.product?.name?.en}
                          </span>
                          <span className="price">
                            {`${details?.currency?.code} ${Number(
                              item?.inspection_value
                            )?.toFixed(2)}`}
                            {item.is_offered && (
                              <span className="newOffer">
                                {" "}
                                {t("New offer!")}
                              </span>
                            )}
                          </span>
                          <ArrowSVG width="15px" />
                        </div>
                      </span>
                      <ArrowSVG className="mobile_svg" />
                    </>
                  }
                >
                  <div className="accordion_list">
                    <span className="approved_sn">
                      <span className="offered_value">
                        {t("Inspection ID")}{" "}
                      </span>
                      <span className="offered_value Barcode">
                        {t("Barcode")}
                      </span>
                      {thirdPartyValue === 1 && (
                        <span className="offered_value Barcode">
                          {t("Gift card code")}
                        </span>
                      )}
                      {thirdPartyValue === 1 && (
                        <span
                          className="offered_value Barcode"
                          style={{ visibility: "hidden" }}
                        >
                          {t("Gift card code")}
                        </span>
                      )}
                      <span className="offered_value">
                        {t("Estimated Value")}
                      </span>
                      <span className="offered_value">{t("Brand")}</span>
                      <span className="offered_value">{t("Category")}</span>
                      <span className="offered_value">{t("Product")}</span>

                      {item?.attribute_value?.map((attribute) => (
                        <span className="offered_value" key={attribute.id}>
                          {attribute?.attribute?.name?.[selectedLanguage]
                            ? attribute?.attribute?.name?.[selectedLanguage]
                            : attribute?.attribute?.name?.en}
                        </span>
                      ))}
                      {item?.question_answer?.map((question) => (
                        <span className="offered_value" key={question.id}>
                          {question?.question?.question?.[selectedLanguage]
                            ? question?.question?.question?.[selectedLanguage]
                            : question?.question?.question?.en}
                        </span>
                      ))}
                    </span>

                    <span
                      className="approved_sn"
                      style={{
                        direction: selectedLanguage != "ar" ? "rtl" : "ltr",
                      }}
                    >
                      <span className="offered_value">{item?.id}</span>
                      <span className="offered_value">
                        <Barcode
                          value={details?.barcode}
                          textPosition={"left"}
                          fontSize={10}
                          height={25}
                          margin={0}
                        />
                      </span>
                      {thirdPartyValue === 1 && (
                        <span className="offered_value" style={{ padding: "0px",}}>
                          <div
                            style={{
                              border: "2px solid #ff0000",
                              padding: "10px 10px",
                              borderStyle: "dashed",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Barcode
                              value={handletrime(
                                details?.third_party[0]?.value
                              )}
                              textPosition={"left"}
                              fontSize={10}
                              height={25}
                              margin={0}
                            />
                          </div>
                        </span>
                      )}
                      {thirdPartyValue === 1 && (
                        <span
                          className="offered_value"
                          style={{
                            color: "#ff0000",
                            padding: "0px",
                            fontSize:"14px"
                          }}
                        >
                          {t("Scan the coupon barcode to redeem the gift card")}
                        </span>
                      )}
                      <span className="offered_value">
                        {`${details?.currency?.code} ${Number(
                          item?.inspection_value
                        )?.toFixed(2)}`}
                      </span>
                      <span className="offered_value">
                        {item?.item?.product?.brand?.name?.[selectedLanguage]
                          ? item?.item?.product?.brand?.name?.[selectedLanguage]
                          : item?.item?.product?.brand?.name?.en}
                      </span>
                      <span className="offered_value">
                        {item?.item?.product?.category?.name?.[selectedLanguage]
                          ? item?.item?.product?.category?.name?.[
                              selectedLanguage
                            ]
                          : item?.item?.product?.category?.name?.en}
                      </span>
                      <span className="offered_value">
                        {item?.item?.product?.name?.[selectedLanguage]
                          ? item?.item?.product?.name?.[selectedLanguage]
                          : item?.item?.product?.name?.en}
                      </span>
                      {item?.attribute_value?.map((attribute) => (
                        <span className="offered_value" key={attribute?.id}>
                          {attribute?.name?.[selectedLanguage]
                            ? attribute?.name?.[selectedLanguage]
                            : attribute?.name?.en}
                        </span>
                      ))}
                      {item?.question_answer?.map((question) => (
                        <span className="offered_value" key={question?.id}>
                          {question?.answer?.[selectedLanguage]
                            ? question?.answer?.[selectedLanguage]
                            : question?.answer?.en}
                        </span>
                      ))}
                    </span>
                  </div>
                </Collapsible>
              </div>
            ))}

            {piceaDetails && (
              <div className="accordions">
                <Collapsible
                  triggerDisabled={false}
                  openedClassName={`accordion-opened `}
                  trigger={
                    <>
                      <span className="accordion_list_wrapper">
                        <div className="accordion_list list1">
                          <span>{t("Product")}</span>
                          <span>{t("Price")}</span>
                        </div>
                        <div className="accordion_list list2 borders">
                          <span className="sale_number">
                            {t("Verify & Diagnostics Details")}
                          </span>
                          <ArrowSVG width="15px" />
                        </div>
                      </span>
                      <ArrowSVG className="mobile_svg" />
                    </>
                  }
                >
                  <div className="accordion_list">
                    <span className="approved_sn">
                      {piceaDetails?.grade ? (
                        <span className="offered_value">{t("Grade")}</span>
                      ) : (
                        ""
                      )}

                      {piceaDetails?.device &&
                        Object.entries(piceaDetails?.device).map(
                          ([key, value], index) =>
                            value != null ? (
                              <span className="offered_value" key={index}>
                                {key}
                              </span>
                            ) : (
                              ""
                            )
                        )}

                      {verifyDetails &&
                        verifyDetails.map((item, index) =>
                          index == 0 ? (
                            ""
                          ) : (
                            <span className="offered_value" key={item.id}>
                              {item?.test
                                ? item?.test.replace(/:/g, "")
                                : "No Value Found"}
                            </span>
                          )
                        )}
                      {diagnositicDetails &&
                        diagnositicDetails.map((item, index) =>
                          index == 0 ? (
                            ""
                          ) : (
                            <span className="offered_value" key={item.id}>
                              {item?.test ? item.test : t("No Value Found")}
                            </span>
                          )
                        )}
                    </span>

                    <span className="approved_sn" style={{ direction: "rtl" }}>
                      <span className="offered_value">
                        {piceaDetails?.grade ? piceaDetails?.grade : null}
                      </span>

                      {Object.entries(piceaDetails?.device).map(
                        ([key, value], index) =>
                          value != null ? (
                            <span className="offered_value" key={index}>
                              {value}
                            </span>
                          ) : (
                            ""
                          )
                      )}

                      {verifyDetails &&
                        verifyDetails?.map((item, index) =>
                          index == 0 ? (
                            ""
                          ) : (
                            <span className="offered_value" key={item.id}>
                              {item?.result
                                ? item?.result
                                : t("No Value Found")}
                            </span>
                          )
                        )}

                      {diagnositicDetails?.map((item, index) =>
                        index != 0 ? (
                          <span className="offered_value" key={item.id}>
                            {item?.result}
                          </span>
                        ) : (
                          ""
                        )
                      )}
                    </span>
                  </div>
                </Collapsible>
              </div>
            )}
          </div>
          <div className="Details_row">
            {summary?.map((item, index) => (
              <div className="Summary" key={index}>
                <h4>{item?.title}</h4>
                <div className="Info">
                  {item?.items?.map((body, ind) => (
                    <div className="Sub_row" key={ind}>
                      <p className="Left">{body.label}</p>
                      <p className={body.className || null}>{body.value}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
            {!details?.inspection?.some((item) => item?.is_approved === true) &&
              details?.inspection
                ?.filter((item) => item.is_offered)
                ?.map((item) => (
                  <div className="Summary" key={item.id}>
                    <h4>{"Order Offer"}</h4>
                    <div className="Info">
                      <div className="Sub_row">
                        <p className="Left">{t("Offered Value")}</p>
                        <p>
                          {`${details?.currency?.code} ${Number(
                            item?.inspection_value
                          )?.toFixed(2)}`}
                        </p>
                      </div>
                      <div className="Sub_row">
                        <p className="Left"> </p>
                        <p></p>
                      </div>
                      <div className="buttonContainer">
                        {[...details.next_action].reverse().map((action) => (
                          <Button
                            key={action.id}
                            name={action.name}
                            width="50%"
                            onClick={handleAccept(
                              action.item_status_id,
                              item?.item?.id
                            )}
                            selected={action.slug === "accept-offer"}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default TransactionDetails;
