import { Cookies } from "react-cookie";
import { api } from "./main";

export const isUserLoggedIn = () => {
  const cookies = new Cookies();
  return Boolean(JSON.parse(cookies?.get("user") || null));
};

export const checkEmail = async (payload) => {
  return await api
    .get("/check-email", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const checkOTP = async (payload) => {
  return await api
    .get("/check-reset-password-otp", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const resetPassword = async (data) => {
  return await api
    .post("auth/reset-password", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const resetPasswordByEmail = async (payload) => {
  return await api
    .post("auth/reset-password-by-email", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const resetPasswordByOTP = (data) => {
  return api
    .post("auth/reset-password-by-otp", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const FCMToken = (payload) => {
  return api
    .post("save-fcm-token", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const emailVerification = (payload) => {
  return api
    .post("auth/verify-email", payload)
    .then((res) => res)
    .catch((err) => console.error(err));
};
