import React, { useContext, useEffect, useState } from "react";
import "./step-3-selectProduct.scss";
import NoImageFound from "../../../assests/Images/NoImageFound.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import { CConverter } from "../../../core/functions/CurrencyConversion";
import "react-tooltip/dist/react-tooltip.css";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { Tooltip, tooltipClasses, styled } from "@mui/material";
import { IconButton, SvgIcon } from "@mui/material";
import informationSvg from "../../../assests/Images/information.png";
import { UserContext } from "../../../context/UserContext";
import Image from "../../../components/image/Image";
import NoDataFound from "../../../components/NodataFound/nodatafound";
import { Skeleton } from "@mui/material";
import { getSubCategoryBySlug } from "../../../core/apis/category";
import { CountryContext } from "../../../context/CountryContext";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const SelectProduct = () => {
  let params = useParams();

  console.log(params);

  const { state } = useLocation();
  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const { t } = useTranslation();
  const { country } = useContext(CountryContext);
  const [subcategoiresProducts, setSubcategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { infoHeadLine } = useContext(UserContext);
  const [showText, setshowText] = useState(false);

  let navigate = useNavigate();
  const save = (Name) => localStorage.setItem("ModelName", Name);
  !state && navigate("/sell");

  let location = useLocation();
  let currentLocation = location?.pathname?.split("/");
  let smartlocation = currentLocation[2] == "smart-watches" ? true : false;

  useEffect(() => {
    setTimeout(() => {
      setshowText(true);
    }, 700);
  }, []);

  //getSubCategoryBySlug

  useEffect(() => {
    let payload = {
      country_id: country.id,
      brand_slug: params.brand,
    };
    getSubCategoryBySlug(params.subcategory, payload).then((res) => {
      let data = res?.data?.data?.product_with_max_price;
      setSubcategories(data);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return (
      <div className="main-sub-body">
        <h1
          style={{ color: "white" }}
          className={isArabic ? "arabic-component" : ""}
        >
          {t("ChooseaProduct")}
        </h1>
        <div className="sellmyiphone">
          {[...Array(6)].map((_, index) => (
            <Skeleton
              key={index}
              variant="rounded"
              width={250}
              height={250}
              className="box-Skeleton"
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="main-sub-body">
      <div
        style={{ display: "flex", alignItems: "left" }}
        className={isArabic ? " arabic-component " : ""}
      >
        <h1
          style={{ color: "white" }}
          className={isArabic ? "arabic-component" : ""}
        >
          {t("ChooseaProduct")}
        </h1>
        <LightTooltip
          style={{ margin: "0 10px 10px" }}
          className="conditions-tooltip"
          title={
            <div className={isArabic ? "arabic-component" : ""}>
              <h6 style={{ color: "#4BA4F0" }}>{t("product")}</h6>
              <h6 style={{ color: "#041C3F" }}>
                {infoHeadLine
                  ? infoHeadLine[3]?.information?.[selectedLanguage] ||
                    infoHeadLine[3]?.information?.en
                  : ""}
              </h6>
            </div>
          }
          arrow
          placement="right"
        >
          <IconButton
            aria-label="info"
            color="primary"
            style={{ margin: "10px 0px" }}
          >
            <img src={informationSvg} />
          </IconButton>
        </LightTooltip>
      </div>
      <div
        className={
          isArabic ? "arabic-component sellmyiphone " : "sellmyiphone "
        }
      >
        {subcategoiresProducts?.length > 0 &&
          subcategoiresProducts?.map((item) => (
            <Button
              component={Link}
              className={smartlocation ? "box min-320" : "box"}
              to={`/sell/${params?.category}/${params?.brand}/${params?.subcategory}/${item.slug}/questions`}
              onClick={() =>
                save(
                  item.name?.[selectedLanguage]
                    ? item.name?.[selectedLanguage]
                    : item.name?.en
                )
              }
              state={{ ...state, product: item }}
              key={item.id}
            >
              <Image
                className="picture"
                src={
                  item?.product_image?.[0].image_path &&
                  item?.product_image?.[0].image_path !== "image1"
                    ? process.env.REACT_APP_IMAGE_URL +
                      item?.product_image?.[0].image_path
                    : NoImageFound
                }
                alt={item?.name?.en}
              />

              <p className="productName">
                {item?.name?.[selectedLanguage]
                  ? item?.name?.[selectedLanguage]
                  : item?.name?.en}
              </p>
              <p className="description">
                {t("For highest specs and conditions")}
              </p>
              <div className="links">
                {t("Up to")}
                <span>{" " + CConverter(item?.max_price)}</span>
              </div>
            </Button>
          ))}
      </div>
      <div className="nodataComponent">
        {subcategoiresProducts?.length == 0 ? <NoDataFound /> : null}
      </div>
    </div>
  );
};
export default SelectProduct;
