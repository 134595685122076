import React, { useState, useEffect } from "react";
import "./notificationsList.scss";
import NotificationComponent from "../../components/Notification-component/NotificationComp";
import Button from "../../components/button/Button";
import { useGlobalLangValues } from "../../context/LanguageContext";
import { useTranslation } from "react-i18next";

const NotificationList = (props) => {
  const { isPage, handleNavigate } = props;

  const { i18n, t } = useTranslation();
  const { selectedLanguage, isArabic, enabledLanguages } =
    useGlobalLangValues();

  const notifications = [
    {
      id: 1,
      details:t("Comming Soon"),
      date: "",
      isRead: true,
    },
  ];

  const handleReadAll = () => {
    notifications.map((item) => {
      item.isRead = !item.isRead;
    });
  };

  return (
    <div className={isPage ? "main-sub-body" : ""}>
      <div className={isArabic ? "arabic-component" : ""}>
        <div
          className={
            isPage
              ? "main-notification-container"
              : "main-notification-container border-none"
          }
        >
          <div className="title">{t("Notifications")}</div>
          <div className="notification-container">
            {isPage
              ? notifications.map((item) => (
                  <NotificationComponent
                    isRead={item.isRead}
                    details={item.details}
                    date={item.date}
                  />
                ))
              : notifications
                  .slice(0, 7)
                  .map((item) => (
                    <NotificationComponent
                      isRead={item.isRead}
                      details={item.details}
                      date={item.date}
                    />
                  ))}
          </div>
          {/* {isPage ? (
            <div className="notification-button">
              <Button
                name={t("Mark all as Read")}
                width="20%"
                selected
                onClick={handleReadAll}
              />
            </div>
          ) : (
            <div className="notification-button">
              <Button
                name={t("View all")}
                width="50%"
                selected
                onClick={handleNavigate}
              />
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};
NotificationList.defaultProps = {
  isPage: true,
};
export default NotificationList;
