import React, { createContext, useEffect, useState } from "react";
import AlertComponent from "../components/Alert/Alert";

const AlertContext = createContext();

const AlertContextProvider = (props) => {
  const { children } = props;
  const [alert, setAlert] = useState({ visible: false });
  useEffect(() => {
    alert.visible &&
      setTimeout(() => {
        setAlert({ visible: false });
      }, 6000);
  }, [alert]);

  return (
    <AlertContext.Provider value={{ alert, setAlert }}>
      {alert.visible && (
        <AlertComponent
          severity={alert.type || ""}
          onClick={() => setAlert({ ...alert, visible: false })}
          text={alert.text}
          onClose={alert.onClose || (() => {})}
        />
      )}
      {children}
    </AlertContext.Provider>
  );
};
export { AlertContextProvider, AlertContext };
