import { async } from "q";
import React, { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getAllCountries } from "../core/apis/countries";
import { useLocalStorage } from "../hooks/useLocalStorage";

const CountryContext = createContext();

const CountryContextProvider = (props) => {
  const { children } = props;
  const [loading, setLoading] = useState(true);
  const [cookies, setCookies] = useCookies(["nlog", "ilog"]);
  const [countries, setCountries] = useLocalStorage("countries");
  const getCountries = async () => {
    await getAllCountries().then(async (res) => {
      setCountries(res);
      !cookies.nlog &&
        (await fetch("https://ipapi.co/json/")
          .then((res) => res.json())
          .then((response) => {
            let countrySelected = res?.find(
              (i) => i.country_code === response?.country_code
            );

            setCookies("ilog", response);
            setCookies("nlog", countrySelected);
          })
          .catch((data) => {
            console.error("Request failed:", data);
            setCookies(
              "nlog",
              res?.find((i) => i.country_code === "AE")
            );
          }));
    });
    await setLoading(false);
  };
  useEffect(() => {
    (!countries?.length || !cookies.nlog || cookies.ilog) && getCountries();
  }, []);
  return (
    <CountryContext.Provider
      value={{
        country: cookies.nlog,
        countries,
        loadingCountries: loading,
      }}
    >
      {children}
    </CountryContext.Provider>
  );
};
export { CountryContextProvider, CountryContext };
