import React, { useEffect, useState, useContext } from "react";
import "./SellMyDeviceQuestion.scss";
import * as ProductsApi from "../../../core/apis/products";
import * as estimate from "../../../core/apis/sellmydevice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import {
  IconButton,
  FormControlLabel,
  Radio,
  Stepper,
  Step,
} from "@mui/material";
import { Skeleton } from "@mui/material";

import { CountryContext } from "../../../context/CountryContext";
import PriceRange from "./priceRange/PriceRange";
import NoQuestionsFound from "./noQuestionsFound/NoQuestionsFound";
import FinalEstimation from "./finalEstimation/FinalEstimation";
import { BackButtonSVG } from "../../../assests/icons/SVG";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { AlertContext } from "../../../context/AlertContext";
import { useTranslation } from "react-i18next";
import TitleComponent from "../../../components/titleComponent/TitleComponent";
import Image from "../../../components/image/Image";
import paper from "../../../assests/Images/summary.png";
import { ColorCode } from "../../../core/variables/static";
import { SquareColor } from "../../../assests/icons/SVG";
import ShowMoreText from "../../../components/textExpanded/ShowMoreText";

const DeviceQuestion = () => {
  let { state } = useLocation();
  let navigate = useNavigate();
  let params = useParams();

  const { setAlert } = useContext(AlertContext);
  const { country } = useContext(CountryContext);
  const [allQuestions, setAllQuestions] = useState([]);
  const [attributes, setAttributes] = useState();
  const [error, setError] = useState(false);
  const [choosenAttributes, setchoosenAttributes] = useState({});
  const [QnA, setQnA] = useState({});
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingestimation, setloadingestimation] = useState(false);
  const [finishLoading, setfinishLoading] = useState(false);
  const [isBreakable, setBreakable] = useState(false);
  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState();

  const [selectedValues, setSelectedValues] = useState(
    Array(steps?.length).fill(null)
  );
  const [SelectedAnswersValues, setSelectedAnswersValues] = useState([]);
  const [imagePath, setImagePath] = useState(null);

  // State to track whether a value has been selected for the current step
  const [isStepComplete, setIsStepComplete] = useState(false);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setIsStepComplete(false);
    setImagePath(null);
  };

  // Retrieve the image path of the selected answer
  const getImagePath = (answerId) => {
    const selectedQuestion = allQuestions[activeStep - attributes?.length];
    if (answerId) {
      const selectedAnswer = selectedQuestion?.answer.find(
        (answer) => answer?.id === answerId
      );
      return selectedAnswer?.image_path || "";
    } else {
      const selectedAnswer = selectedQuestion?.answer.find(
        (answer) => answer?.id
      );
      return selectedAnswer?.image_path || "";
    }
  };

  const handleBack = () => {
    setloadingestimation(false);
    setfinishLoading(false);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setIsStepComplete(true);
  };

  // for device box keep
  let NewId = Object.values(choosenAttributes);

  //get attributes & get all questions for a specific product
  useEffect(() => {
    !state && navigate("/sell"); //if there is no state? Redirect to Sell Route
    ProductsApi.getProductEstimationQuestion(params?.productslug).then(
      (res) => {
        const estimationAttributes =
          res?.data?.attributes?.estimation_attributes || [];
        estimationAttributes.map((item) =>
          item?.values?.map((itemKey) => (itemKey["attribute_id"] = item?.id))
        );
        const nonEstimationAttributes =
          res?.data?.attributes?.non_estimation_attributes || [];
        nonEstimationAttributes.map((item) =>
          item?.values?.map((itemKey) => (itemKey["attribute_id"] = item?.id))
        );
        console.log(nonEstimationAttributes);

        setAttributes([...estimationAttributes, ...nonEstimationAttributes]);

        setAllQuestions(res?.data?.questions?.[0] || []);
        handleSetSteps(
          res?.data?.attributes?.estimation_attributes || [],
          res?.data?.attributes?.non_estimation_attributes || [],
          res?.data?.questions?.[0]  || []
        );
        setLoading(false);
        !res?.success && setError(true);
      }
    );
  }, []);

  const handleSetSteps = (attributes, nonattributes, allQuestions) => {
    setSteps(
      [...attributes, ...nonattributes, ...allQuestions].sort(
        (a, b) => a.order - b.order
      )
    );
  };

  //final button: Evaluate
  const handleEvaluateContinue = () => {
    setloadingestimation(true);
    Evaluate();
  };

  //Evaluate Function
  const Evaluate = () => {
    const payload = {
      country_id: country.id,
      product_id: state?.product.id,
      attributes_answers: Object.values(NewId).map((item) => ({
        attribute_id: item.attribute_id,
        attribute_value_id: item.id,
      })),
      questions: Object.values(QnA).map((item) => ({
        question_id: item.question_id,
        answer_id: item.id,
      })),
    };
    estimate.evaluate(payload).then((res) => {
      if (res.data.success) {
        setResult(res?.data?.data);
        setfinishLoading(true);
      } else {
        setAlert({ visible: false, type: "error", text: res?.data?.message });
      }
    });
  };

  const [completedSteps, setCompletedSteps] = useState([]);

  // Function to handle radio button change ATTRIBUTES
  const handleRadioButtonChange = (value, question) => {
    // Check if the key already exists in choosenAttributes
    if (choosenAttributes[value?.attribute_id]) {
      // If the key exists, update the existing entry
      setchoosenAttributes({
        ...choosenAttributes,
        [value?.attribute_id]: { ...value, question: question },
      });
    } else {
      // If the key doesn't exist, add a new entry
      setchoosenAttributes((prevState) => ({
        ...prevState,
        [value?.attribute_id]: { ...value, question: question },
      }));
    }
    setSelectedValues((prevState) => {
      const updatedValues = [...prevState];
      updatedValues[activeStep] = value.id;
      return updatedValues;
    });

    setIsStepComplete(true);
    setTimeout(() => {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, 600);
  };

  const handleQuestionsAnswersChange = (value, question) => {
    setQnA({ ...QnA, [value?.question_id]: { ...value, question: question } });
    setSelectedAnswersValues((prevState) => {
      const updatedValues = [...prevState];
      updatedValues[activeStep] = value.id;
      return updatedValues;
    });
    setIsStepComplete(true);
    setTimeout(() => {
      //setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }, 7000);
  };

  const findColor = (colorName) => {
    // Find the color object by name
    const color = ColorCode.find(
      (c) => c.name.toLowerCase() === colorName.toLowerCase()
    );

    // Return the color code if found, otherwise return null
    return color ? color.code : null;
  };

  const isNextDisabled =
    activeStep < attributes?.length
      ? selectedValues[activeStep] == null
      : SelectedAnswersValues[activeStep] == null;

  return (
    <div
      className={isArabic ? " arabic-component main-sub-body" : "main-sub-body"}
    >
      <div className="header_title">
        <h1
          className={isArabic ? " arabic-component model_name" : "model_name"}
        >
          {state?.product?.name?.[selectedLanguage]
            ? state?.product?.name?.[selectedLanguage]
            : state?.product?.name?.en}
        </h1>
      </div>

      <div className="main_container">
        {!error ? (
          <>
            <div className={isArabic ? "step4 arabic-component" : "step4 "}>
              <PriceRange
                country={country}
                loading={loading}
                product={state?.product}
                attributes={attributes}
                estimationvalues={attributes}
                attribute_values_id={Object.values(NewId).map(
                  (item) => item.id
                )}
              />
            </div>

            <div className="Questions_box">
              <div className="Questions_box_title">
                {loading ? (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{ fontSize: "1rem", width: "250px" }}
                  />
                ) : (
                  <h4>{t("Answer the questions")}</h4>
                )}
              </div>
              <div className="Questions_box_stepper">
                <Stepper
                  activeStep={activeStep}
                  style={{ marginBottom: "5px" }}
                >
                  {steps?.map((step, index) => (
                    <Step
                      className="stepper-ui"
                      key={index}
                      sx={{
                        width: `${100 / steps?.length}%`,
                        "&::after": {
                          content: '""',
                          display: "block",
                          height: 10,
                          borderRadius: "24px",
                          background:
                            index < activeStep
                              ? "linear-gradient(90deg, rgba(75, 164, 240, 0.38) 0%, rgba(85, 180, 223, 0.38) 30.5%, rgba(113, 228, 172, 0.38) 100%, rgba(113, 228, 172, 0.38) 100%)"
                              : index === activeStep
                              ? "linear-gradient(90deg, #4BA4F0 0%, #55B4DF 30.5%, #71E4AC 100%, #71E4AC 100%)"
                              : "#808080",
                        },
                      }}
                    ></Step>
                  ))}
                </Stepper>
                <div className="Question_answers_section">
                  {activeStep != steps?.length ? (
                    loading ? (
                      <h5 className="active_step">
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{ fontSize: "1rem", width: "150px" }}
                        />
                      </h5>
                    ) : (
                      <h5 className="active_step">
                        {activeStep} {t("out of")} {steps?.length - 1}
                      </h5>
                    )
                  ) : undefined}

                  <div>
                    {" "}
                    {/* Display attributes or questions based on active step */}
                    {steps?.length > 0 && (
                      <div>
                        {activeStep < attributes?.length ? (
                          <div>
                            <TitleComponent
                              title={`${activeStep + 1}. ${t("Select the")}
                                  ${
                                    attributes[activeStep]?.name?.[
                                      selectedLanguage
                                    ] || attributes[activeStep]?.name?.en
                                  }
                                `}
                              className="question_title"
                            />
                            <div className="Answers">
                              <div
                                className={
                                  attributes[activeStep]?.values?.length > 5
                                    ? "Answers_section display-grid"
                                    : "Answers_section "
                                }
                              >
                                {attributes[activeStep]?.values?.map(
                                  (attr, index, array) => (
                                    <>
                                      <span
                                        key={attr?.id}
                                        className={
                                          isArabic
                                            ? "span-answers"
                                            : attributes[activeStep]?.values?.length > 5
                                            ? "span-answers padding6"
                                            : "span-answers"
                                        }
                                      >
                                        <FormControlLabel
                                          control={<Radio color="primary" />}
                                          checked={
                                            selectedValues[activeStep] ===
                                            attr.id
                                          }
                                          onChange={() =>
                                            handleRadioButtonChange(
                                              attr,
                                              attributes[activeStep]?.name
                                            )
                                          }
                                          label={
                                            attr?.name?.[selectedLanguage] ||
                                            attr?.name?.en
                                          }
                                          classes={{
                                            label: "bold-label",
                                          }}
                                        />
                                        {attributes[
                                          activeStep
                                        ]?.name?.en.toLowerCase() ===
                                          "color" && (
                                          <span
                                            style={{
                                              width: "70%",
                                              display: "flex",
                                              justifyContent: "space-evenly",
                                            }}
                                          >
                                            <SquareColor
                                              color={findColor(attr?.name?.en)}
                                            />
                                          </span>
                                        )}
                                      </span>

                                      {attributes[activeStep]?.values?.length <
                                        6 && index !== array.length - 1 ? (
                                        <hr className="divider div-margin10" />
                                      ) : undefined}
                                    </>
                                  )
                                )}
                              </div>

                              <div className="Answers_image">
                                <Image
                                  className="Answers_img"
                                  src={
                                    process.env.REACT_APP_IMAGE_URL +
                                    attributes[activeStep]?.image_path
                                  }
                                  alt="question_image"
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            {activeStep != steps?.length ? (
                              <div>
                                <TitleComponent
                                  title={` ${activeStep + 1}. 
                               ${
                                 allQuestions[activeStep - attributes?.length]
                                   ?.question?.[selectedLanguage] ||
                                 allQuestions[activeStep - attributes?.length]
                                   ?.question?.en
                               }
                                `}
                                  className="question_title"
                                />

                                <div className="Answers">
                                  <div className="Answers_section_questions">
                                    {allQuestions[
                                      activeStep - attributes?.length
                                    ]?.answer
                                      .sort((a, b) => a.id - b.id)
                                      .map((answer, index, array) => (
                                        <>
                                          <span
                                            key={answer?.id}
                                            className={
                                              isArabic ? "" : "span-answers"
                                            }
                                          >
                                            <FormControlLabel
                                              control={
                                                <Radio color="primary" />
                                              }
                                              checked={
                                                SelectedAnswersValues[
                                                  activeStep
                                                ] === answer.id
                                              }
                                              onChange={() =>
                                                handleQuestionsAnswersChange(
                                                  answer,
                                                  allQuestions[
                                                    activeStep -
                                                      attributes?.length
                                                  ]?.question
                                                )
                                              }
                                              label={
                                                answer?.answer?.[
                                                  selectedLanguage
                                                ] || answer?.answer?.en
                                              }
                                              classes={{
                                                label: "bold-label",
                                              }}
                                            />
                                          </span>
                                          <p
                                            className={
                                              isArabic
                                                ? "answer_description_arabic answer_description"
                                                : "answer_description"
                                            }
                                          >
                                            {answer?.description?.[
                                              selectedLanguage
                                            ] || answer?.description?.en}
                                          </p>
                                          {index !== array.length - 1 && (
                                            <hr className="divider" />
                                          )}
                                        </>
                                      ))}
                                  </div>

                                  <div className="Answers_image">
                                    <Image
                                      className="Answers_img"
                                      src={
                                        process.env.REACT_APP_IMAGE_URL +
                                        getImagePath(
                                          SelectedAnswersValues[activeStep]
                                        )
                                      }
                                      alt="question_image"
                                    />
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <TitleComponent
                                  title={t("Device Summary")}
                                  className="device_summary_title"
                                />
                                <div className="device_summary">
                                  <div className="device_summary_questions">
                                    {Object.values(choosenAttributes).map(
                                      (item, index) => (
                                        <div className="sub-summary">
                                          <ShowMoreText
                                            className="device_summary_qa"
                                            initialText={`${index + 1}.  ${
                                              item.question?.[
                                                selectedLanguage
                                              ] || item.question?.en
                                            }`}
                                            maxLength={30}
                                          />
                                          <ShowMoreText
                                            className={
                                              isArabic
                                                ? "device_an_right device_summary_an"
                                                : "device_summary_an"
                                            }
                                            initialText={
                                              item.name?.[selectedLanguage] ||
                                              item.name?.en
                                            }
                                            maxLength={30}
                                          />
                                          <hr className="divider"></hr>
                                        </div>
                                      )
                                    )}
                                    {Object.values(QnA).map(
                                      (item, index, array) => (
                                        <div className="sub-summary">
                                          <ShowMoreText
                                            className="device_summary_qa"
                                            initialText={`${
                                              index + attributes?.length + 1
                                            }. ${
                                              item.question?.[
                                                selectedLanguage
                                              ] || item.question?.en
                                            }`}
                                            maxLength={30}
                                          />

                                          <ShowMoreText
                                            className={
                                              isArabic
                                                ? "device_an_right device_summary_an"
                                                : "device_summary_an"
                                            }
                                            initialText={
                                              item.answer?.[selectedLanguage] ||
                                              item.answer?.en
                                            }
                                            maxLength={30}
                                          />

                                          <hr className="divider" />
                                        </div>
                                      )
                                    )}
                                  </div>

                                  <div className="device_summary_estimation">
                                    {loadingestimation ? (
                                      <FinalEstimation
                                        isBreakable={Object.values(
                                          isBreakable
                                        ).some((item) => item === true)}
                                        price={result}
                                        setResult={setResult}
                                        finish={finishLoading}
                                        state={{
                                          attributes_answers: Object.values(
                                            NewId
                                          ).map((item) => ({
                                            attribute_id: item.attribute_id,
                                            attribute_value_id: item.id,
                                          })),
                                          questions: Object.values(QnA).map(
                                            (item) => ({
                                              question_id: item.question_id,
                                              answer_id: item.id,
                                            })
                                          ),
                                          price: result,
                                          imei_1: "",
                                          attributes: NewId,
                                          ...state,
                                        }}
                                        question={QnA}
                                        data={allQuestions}
                                        params={params}
                                        isPicea={false}
                                      />
                                    ) : (
                                      <Image
                                        className="paper_icon"
                                        src={paper}
                                        alt="question_image"
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                <div className="d-flex">
                  <>
                    <IconButton
                      onClick={() => handleBack()}
                      className={
                        activeStep == 0
                          ? "back_button_wrap visibilityHidden"
                          : "back_button_wrap"
                      }
                    >
                      <div className={isArabic ? "flipArrow" : "flipArrow-en"}>
                        <BackButtonSVG fill="#234292" />{" "}
                        <span className="back_button">{t("Back")}</span>
                      </div>
                    </IconButton>
                    {activeStep == steps?.length ? undefined : (
                      <Button
                        disabled={isNextDisabled}
                        onClick={handleNext}
                        name={t("Next")}
                        selected
                        width="15%"
                      />
                    )}
                  </>

                  {activeStep == steps?.length && !loadingestimation ? (
                    <Button
                      name={t("Evaluate")}
                      onClick={handleEvaluateContinue}
                      selected
                      width="15%"
                    />
                  ) : undefined}
                </div>
              </div>
            </div>
          </>
        ) : (
          <NoQuestionsFound />
        )}
      </div>
    </div>
  );
};
export default DeviceQuestion;
