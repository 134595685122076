import React, { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { isLoggedIn, getSelf, getBranchByID } from "../core/apis/users";
import { useGlobalValues } from "./GlobalContext";
import { getworkflowByType } from "../core/apis/newapis";
import { getallretailerInfocards } from "../core/apis/buyback";

//creating context
const UserContext = createContext({
  user: null,
  getSelfUser: () => {},
});

//user context .
const UserContextProvider = (props) => {
  //props distructuring
  const { children } = props;

  const [cookies, setCookies] = useCookies(["user_info", "user"]);

  const [organizationId, setorganizationId] = useState("");

  const [userDefaultLang, setuserDefaultLang] = useState("");

  const [userName, setuserName] = useState("");

  const [infoHeadLine, setinfoHeadLine] = useState([]);

  const {
    setOrganizationPiceaWorkflows,
    setOrganizationPiceaState,
    seTwoUseWorkFlowState,
    setOrganizationImages,
    setTwoUseVerfiyworkFlowStatemethod,
    setWipingworkFlowStatemethod,
    setOrganizationPiceaWipeWorkflows,
    setOrganizationtwoUseVerifyworkFlow,
    setOrganizationtwoUseWipeWorkFlow,
    setOrganizationWipePiceaState,
    setISEmployeeInfoEnabled,
    setThirdPartyValue,
    setBranchID,
    branchcountryID,
  } = useGlobalValues();

  const getWorkFlows = (twoUseState, piceaState) => {
    if (twoUseState) {
      getworkflowByType(1).then((res) => {
        const filterByWorkflowType = (workflowTypeId) =>
          res?.data?.data?.filter(
            (item) =>
              item?.picea_workflow?.picea_workflow_type?.id === workflowTypeId
          );
        const verifyFlow = filterByWorkflowType(6);
        const wipePicea = filterByWorkflowType(1);
        setOrganizationtwoUseVerifyworkFlow(verifyFlow);
        setOrganizationtwoUseWipeWorkFlow(wipePicea);
        if (verifyFlow.length > 0) {
          setTwoUseVerfiyworkFlowStatemethod(true);
        } else {
          setTwoUseVerfiyworkFlowStatemethod(false);
        }
        if (wipePicea.length > 0) {
          setWipingworkFlowStatemethod(true);
        } else {
          setWipingworkFlowStatemethod(false);
        }
      });
    }
    if (piceaState) {
      getworkflowByType(2).then((res) => {
        const filterByWorkflowType = (workflowTypeId) =>
          res?.data?.data?.filter(
            (item) =>
              item?.picea_workflow?.picea_workflow_type?.id === workflowTypeId
          );
        const retailerbuyBack = filterByWorkflowType(2);
        const wipePicea = filterByWorkflowType(1);
        setOrganizationPiceaWorkflows(retailerbuyBack);
        setOrganizationPiceaWipeWorkflows(wipePicea);
        if (wipePicea.length > 0) {
          setOrganizationWipePiceaState(true);
        } else {
          setOrganizationWipePiceaState(false);
        }
      });
    }
  };

  //fetching user information
  const getSelfUser = () =>
    getSelf().then((res) => {
      setuserDefaultLang(res?.language?.code);
      setuserName(res?.full_name);
      setCookies("user_info", JSON.stringify(res), { path: "/" });
      getBranchByID(res?.branch_id).then((res) => {
        setorganizationId(res?.organization?.organization_id);
        setBranchID(res?.address[0]?.country_id);
      });

      setThirdPartyValue(res?.third_party[0]?.id);
      // set Global Value for picea State and organization
      setOrganizationPiceaState(
        res?.branch?.organization?.organization?.picea_enabled
      );
      seTwoUseWorkFlowState(
        res?.branch?.organization?.organization?.two_use_enabled
      );
      setISEmployeeInfoEnabled(
        res?.branch?.organization?.organization?.enable_fill_form_employee
      );
      setOrganizationImages({
        logo:
          process.env.REACT_APP_IMAGE_URL +
          res?.branch?.organization?.organization.logo,
        backgroundImage:
          process.env.REACT_APP_IMAGE_URL +
          res?.branch?.organization?.organization?.background,
      });
      getWorkFlows(
        res?.branch?.organization?.organization?.two_use_enabled,
        res?.branch?.organization?.organization?.picea_enabled
      );
      getallretailerInfocards().then((res) => setinfoHeadLine(res));
    });

  const checkIfLoggedIn = () =>
    isLoggedIn().then((res) => {
      res?.data?.data && getSelfUser();
      setCookies("user", Boolean(res?.data?.data), { path: "/" });
    });

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  return (
    <UserContext.Provider
      value={{
        user: cookies?.user_info,
        getSelfUser,
        organizationId,
        userDefaultLang,
        userName,
        infoHeadLine,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

//exporting context
export { UserContextProvider, UserContext };
