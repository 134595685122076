import React from "react";
import { NoQuestionsFoundSVG } from "../../../../assests/Images/NoQuestionsFoundSVG";
import Button from "../../../../components/button/Button";
import { useTranslation } from "react-i18next";

const NoQuestionsFound = () => {
  const { t } = useTranslation();

  return (
    <div className="noQuestionsFound absolute">
      <h1>{t("Sorry... You can’t sell this device")}</h1>
      <p>{t("No questions were found")}</p>
      <NoQuestionsFoundSVG width="100%" />
      <Button href="/sell" width="80%" name={t("Show me other devices!")} selected />
    </div>
  );
};

export default NoQuestionsFound;
