//MARILYNN

//CSS
import "./TitleComponent.scss";
export function TitleComponent(props) {
  const { title, color, className } = props;

  return (
    <div style={{ color: color }} className={className}>
      {title}
    </div>
  );
}

export default TitleComponent;
