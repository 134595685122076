import React, { useState, useContext } from "react";
import { Form, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import * as AuthApi from "../../../core/apis/auth";
import "./ResetPassword.scss";
import Button from "../../../components/button/Button";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AlertContext } from "../../../context/AlertContext";
import { useTranslation } from "react-i18next";
import { useGlobalLangValues } from "../../../context/LanguageContext";

function ResetPassword() {
  // 2nd step

  const { t } = useTranslation();
  const { isArabic } = useGlobalLangValues();

  let location = useLocation().state;
  const { setAlert } = useContext(AlertContext);
  const [radio, setRadio] = useState({ otp: false, email: true });
  const [loading, setLoading] = useState(false);
  let navigate = useNavigate();
  const handleChange = (e) => {
    e.target.value === "otp"
      ? setRadio({ otp: true, email: false })
      : e.target.value === "email"
      ? setRadio({ otp: false, email: true })
      : setRadio({ otp: false, email: false });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      user_type_id: 2,
      email: location?.email,
    };
    radio.otp
      ? await AuthApi.resetPasswordByOTP(data).then((res) => {
          setAlert({
            visible: true,
            text: t(res.data.message),
            type: res.data.success ? "success" : "error",
          });
          res.data.success &&
            navigate(`/reset-password/by-OTP`, {
              state: { email: location?.email },
            });
        })
      : radio.email &&
        (await AuthApi.resetPasswordByEmail(data).then((res) => {
          setAlert({
            visible: true,
            text: res.data.success && t(res.data.message),
            type: res.data.success ? "success" : "error",
          });
          res.data.success && setTimeout(() => navigate("/login"), 2000);
        }));
    setLoading(false);
  };

  return (
    <div className="main-login-body">
      <div
        className={
          isArabic ? "forgot-password arabic-component" : "forgot-password"
        }
      >
        <h2>{t("Reset Password")}</h2>
        <Form onSubmit={handleSubmit} className=" resetPasswordForm">
          <Form.Group as={Row} className="mb-3">
            <RadioGroup defaultValue="email" name="radio-buttons-group">
              <FormControlLabel
                value="email"
                control={<Radio />}
                id="email"
                label={t("Reset by Email")}
                onChange={handleChange}
              />
              <FormControlLabel
                value="otp"
                control={<Radio />}
                label={t("Reset by OTP")}
                id="otp"
                onChange={handleChange}
              />
            </RadioGroup>
          </Form.Group>
          <Button
            id="loginBtn"
            onClick={handleSubmit}
            name={t("Continue")}
            width="100%"
            selected
            loading={loading}
          />
        </Form>
      </div>
    </div>
  );
}

export default ResetPassword;
