import React, { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getLiveRate, getCurrencyFromDb } from "../core/apis/paymentGateways";
import { defaultCurrencyCode } from "../core/variables/variables";

const CurrencyContext = createContext();
const CurrencyContextProvider = (props) => {
  const { children } = props;

  // the value that will be given to the context
  const [cookies, setCookie] = useCookies(["currency", "rate"]);
  const [loadingCurrency, setLoadingCurrency] = useState(false);
  const UpdateCurrency = (newCurrency) => {
    setLoadingCurrency(true);
    getCurrencyFromDb(newCurrency?.id).then((res) => {
      setCookie("rate", res?.data?.data?.conversion_rate);
      setLoadingCurrency(false);
    });
    setCookie("currency", newCurrency, { path: "/" });
  };
  return (
    // the Provider gives access to the context to its children
    <CurrencyContext.Provider
      value={{
        currency: cookies?.currency,
        rate: cookies?.rate,
        loadingCurrency,
        setCurrency: UpdateCurrency,
      }}
    >
      {children}
    </CurrencyContext.Provider>
  );
};

export { CurrencyContext, CurrencyContextProvider };
