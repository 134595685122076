import React, { Suspense, useContext, useEffect, useState } from "react";

import "./step-1-brand.scss";
import { Link, useParams } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";
import { CountryContext } from "../../../context/CountryContext";
import Image from "../../../components/image/Image";
import { getAllBrandsByCategory } from "../../../core/apis/newapis";
import { useGlobalValues } from "../../../context/GlobalContext";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { Tooltip, tooltipClasses, styled } from "@mui/material";
import { IconButton, SvgIcon } from "@mui/material";
import { iSVG } from "../../../assests/icons/SVG";
import { UserContext } from "../../../context/UserContext";
import informationSvg from "../../../assests/Images/information.png";
import NoDataFound from "../../../components/NodataFound/nodatafound";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const BrandStep = () => {
  const { country } = useContext(CountryContext);
  const { selectedItemId } = useGlobalValues();
  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const [brands, setBrands] = useState();
  const [isLoading, setLoading] = useState(true);

  const [showText, setshowText] = useState(false);

  const { infoHeadLine } = useContext(UserContext);

  const { t } = useTranslation();

  let params = useParams();

  const fetchBrands = async () => {
    setLoading(true);
    const response = await getAllBrandsByCategory({
      country_id: country?.id,
      category_id: selectedItemId,
    });
    let data = response?.data?.data?.sort((a, b) => a.id - b.id);
    setBrands(data);
    setLoading(false);
    setTimeout(() => {
      setshowText(true);
    }, 900);
  };
  useEffect(() => {
    fetchBrands();
  }, []);

  return (
    <div className="main-sub-body">
      <div
        style={{ display: "flex", alignItems: "left" }}
        className={isArabic ? "arabic-component" : ""}
      >
        <h1
          style={{ color: "white" }}
          className={isArabic ? "arabic-component" : ""}
        >
          {" "}
          {t("ChooseaBrand")}
        </h1>
        <LightTooltip
          style={{ margin: "0 10px 10px" }}
          className="conditions-tooltip"
          title={
            <div className={isArabic ? "arabic-component" : ""}>
              <h6 style={{ color: "#4BA4F0" }}>{t("Brands")}</h6>
              <h6 style={{ color: "#041C3F" }}>
                {infoHeadLine
                  ? infoHeadLine[0]?.information?.[selectedLanguage] ||
                    infoHeadLine[0]?.information?.en
                  : ""}
              </h6>
            </div>
          }
          arrow
          placement="right"
        >
          <IconButton
            aria-label="info"
            color="primary"
            style={{ margin: "10px 0px" }}
          >
            <img src={informationSvg} />
          </IconButton>
        </LightTooltip>
      </div>

      <div
        className={isArabic ? "selectBrand  arabic-component" : "selectBrand "}
      >
        {!isLoading && brands
          ? brands?.length > 0 &&
            brands?.map((data) => (
              <Button
                aria-label="navigate to brands"
                key={data.id}
                component={Link}
                className="box"
                to={`/sell/${params.category}/${data?.slug}`}
              >
                <Image
                  className="picture"
                  src={process.env.REACT_APP_IMAGE_URL + data?.image_path}
                  alt={data?.name?.[selectedLanguage]}
                />

                <p>
                  {data?.name?.[selectedLanguage]
                    ? data?.name?.[selectedLanguage]
                    : data?.name?.en}
                </p>
              </Button>
            ))
          : [...Array(6)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                width={250}
                height={250}
                className="box-Skeleton"
              ></Skeleton>
            ))}
      </div>
      <div className="nodataComponent">
        {!isLoading && brands?.length == 0 ? <NoDataFound /> : null}
      </div>
    </div>
  );
};

export default BrandStep;
