import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Skeleton,
} from "@mui/material";
import { useGlobalValues } from "../../../context/GlobalContext";
import { getPiceaToken, getPiceaDeviceInfo } from "../../../core/apis/newapis";
import Swal from "sweetalert2";

const VerifyFlow = () => {
  let navigate = useNavigate();

  let params = useParams();

  const {
    TwoUsewipingworkFlowState,
    piceaOrganizationWorkFlow,
    twoUseVerifyworkFlow,
    updateGlobalState,
  } = useGlobalValues();

  const [isLoading, setisLoading] = useState(true);

  const [piceaInfo, setpiceaInfo] = useState();

  const [deviceInfo, setdeviceInfo] = useState();

  let { state } = useLocation();

  const onVerifyCompleted = async (tx) => {
    setisLoading(false);
    if (tx.status == 0) {
      setpiceaInfo(tx);
      getDeviceInfo(tx);
      await updateGlobalState({
        piceaValues: tx,
      });
    } else if (tx.status == 2) {
      showErrorPopup(
        "Due to a test failure, the process has been declined. Please check your phone to find out why"
      );
      //handleNavigate();
    } else if (tx.status == 1) {
      navigate("/sell");
    }
  };

  const getDeviceInfo = async (tx) => {
    let payload = {
      category: params.category,
      piceaWidgetData: tx,
    };
    getPiceaDeviceInfo(payload).then((res) => {
      if (res.data.data.length != 0) {
        setdeviceInfo(res.data.data[0]);
        handleNavigate(res.data.data[0], tx);
      } else {
        showErrorPopup(
          "This phone is not the same phone selected in the question flow. Please try again later or contact our help team"
        );
      }
    });
  };

  const handleNavigate = async (data, tx) => {
    let productId = state.state.product.id;
    let deviceInfoID = data.id;
    if (productId == deviceInfoID) {
      navigate(
        `/sell/${Object.values(params)
          .toString()
          .split(",")
          .join("/")}/checkout`,
        {
          state: {
            state: state.state,
            isPicea: state.isPicea,
            piceaVerifyData: tx,
            isWiping: TwoUsewipingworkFlowState,
          },
        }
      );
    } else {
      showErrorPopup(
        "This phone is not the same phone selected in the question flow. Please try again later or contact our help team"
      );
    }
  };

  useEffect(() => {
    getToken();
  }, []);

  const getToken = async () => {
    console.log(twoUseVerifyworkFlow);

    let id = parseInt(
      twoUseVerifyworkFlow[0]?.picea_workflow.picea_workflow_id
    );

    let payload = {
      picea_workflow_id:
        twoUseVerifyworkFlow[0]?.picea_workflow.picea_workflow_id,
    };
    await new Promise((resolve) => setTimeout(resolve, 5000));
    getPiceaToken(payload).then((res) => {
      if (res?.data?.data?.token) {
        window.picea.run(
          { token: res?.data?.data?.token, autorun: id, responseVersion: 2 },
          onVerifyCompleted
        );
      } else if (res.data.data == null) {
        alert("token is null");
        navigate("/sell");
      }
    });
  };

  const showErrorPopup = (text) => {
    let timerInterval;
    Swal.fire({
      icon: "error",
      title: "Error !",
      html: text,
      timer: 8000,
      timerProgressBar: true,
      confirmButtonText: "continue",
      showClass: {
        popup: "swal2-show",
        backdrop: "swal2-backdrop-show",
        icon: "swal2-icon-show",
      },
      customClass: {
        container: "custom-swal-container",
        background: "custom-swal-background",
      },
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const timeLeft = Math.ceil(Swal.getTimerLeft() / 1000);
        }, 10);
      },
      willClose: () => {
        navigate("/sell");
      },
    });
  };

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton className="box" height={225} />
          <Skeleton className="box" height={225} />
          <Skeleton className="box" height={225} />
          <Skeleton className="box" height={225} />
        </>
      ) : (
        <div></div>
      )}
    </>
  );
};

export default VerifyFlow;
