import { api } from "./main";

export const sendOTP = (body) => {
  return api
    .post("retailer/send-otp", body)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const verifyOTP = (body) => {
  return api
    .post("retailer/verify-otp", body)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const resendOTP = (body) => {
  return api
    .post("retailer/resend-otp", body)
    .then((res) => res)
    .catch((err) => console.error(err));
};
