export const conditions = [
  {
    label: "Excellent",
    color: "#11AEE0",
    background: "#E9F5FF",
    db_id: 2,
    description: `Devices are unlocked in almost new condition. they don’t have any signs of use, No dents, and scratches. Battery health should be 90-95%
`,
  },
  {
    label: "Very Good",
    color: "#74D301",
    background: "#E2FFBF",
    db_id: 3,
    description: `The devices are unlocked they look in very good condition;
                    no dents & minor scratches, they have very slight signs of
                    use that cannot be noticeable. Battery health should be
                    86-90%`,
  },
  {
    label: "Good",
    color: "#FFC700",
    background: "#FFF2C0",
    db_id: 4,
    description: `Devices are unlocked they have moderate signs of wear; scratches are slightly noticeable & have minor dents. The screen may be replaced. Battery health should be 80-85%`,
  },
  {
    label: "Fair",
    color: "#EF8800",
    background: "#FFEBD0",
    db_id: 5,
    description: `Devices are unlocked and they have clear signs of use with scratches, dents, and color peel on the body. Battery health should be below 80%
`,
  },
];

export const defaultCurrencyCode = "AED";
export const defaultCurrency = {
  id: 4,
  code: "AED",
  name: "United Arab Emirates Dirham",
  symbol: "AED",
};

export const username = "2use.lb.api@2U";

export const password = "$FXL@onjy22BU6MPw#Ub";
