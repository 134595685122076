import axios from "axios";
import { Cookies } from "react-cookie";
import Swal from "sweetalert2";

export const REACT_APP_IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

export let api = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_WEB_URL,
  headers: {
    "X-Authorization":
      "AoKeVdwXUfTBe8kOTPsSJ0vZYWY4qFGmEqtJYs1M7osYXBSiCsXeqXEOAfgzC7oL",
  },
});

// Set the AUTH token for any request
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      await axios
        .get("auth/refresh-token", {
          withCredentials: true,
          baseURL: process.env.REACT_APP_WEB_URL,
          headers: {
            "X-Authorization":
              "AoKeVdwXUfTBe8kOTPsSJ0vZYWY4qFGmEqtJYs1M7osYXBSiCsXeqXEOAfgzC7oL",
          },
        })
        .catch(() => {
          if (!isLoginPage()) {
            showSessionExpiredPopup();
          } else {
            const cookies = new Cookies();
            cookies.remove("user");
            window.location.replace("/login");
          }
        });
      return axios(error.config);
    } else {
      return Promise.reject(error);
    }
  }
);

function showSessionExpiredPopup() {
  const selectedLanguage = localStorage.getItem("selectedLanguage");

  let timerInterval;
  Swal.fire({
    icon: "error",
    title:
      selectedLanguage != "ar"
        ? "Your session has expired."
        : "الجلسة الخاصة بك قد انتهت",
    html:
      selectedLanguage != "ar"
        ? "Please log in again in <b></b> seconds"
        : "الرجاء تسجيل الدخول مرة أخرى خلال <b></b> ثانية",
    timer: 4000,
    timerProgressBar: true,
    confirmButtonText: selectedLanguage != "ar" ? "continue" : "استمر",
    customClass: {
      container: "custom-swal-container",
      background: "custom-swal-background",
    },
    didOpen: () => {
      Swal.showLoading();
      const timer = Swal.getPopup().querySelector("b");
      timerInterval = setInterval(() => {
        const timeLeft = Math.ceil(Swal.getTimerLeft() / 1000);
        timer.textContent = timeLeft;
      }, 10);
    },
    willClose: () => {
      clearInterval(timerInterval);
      const cookies = new Cookies();
      cookies.remove("user");
      window.location.replace("/login");
    },
  });
}

function isLoginPage() {
  if (window.location.pathname === "/login")
    return true;
  else return false;
}
