import React from "react";
import "../../../../components/disclaimer/Disclaimer.scss";
import background from "../../../../assests/Images/2useBackground.png";
import { LogoSVG } from "../../../../assests/icons/SVG";
import Barcode from "react-barcode/lib/react-barcode";
export const Barcodee = React.forwardRef((props, ref) => {
  const { data } = props;

  return (
    <div ref={ref} className="disclaimer">
      <table>
        <thead>
          <tr>
            <td>
              <div className="header-space" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <main>
                <>
                  <div className="disclaimerText">
                    <h2>Barcode</h2>
                    <br />
                    <div>
                      <Barcode
                        value={data?.transaction_details?.barcode}
                        textPosition={"left"}
                        fontSize={10}
                        height={25}
                        margin={0}
                      />
                    </div>
                    <br />
                  </div>
                </>
              </main>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-space"> </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <img alt="background" src={background} className="watermark" />
    </div>
  );
});
