import React, { useContext, useEffect, useState } from "react";
import "./step-2-selectSubCategory.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";
import { getCategoryBySlug } from "../../../core/apis/category";
import { CountryContext } from "../../../context/CountryContext";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { customSort } from "../../../core/functions/Functions";
import { Tooltip, tooltipClasses, styled } from "@mui/material";
import { IconButton, SvgIcon } from "@mui/material";
import { UserContext } from "../../../context/UserContext";
import Image from "../../../components/image/Image";
import informationSvg from "../../../assests/Images/information.png";
import NoDataFound from "../../../components/NodataFound/nodatafound";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const SubCategoryStep = () => {
  const { brand, category } = useParams();
  let navigate = useNavigate();
  const [Serie, setSerie] = useState([]);
  const [loading, setLoading] = useState(true);
  const { country } = useContext(CountryContext);
  const { infoHeadLine } = useContext(UserContext);
  const [showText, setshowText] = useState(false);

  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const { t } = useTranslation();

  useEffect(() => {
    !category && navigate("/sell");
    let payload = {
      country_id: country.id,
      brand_slug: brand,
    };
    getCategoryBySlug(category, payload).then((res) => {
      let data = res?.data?.data?.child_with_product;
      data.sort(customSort);
      setSerie(data);
      setLoading(false);
    });
  }, []);
  return (
    <div className="main-sub-body">
      <div
        style={{ display: "flex", alignItems: "left" }}
        className={isArabic ? " arabic-component " : ""}
      >
        <h1
          style={{ color: "white" }}
          className={isArabic ? " arabic-component " : ""}
        >
          {t("ChooseaSubCategory")}
        </h1>
        <LightTooltip
          style={{ margin: "0 10px 10px" }}
          className="conditions-tooltip"
          title={
            <div className={isArabic ? "arabic-component" : ""}>
              <h6 style={{ color: "#4BA4F0" }}>{t("Model")}</h6>

              <h6 style={{ color: "#041C3F" }}>
                {infoHeadLine
                  ? infoHeadLine[2]?.information?.[selectedLanguage] ||
                    infoHeadLine[2]?.information?.en
                  : ""}
              </h6>
            </div>
          }
          arrow
          placement="right"
        >
          <IconButton
            aria-label="info"
            color="primary"
            style={{ margin: "10px 0px" }}
          >
            <img src={informationSvg} />
          </IconButton>
        </LightTooltip>
      </div>
      <div
        className={
          isArabic ? "arabic-component subCategoryFlow " : "subCategoryFlow "
        }
      >
        {!loading ? Serie?.length > 0 &&
            Serie?.map((item) => (
              <Button
                component={item.is_coming_soon ? "button" : Link}
                className={`box ${item.is_coming_soon ? "boxdisabled" : ""}`}
                to={`/sell/${category}/${brand}/${item?.slug}`}
                state={{
                  products: item?.product_with_max_price,
                }}
                key={item.id}
              >
                {+item.is_coming_soon ? (
                  <p className="comingSoon">Coming Soon</p>
                ) : null}

                <Image
                  className="picture"
                  src={process.env.REACT_APP_IMAGE_URL + item.image_path}
                  alt={item?.name?.en}
                />

                <p>
                  {" "}
                  {item?.name?.[selectedLanguage]
                    ? item?.name?.[selectedLanguage]
                    : item?.name?.en}
                </p>
              </Button>
            ))
          : [...Array(6)].map((_, index) => (
              <Skeleton
                key={index}
                variant="rounded"
                width={250}
                height={250}
                className="box-Skeleton"
              />
            ))}
      </div>
      <div className="nodataComponent">{!loading && Serie?.length == 0 ? <NoDataFound /> : null}</div>
    </div>
  );
};
export default SubCategoryStep;
