import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";
import { useTranslation } from "react-i18next";
//COMPONENT
import OpenTransactionsFilter from "./OpenTransactionsFilter";
import { useQuery } from "@tanstack/react-query";
import CountUp from "react-countup";
import { Skeleton } from "@mui/material";
import Paginator from "../../../components/paginator/Paginator";

//SCSS
import "./OpenTransaction.scss";
//API
import { getSales } from "../../../core/apis/transactions";
import translateToArabicCalendar from "../../../core/functions/moment";
import Table from "@mui/material/Table";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const OpenTransaction = () => {
  let navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [refetch, setRefetch] = useState(false);
  const { t, i18n } = useTranslation();
  const [arabicComponent, setArabicComponent] = useState(false); // State to track Arabic language

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth < 1700;

  const skeletonRows = Array.from({ length: 10 }); // Create an array to render skeletons (5 rows)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let payload = {
    per_page: isMobile ? 10 : 30,
    barcode: searchParams.get("barcode"),
    transaction_status_id: searchParams.getAll("transaction_status_id"),
    is_all: searchParams.get("is_all"),
    start_date: searchParams.get("start_date"),
    end_date: searchParams.get("end_date"),
    customer_name: searchParams.get("customer_name"),
    customer_phone: searchParams.get("customer_phone"),
  };

  const {
    isLoading: loading,
    data: transactions,
    isFetching,
  } = useQuery({
    queryKey: [`sales-transactions`, refetch],
    queryFn: () =>
      getSales(payload, searchParams.get("page")).then((res) => res),
  });

  const handleTable = (item) => () => {
    navigate(
      item?.transation_type?.name === "buy"
        ? `/buying-transation/${item.id}`
        : `/sale-details/${item.id}`
    );
  };

  // Effect to set Arabic component class
  useEffect(() => {
    setArabicComponent(i18n.language === "ar");
  }, [i18n.language]);

  return (
    <div className="main-sub-body">
      <div
        className={`open-transaction  ${
          arabicComponent ? "arabic-component" : ""
        }`}
      >
        <div className="mainTitle">{t("Sales")}</div>
        <div className="open-transaction-body">
          <TableContainer
            component={Paper}
            sx={{
              padding: "10px",
              border: "1px solid",
              borderRadius: "10px",
            }}
            className="tablecontainerheight"
          >
            <OpenTransactionsFilter
              setSearchParams={setSearchParams}
              isFetching={isFetching}
              searchParams={searchParams}
              setRefetch={() => setRefetch(!refetch)}
            />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>{t("Sales Number")}</TableCell>
                  <TableCell>{t("Barcode")}</TableCell>
                  <TableCell>{t("Date")}</TableCell>
                  <TableCell>{t("Price")}</TableCell>
                  <TableCell>{t("Transaction Status")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isFetching
                  ? // Show skeletons if fetching data
                    skeletonRows.map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width={100} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={150} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={120} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={80} />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width={150} />
                        </TableCell>
                      </TableRow>
                    ))
                  : transactions?.data?.map((item) => (
                      <TableRow
                        onClick={handleTable(item)}
                        key={item.key}
                        className="trBody"
                      >
                        <TableCell> {item?.id}</TableCell>
                        <TableCell> {item?.barcode}</TableCell>
                        {arabicComponent ? (
                          <TableCell>
                            {" "}
                            {translateToArabicCalendar(item?.created_at)}
                          </TableCell>
                        ) : (
                          <TableCell>
                            {" "}
                            {moment(item?.created_at)
                              .locale("en")
                              .calendar()}{" "}
                          </TableCell>
                        )}

                        <TableCell>
                          {" "}
                          <CountUp
                            end={item?.inspection?.[0]?.inspection_value}
                            duration={0.9}
                            decimals={2}
                          />
                          {` ${item?.currency?.code}`}
                        </TableCell>

                        <TableCell>
                          {" "}
                          {t(item?.transaction_status?.name?.en)}
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {!isFetching && (
              <Paginator
                count={transactions?.last_page}
                setRefetch={() => setRefetch(!refetch)}
              />
            )}
          </TableContainer>
        </div>
      </div>
    </div>
  );
};

export default OpenTransaction;
