import { useState, useEffect, useCallback, useContext } from "react";
import { AlertContext } from "../context/AlertContext";
import { api } from "../core/apis/main";

export const useGet = (props) => {
  const { url, payload, start = true, reloading } = props;
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(0);
  const refetch = () => setReload((prev) => prev + 1);
  useEffect(() => {
    reloading && setLoading(true);
    start &&
      api
        .request({
          method: "get",
          url,
          params: payload,
        })
        .then((res) => {
          setData(res);
        })
        .catch((err) => {
          localStorage.setItem("SESSION", false);
          console.error("err", err);
          console.log(
            "err",
            Object.values(err),
            Object.keys(err),
            err.response.status
          );

          setError(err);
        })
        .finally(() => setLoading(false));
  }, [reload, JSON.stringify(payload), url]);
  return { data, setData, error, loading, refetch };
};
export const useAxios = (url, method, payload) => {
  const { setAlert } = useContext(AlertContext);
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const callAPI = useCallback(() => {
    setLoading(true);
    api
      .request({
        data: payload,
        method,
        url,
      })
      .then((res) => {
        setData(res);
        setAlert({
          text: res.data.message,
          type: res.data.success ? "success" : "error",
        });
      })
      .catch((err) => {
        localStorage.setItem("SESSION", false);
        console.error("err", err);
        setError(err);
      })
      .finally(() => setLoading(false));
  }, [url, payload]);

  return { data, error, loading, callAPI };
};
