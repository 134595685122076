// GlobalContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import { getalllanguages } from "../core/apis/newapis";
import { UserContext } from "./UserContext";
import { getSelf, isLoggedIn } from "../core/apis/users";
import { useTranslation } from "react-i18next";

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const { t, i18n } = useTranslation();

  const [enabledLanguages, setenabledLanguages] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState("ar");
  const [isArabic, setisArabic] = useState(false);

  const langValues = [
    {
      id: 7,
      name: "Arabic",
      code: "ar",
      is_enabled: true,
    },
    {
      id: 40,
      name: "English",
      code: "en",
      is_enabled: true,
    },
  ];

  const setisArabicComponent = (value) => {
    setisArabic(value);
  };

  const setLanguageCode = (id) => {
    setSelectedLanguage(id);
  };

  useEffect(() => {
    setLanguageCode(i18n.language);
    localStorage.setItem("selectedLanguage", i18n.language);
    if (i18n.language === "ar") {
      setisArabic(true);
    } else {
      setisArabic(false);
    }
    getalllanguages().then((res) => {
      setenabledLanguages(res.data.data);
    });
  }, [i18n.language]);

  return (
    <LanguageContext.Provider
      value={{
        selectedLanguage,
        setLanguageCode,
        isArabic,
        setisArabicComponent,
        enabledLanguages,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export const useGlobalLangValues = () => {
  return useContext(LanguageContext);
};
