import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../header/Header";
import { isUserLoggedIn } from "../../core/apis/auth";
import Notification from "../../firebase/Notification";
import { Outlet } from "react-router-dom";
import * as Sentry from "@sentry/react";
import "./RootLayout.scss";
import { useGlobalValues } from "../../context/GlobalContext";

function ErrorFallback({ error, resetErrorBoundary }) {
  return (
    <div role="alert">
      <p>Something went wrong</p>
      {/* <pre>{error.message}</pre> */}
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

const RootLayout = () => {
  const [blueBackground, setBlueBackground] = useState(false);
  const location = useLocation();

  const { Oragnizationimages } = useGlobalValues();

  useEffect(() => {
    // Determine if the current route should have a blue background

    let currentLocation = location.pathname.split("/");
  
    const shouldUseBlueBackground = currentLocation[1] === "";
    setBlueBackground(shouldUseBlueBackground);

    // Clean up the effect by returning a cleanup function
    return () => {
      setBlueBackground(false); // Reset state when component unmounts (optional)
    };
  }, [location.pathname]);

  return (
    <>
      <Header />
      {isUserLoggedIn() && <Notification />}
      <main
        className={`main-body ${
          blueBackground ? "blueBackground" : "gradientBackground"
        }`}
        style={{
          backgroundImage: blueBackground
            ? `url(${Oragnizationimages?.backgroundImage})`
            : "",
        }}
      >
        <Sentry.ErrorBoundary fallback={ErrorFallback} showDialog>
          <Outlet />
        </Sentry.ErrorBoundary>
      </main>
    </>
  );
};

export default RootLayout;
