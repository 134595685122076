import moment from 'moment';
import 'moment/locale/ar'; // Import Arabic locale for Moment.js

// Function to translate date into Arabic calendar format
const translateToArabicCalendar = (createdAt) => {
  // Set locale to Arabic
  moment.locale('ar');
  // Return the Arabic translation of the calendar format
  return moment(createdAt).calendar();
};

export default translateToArabicCalendar