import React, { Suspense } from "react";
import "./step-1-chooseMethod.scss";
import { Link, useParams } from "react-router-dom";
import { Button, Skeleton } from "@mui/material";
import piceaimage from "../../../assests/Images/automation-testing.png";
import tuselogo from "../../../assests/Images/2use.png";
import Image from "../../../components/image/Image";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { useGlobalValues } from "../../../context/GlobalContext";

const ChoosMethod = () => {
  let params = useParams();
  const { piceaOrganizationState, twoUseWorkFlowState } = useGlobalValues();
  const { t } = useTranslation();

  const { selectedLanguage, isArabic } = useGlobalLangValues();

  const methods = [
    { name: "Auto Inspection", id: 1, status: piceaOrganizationState },

    { name: t("2USEworkflow"), id: 2, status: twoUseWorkFlowState },
  ];
  return (
    <div className="main-sub-body">
      <h1 className={isArabic ? "arabic-component" : ""}>
        {t("Choose a Method")}
      </h1>
      <div
        className={
          isArabic
            ? "chooseMethod fade-in arabic-component"
            : "chooseMethod fade-in"
        }
      >
        {methods ? (
          methods?.length ? (
            methods?.map((data) =>
              data.status ? (
                <Button
                  aria-label="navigate to subCategory or Picea widget"
                  key={data.id}
                  component={Link}
                  className="box"
                  to={
                    data.id === 1
                      ? `/sell/${params.category}/picea`
                      : `/sell/${params.category}`
                  }
                >
                  <Image
                    width="250"
                    height="250"
                    className="picture"
                    src={data.id === 1 ? piceaimage : tuselogo}
                    alt={data?.name}
                  />
                  <p> {data?.name}</p>
                </Button>
              ) : null
            )
          ) : (
            <h1 className="absolute">{t("No Data Found")}</h1>
          )
        ) : (
          [...Array(4)].map((_, index) => (
            <Skeleton className="box" key={index} height={250} />
          ))
        )}
      </div>
    </div>
  );
};

export default ChoosMethod;
