import React, { Fragment, useContext, useEffect } from "react";
import { useRef, useState } from "react";
import "./form.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/button/Button";
import { PhoneInput } from "../../../components/phoneInput/PhoneInput";
import { checkPhoneValidity } from "../../../core/apis/users";
import { DialogContent, DialogTitle, Button as MUIButton } from "@mui/material";
import {
  Radio,
  FormControlLabel,
  RadioGroup,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Dialog, DialogActions } from "@mui/material";
import { useGet } from "../../../hooks/useFetch";
import { Disclaimer } from "../../../components/disclaimer/Disclaimer";

import { Barcode } from "../../../components/disclaimer/barcode";
import {
  FormDropdownList,
  FormInput,
} from "../../../components/formComponents/FormComponents";
import * as OTP from "../../../core/apis/PhoneVerification";
import { selldevice } from "../../../core/apis/sellmydevice";
import SigninPage from "./du/siginpage";
import { OTPSVG, EmailSVG } from "../../../assests/icons/SVG";
import ReactToPrint from "react-to-print";
import OtpInput from "react-otp-input";
import moment from "moment";
import Countdown from "react-countdown";
import { AlertContext } from "../../../context/AlertContext";
import { UserContext } from "../../../context/UserContext";
import Image from "../../../components/image/Image";
import wp from "../../../assests/Images/whatsapp.png";
import mail from "../../../assests/Images/gmail.png";
import sms from "../../../assests/Images/comment.png";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useGlobalValues } from "../../../context/GlobalContext";

const PiceaCheckout = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let params = useParams();
  const componentRef = useRef();
  const barcodeRef = useRef();
  const printRef = useRef();
  const barprintRef = useRef();
  const { setAlert } = useContext(AlertContext);
  const [phone_number_error, setPhone_number_error] = useState("");
  const [otp, setOtp] = useState({ otp: "", id: "" });
  const [thirdParty, setThirdParty] = useState("");
  const [Payload, setPayload] = useState({});
  const [loading, setLoading] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);
  const [errormsg, seterrormsg] = useState("");
  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const [isOpen, setOpen] = useState({ completed: false, otp: false });
  const [result, setResult] = useState({});
  const [time, setTime] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [popup, setpopup] = useState(false);
  const [sendTypeId, setSendTypeId] = useState(1);
  const [serialpopUp, setSerialPopUp] = useState(false);
  const [serialNumber, setserialNumber] = useState("");
  const [categoryValue, setcategoryValue] = useState();
  let DuIntegration = +localStorage.getItem("integration") === 2;
  const { data: countries, countriesLoading } = useGet({
    url: `get-all-countries`,
  });
  let countriesArray = countries?.data?.data;

  const { setThirdPartyValue, employeInfoEnabled } = useGlobalValues();

  const [cookies, setCookies] = useCookies(["user_info", "user", "nlog"]);
  const { t } = useTranslation();

  const formSchema = yup.object({
    first_name: yup
      .string("Enter your full name")
      .required(t("First Name is required")),
    middle_name: yup
      .string("Enter your full name")
      .required(t("Middle Name is required")),
    last_name: yup
      .string("Enter your full name")
      .required(t("Last Name is required")),
    full_address: yup
      .string("Enter your full address")
      .required(t("Full Address is required")),
    city: yup.string("Enter your full address").required(t("City is required")),
    paper_type: yup
      .number("Enter your ID Or Passport Number")
      .required(t("ID Or Passport Number required")),
    id_or_passport_number: yup
      .string("")
      .nullable()
      .when("paper_type", {
        is: 1,
        then: yup
          .string("Enter a valid ID")
          .nullable()
          .matches(/^[0-9]+$/, "Enter a valid ID")
          .required(t("Field is required")),
        otherwise: yup
          .string("Enter a valid passport number")
          .required(t("Field is required")),
      }),

    email: yup
      .string("Enter your email")
      .email("Enter a valid email")
      .required(t("Email is required")),
    nationality: yup.object().nullable().required(t("Field is required")),

    phone_number: yup.number().nullable().required(t("Field is required")),

    EmployeeID:
      employeInfoEnabled && employeInfoEnabled
        ? yup.string().nullable().required(t("Field is required"))
        : yup.string().nullable(),
    employeeName:
      employeInfoEnabled && employeInfoEnabled
        ? yup.string().nullable().required(t("Field is required"))
        : yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      first_name: "",
      middle_name: "",
      last_name: "",
      nationality: null,
      phone_number: null,
      paper_type: 1,
      id_or_passport_number: "",
      full_address: "",
      city: "",
      street: "",
      building: "",
      employeeName: "",
      EmployeeID: "",
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  //select whether verifying by OTP or by Email
  const handleRadio = (e) => {
    setSendTypeId(+e.target.value);
    setLoading(false);
  };
  //handle Phone Number
  const handleNumber = () => (number, info) => {
    setDialCode(info?.dialCode);
    formik.setFieldValue("phone_number", number);
    setPhone_number_error("");
  };

  const checkPhone = async (phone_number) => {
    let response = false;
    if (!phone_number) {
      setPhone_number_error(t("Field is required"));
      response = false;
    } else {
      await checkPhoneValidity({ phone_number: phone_number }).then((res) => {
        if (res?.data?.data) {
          setPhone_number_error("");
          response = true;
        } else {
          setPhone_number_error(t("Invalid phone number"));
          response = false;
        }
      });
    }

    return response;
  };

  //if no state redirect to sell route
  useEffect(() => {
    !location.state && navigate("/sell");
  }, [location.state, navigate]);
  //
  useEffect(() => {
    if (employeInfoEnabled) {
      setSerialPopUp(true);
    }
    if (params.category) {
      setcategoryValue(params.category);
    } else {
      setcategoryValue(location.state.category);
    }
  }, []);

  const handleSubmitForm = async (formValues) => {
    setSaveLoading(true);
    let phonecheck = await checkPhone(formValues.phone_number);
    if (phonecheck) {
      setOpen({ otp: true });
      setSaveLoading(false);
      OTP.sendOTP({
        phone_number: DuIntegration
          ? dialCode + formik.values.phone_number
          : formik.values.phone_number,
        email: formik.values.email,
        send_type_id: sendTypeId,
      }).then((res) =>
        setAlert({
          visible: true,
          type: !res?.data?.success ? "error" : "success",
          text: t(res?.data?.message),
        })
      );
    } else {
      setSaveLoading(false);
    }
  };

  // handle Verify and Proceed
  const handleVerifyOtp = async () => {
    await setLoading(true);
    await OTP.verifyOTP({
      phone_number: DuIntegration
        ? dialCode + formik.values.phone_number
        : formik.values.phone_number,
      otp: otp.otp,
      email: formik.values.email,
      send_type_id: sendTypeId,
    }).then(async (response) => {
      setAlert({
        visible: true,
        type: !response?.data?.success ? "error" : "success",
        text: t(response?.data?.message),
      });
      response?.data?.success && setOpen({ otp: false });

      const data = {
        ...location.state.state,
        country_id: formik.values.nationality?.id,
        product_id: location.state.isPicea
          ? location.state.deviceInfo.id
          : location.state.state.product.id,
        fill_form: {
          full_name: `${formik.values.first_name} ${formik.values.middle_name} ${formik.values.last_name}`,
          address_line: `${formik.values.full_address} ${formik.values.street} ${formik.values.city} ${formik.values.building}`,
          email: formik.values.email,
          id_or_passport_number: formik.values.id_or_passport_number,
          phone_number: DuIntegration
            ? dialCode + formik.values.phone_number
            : formik.values.phone_number,
          postal_code: "0000",
          country_id: formik.values.nationality?.id,
          employee_name: formik?.values?.employeeName,
          employee_code: formik?.values?.EmployeeID,
          otp: otp.otp,
        },
      };
      setPayload(data);
      setThirdPartyValue(response?.data?.data?.third_party.id);
      setThirdParty(response?.data?.data?.third_party.id);

      if (
        response?.data?.success &&
        response?.data?.data?.third_party?.id === 2
      ) {
        setResult({
          disclaimer: {
            seller_name: `${formik.values.first_name} ${formik.values.middle_name} ${formik.values.last_name}`,
            id_or_passport_number: formik.values?.id_or_passport_number,
            nationality: formik.values.nationality.name,
            product_name: location?.state?.isPicea
              ? location?.state?.deviceInfo?.name?.en
              : location?.state?.state?.product?.name?.en,
            buyer_name: cookies.user_info.full_name,
            date: new Date(),
          },
        });

        setTimeout(() => {
          setpopup(!popup);
        }, 50);
      }
      //If it is a retailer w/o 3rd party integration
      if (
        (response?.data?.success || otp.otp) &&
        response?.data?.data?.third_party?.id !== 2 &&
        !location.state.isWiping
      ) {
        await selldevice(data).then((res) => {
          setResult(res?.data?.data || {});
          setAlert({
            visible: true,
            type: !res?.data?.success ? "error" : "success",
            text: !res?.data?.success
              ? res?.data?.message
              : t("Your sales has been Successfully Placed "),
          });
          res?.data?.success &&
            setTimeout(() => {
              setpopup(!popup);
            }, 50);
        });
      } else if (
        (response?.data?.success || otp.otp) &&
        response?.data?.data?.third_party?.id !== 2 &&
        location.state.isWiping &&
        (categoryValue != "mobiles" || categoryValue != "tablets")
      ) {
        await selldevice(data).then((res) => {
          setResult(res?.data?.data || {});
          setAlert({
            visible: true,
            type: !res?.data?.success ? "error" : "success",
            text: !res?.data?.success
              ? res?.data?.message
              : t("Your sales has been Successfully Placed "),
          });
          res?.data?.success &&
            setTimeout(() => {
              setpopup(!popup);
            }, 50);
        });
      } else if (
        (response?.data?.success || otp.otp) &&
        response?.data?.data?.third_party?.id !== 2 &&
        location.state.isWiping &&
        (categoryValue === "mobiles" || categoryValue === "tablets")
      ) {
        setResult({
          disclaimer: {
            seller_name: `${formik.values.first_name} ${formik.values.middle_name} ${formik.values.last_name}`,
            id_or_passport_number: formik.values?.id_or_passport_number,
            nationality: formik.values.nationality.name,
            product_name: location?.state?.isPicea
              ? location?.state?.deviceInfo?.name?.en
              : location?.state?.state?.product?.name?.en,
            buyer_name: cookies.user_info.full_name,
            date: new Date(),
          },
        });
        setTimeout(() => {
          setpopup(!popup);
        }, 50);
      }
    });
    await setLoading(false);
  };

  //handle resending
  const handleResend = () => {
    let payload = {
      phone_number: DuIntegration
        ? dialCode + formik.values.phone_number
        : formik.values.phone_number,
      email: formik.values.email,
      send_type_id: sendTypeId,
    };
    //send instead of resend
    OTP.sendOTP(payload).then((res) => {
      setAlert({
        visible: true,
        type: !res.data.success ? "error" : "success",
        text: t(res.data.message),
      });
      setTime(res.data.data.time);
    });
  };
  let timer = (
    <Countdown
      date={moment(time) + 120000 || ""}
      zeroPadTime={1}
      daysInHours
      precision={2}
      renderer={(props) => ` (${props.minutes}:${props.seconds}s)`}
      onComplete={() => setTime(0)}
    />
  );

  const handlePrint = () => {
    setpopup(!popup);
    setTimeout(() => {
      printRef?.current?.handleClick();
    }, 50);
  };

  const handleprintcode = () => {
    barprintRef.current?.handleClick();
  };
  const handledailogs = () => {
    setpopup(!popup);
    navigate("/sign", {
      state: {
        payload: Payload,
        result: result,
        thirdParty: { id: thirdParty },
      },
    });
  };

  const handleVerifyAndProceed = () => {
    seterrormsg("");
    if (serialNumber.length > 5) {
      setSerialPopUp(false);
    } else {
      seterrormsg("Field Is Not Valid");
    }
  };

  return (
    <div className="main-sub-body">
      <Disclaimer
        ref={componentRef}
        data={result}
        noTransaction={thirdParty === 2} //DU is 2
        arabic={selectedLanguage != "ar" ? false : true}
        serialNumber={serialNumber}
        isPicea={location.state.isPicea}
        isWiping={
          (location.state.isWiping && categoryValue == "mobiles") ||
          (location.state.isWiping && categoryValue == "tablets")
            ? true
            : false
        }
      />

      <Barcode ref={barcodeRef} data={result} />
      <ReactToPrint
        trigger={() => <Fragment />}
        content={() => componentRef.current}
        ref={printRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            const isMobileOrTablet = ["mobiles", "tablets"].includes(
              categoryValue
            );
            const isWiping = location.state.isWiping;

            if (
              (thirdParty !== 2 && isWiping && isMobileOrTablet) ||
              (thirdParty === 2 && isWiping && isMobileOrTablet)
            ) {
              navigate("/wiping", {
                state: {
                  state: Payload,
                  category: params.category,
                  piceaVerifydata: location.state.piceaVerifyData,
                  isPicea: location.state.isPicea,
                  preData: location.state.state,
                },
              });
            } else if (thirdParty === 2 && !isWiping) {
              navigate("/payment-method", { state: { payload: Payload } });
            } else {
              handleprintcode();
            }
          }, 500);
        }}
      />
      <ReactToPrint
        trigger={() => <Fragment />}
        content={() => barcodeRef.current}
        ref={barprintRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            thirdParty !== 2
              ? navigate("/sell")
              : navigate("/payment-method", { state: { payload: Payload } });
          }, 500);
        }}
      />

      <div>
        <h1 className={isArabic ? " arabic-component" : " "}>
          {t("Fill Form")}
        </h1>

        <div
          className={
            isArabic
              ? " arabic-component pad-10  formContainer"
              : "formContainer "
          }
        >
          <form onSubmit={formik.handleSubmit}>
            <label
              className="my-1"
              style={{
                color: "#4ba4f0",
                fontWeight: "600",
                fontSize: "20px",
              }}
            >
              <span> {t("Customer Details")}</span>
            </label>
            <div className="row">
              <div className="form-inputs-triple-children">
                <FormInput
                  required
                  type="text"
                  name="first_name"
                  value={formik?.values?.first_name}
                  placeholder={t("Enter First Name")}
                  onChange={formik.handleChange}
                  label={t("First Name")}
                  helperText={
                    formik.touched?.first_name && formik.errors?.first_name
                  }
                />

                <FormInput
                  required
                  type="text"
                  name="middle_name"
                  placeholder={t("Enter Middle name")}
                  helperText={
                    formik.touched?.middle_name && formik.errors?.middle_name
                  }
                  value={formik?.values?.middle_name}
                  onChange={formik.handleChange}
                  label={t("Middle Name")}
                />

                <FormInput
                  required
                  type="text"
                  name="last_name"
                  placeholder={t("Enter Last name")}
                  helperText={
                    formik.touched?.last_name && formik.errors?.last_name
                  }
                  value={formik?.values?.last_name}
                  onChange={formik.handleChange}
                  label={t("Last Name")}
                />
                <FormDropdownList
                  required
                  loading={countriesLoading}
                  data={countriesArray || []}
                  name="nationality"
                  className="countryForm direction-left"
                  label={t("Nationality")}
                  value={formik?.values?.nationality}
                  onChange={(value) =>
                    formik.setFieldValue("nationality", value)
                  }
                  helperText={
                    formik.touched?.nationality && formik.errors?.nationality
                  }
                />

                <FormInput
                  required
                  type="text"
                  name="email"
                  placeholder={t("Enter Email")}
                  helperText={formik.touched?.email && formik.errors?.email}
                  value={formik?.values?.email}
                  onChange={formik.handleChange}
                  label={t("Email")}
                />
                <PhoneInput
                  required
                  value={formik.values?.phone_number}
                  onChange={handleNumber}
                  className="inputForm direction-left"
                  ip={!DuIntegration}
                  onlyCountries={DuIntegration ? ["lb"] : []}
                  country="lb"
                  enableAreaCodes={!DuIntegration}
                  disableCountryCode={DuIntegration}
                  disableDropdown={DuIntegration}
                  helperText={
                    phone_number_error !== ""
                      ? phone_number_error
                      : formik.touched?.phone_number &&
                        formik.errors?.phone_number
                  }
                />
              </div>

              <div className="form-inputs-triple-children">
                <FormInput
                  required
                  type="text"
                  name="full_address"
                  placeholder={t("Enter Country")}
                  helperText={
                    formik.touched?.full_address && formik.errors?.full_address
                  }
                  value={formik?.values?.full_address}
                  onChange={formik.handleChange}
                  label={t("Country")}
                />

                <FormInput
                  required
                  type="text"
                  name="city"
                  placeholder={t("Enter City")}
                  helperText={formik.touched?.city && formik.errors?.city}
                  value={formik?.values?.city}
                  onChange={formik.handleChange}
                  label={t("City")}
                />
                <div>
                  <FormControl fullWidth>
                    <p style={{ margin: "5px 0px" }}></p>
                    <Select
                      labelId="paper-type-label"
                      id="paper-type"
                      name="paper_type"
                      placeholder="Select"
                      value={formik.values.paper_type}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.paper_type &&
                        Boolean(formik.errors.paper_type)
                      }
                    >
                      <MenuItem value={1}>{t("ID")}</MenuItem>
                      <MenuItem value={2}>{t("Passport Number")}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <div className="form-inputs-double-children">
                <FormInput
                  required
                  name={"id_or_passport_number"}
                  placeholder={` ${
                    formik.values.paper_type === 1
                      ? t("ID")
                      : t("passport number")
                  }`}
                  label={
                    formik.values.paper_type === 1
                      ? t("ID")
                      : t("Enter Passport number")
                  }
                  helperText={
                    formik.touched?.id_or_passport_number &&
                    formik.errors?.id_or_passport_number
                  }
                  value={formik?.values?.id_or_passport_number}
                  onChange={formik.handleChange}
                />

                <div className="last-row-demand">
                  <div>
                    <RadioGroup
                      row
                      className="RadioGroup"
                      aria-labelledby="email-or-otp"
                      name="email-or-otp"
                      value={sendTypeId}
                      onChange={handleRadio}
                    >
                      <FormControlLabel
                        label={
                          <div>
                            <Image
                              src={mail}
                              className={
                                isArabic
                                  ? "iconImage mlarab-10"
                                  : "iconImage mR-10"
                              }
                            />
                            {t("Verify by Email")}
                          </div>
                        }
                        control={<Radio />}
                        value={1}
                      />

                      <FormControlLabel
                        label={
                          <div>
                            <Image
                              src={wp}
                              className={
                                isArabic
                                  ? "iconImage mlarab-10"
                                  : "iconImage mR-10"
                              }
                            />
                            {t("Verify by whatsapp")}
                          </div>
                        }
                        control={<Radio />}
                        value={3}
                      />
                      {cookies.nlog.country_code &&
                        cookies.nlog.country_code == "AE" && (
                          <FormControlLabel
                            label={
                              <div>
                                <Image
                                  src={sms}
                                  className={
                                    isArabic
                                      ? "iconImage mlarab-10"
                                      : "iconImage "
                                  }
                                />
                                {t("Verify by OTP")}
                              </div>
                            }
                            control={<Radio />}
                            value={2}
                          />
                        )}
                    </RadioGroup>
                  </div>
                </div>
              </div>
            </div>
            {employeInfoEnabled ? (
              <div>
                <label
                  className="my-1"
                  style={{
                    color: "#4ba4f0",
                    fontWeight: "600",
                    fontSize: "20px",
                  }}
                >
                  <span>{t("Employee Details")} </span>
                </label>

                <div className="row ">
                  <div className="col-lg-3 col-md-6 col-sm-12 mt">
                    <FormInput
                      required
                      type="text"
                      name="employeeName"
                      placeholder={t("Employee Name")}
                      helperText={
                        formik.touched?.employeeName &&
                        formik.errors?.employeeName
                      }
                      value={formik?.values?.employeeName}
                      onChange={formik.handleChange}
                      label={t("Employee Name")}
                    />
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12 mt">
                    <FormInput
                      required
                      type="text"
                      name="EmployeeID"
                      placeholder={t("Employee Code")}
                      helperText={
                        formik.touched?.EmployeeID && formik.errors?.EmployeeID
                      }
                      value={formik?.values?.EmployeeID}
                      onChange={formik.handleChange}
                      label={t("Employee Code")}
                    />
                  </div>
                </div>
              </div>
            ) : undefined}

            <Button
              className="placeorder"
              name={t("Continue")}
              selected
              type="submit"
              disabled={saveLoading}
              loading={loading || saveLoading}
              width="20%"
            />
            <Button
              className="placeorder"
              onClick={() => navigate("/sell")}
              name={t("Cancel")}
              width="20%"
            />
          </form>
          <Dialog
            open={isOpen.otp}
            keepMounted
            onClose={() => setOpen({})}
            className="dialogDisclaimer"
            maxWidth="md"
          >
            <DialogTitle className="dialogTitle">
              <OTPSVG />
              {t("OTP Verification Code")}
            </DialogTitle>
            <DialogContent className="modalotp">
              <p>
                {t("Please enter the OTP sent to ")}
                <b className="px-2">
                  {`${
                    DuIntegration
                      ? dialCode + formik.values.phone_number
                      : sendTypeId === 1
                      ? formik.values.email
                      : formik.values?.phone_number
                  }`}
                </b>
              </p>
              <OtpInput
                shouldAutoFocus
                value={otp.otp}
                onChange={(otp) => setOtp({ otp: otp })}
                numInputs={4}
                containerStyle="otpContainer"
                inputStyle="otpInput"
                focusStyle="otpFocus"
                isInputNum
              />
            </DialogContent>
            <DialogActions className="dialogActions">
              <Button
                name={t("Verify and Proceed")}
                width="100%"
                selected
                onClick={handleVerifyOtp}
              />
              <MUIButton
                disabled={Boolean(time)}
                onClick={handleResend}
                className="sendOTP"
              >
                {sendTypeId === 1
                  ? t("Resend Email")
                  : sendTypeId === 2
                  ? t("Resend OTP")
                  : t("Resend OTP")}
                <b> {time ? timer : null}</b>
              </MUIButton>
            </DialogActions>
          </Dialog>
          <Dialog
            open={popup}
            keepMounted
            onClose={() => setpopup({})}
            className="selectdialogDisclaimer"
            maxWidth="md"
          >
            <DialogTitle className="dialogTitle my-2">
              <EmailSVG />
              {t("Would you like to print or send by email?")}
            </DialogTitle>
            <DialogActions className="dialogAction my-1">
              <Button
                name={t("Send by email")}
                width="100%"
                onClick={handledailogs}
              />
              <Button
                name={t("Print")}
                width="100%"
                selected
                onClick={handlePrint}
              />
            </DialogActions>
          </Dialog>

          <Dialog
            open={serialpopUp}
            maxWidth="md"
            className={
              isArabic
                ? " selectdialogDisclaimer arabic-component"
                : " selectdialogDisclaimer"
            }
          >
            <DialogTitle className="dialogTitle my-2">
              {t("Please Add Device Serial Number / IMEI")}
            </DialogTitle>
            <DialogContent className="dialogAction my-1">
              <FormInput
                required
                type="text"
                name="serialNumber"
                placeholder={t("Serial Number")}
                value={serialNumber}
                onChange={(e) => {
                  setserialNumber(e.target.value);
                  seterrormsg("");
                }}
                label={t("Serial Number")}
              />
              <p className="errorMsg">{errormsg}</p>
              <Button
                name={t("Continue")}
                width="100%"
                selected
                disabled={!serialNumber}
                onClick={handleVerifyAndProceed}
              />
            </DialogContent>
          </Dialog>
          {/* // popup e sign */}
          <div style={{ display: "none" }}>
            <SigninPage arabic={selectedLanguage != "ar" ? false : true} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default PiceaCheckout;
