import React from "react";
import { Box, Container } from "@mui/material";
import "./nodatafound.scss";
import emptyfolder from "../../assests/Images/empty-folder.png";
import { useGlobalLangValues } from "../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

const NoDataFound = () => {
  const { t } = useTranslation();
  const { isArabic } = useGlobalLangValues();

  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box
        className={isArabic ? "arabic-component" : ""}
        sx={{ textAlign: "center", mt: 8 }}
      >
        <img
          src={emptyfolder}
          alt="No Data Found"
          style={{
            width: "200px",
          }}
        />
        <h2 style={{ color: "white" }}>{t("No Data Found")} </h2>
        <h5 style={{ color: "white", margin: "10px 0px" }}>
          {t("Please choose another option")}
        </h5>
        <button className="GoBack" onClick={() => navigate("/")}>
          {t("Go Back")}{" "}
        </button>
      </Box>
    </Container>
  );
};

export default NoDataFound;
