import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import englishtranslation from "./langs/en/translation.json";
import arabictranslation from "./langs/ar/translation.json";

const resources = {
  en: { translation: englishtranslation },
  ar: { translation: arabictranslation },
};

//en
const selectedLanguage = localStorage.getItem("selectedLanguage");

i18n.use(initReactI18next).init({
  debug: false,
  lng: selectedLanguage || "en",
  fallbackLng: "en",
  resources,
});

export default i18n;
