import React, { useEffect, useState } from "react";
import "./PriceRange.scss";
import { Tooltip, tooltipClasses, styled } from "@mui/material";
import { IconButton, Skeleton, SvgIcon } from "@mui/material";
import {
  MedalWOBackgroundSVG,
  RecyclableSVG,
} from "../../../../assests/icons/SVG";
import { InfoSVG } from "../../../../assests/icons/SVG";
import NoImageFound from "../../../../assests/Images/NoImageFound.png";
import { getConditionPrice } from "../../../../core/apis/sellmydevice";
import { CConverter } from "../../../../core/functions/CurrencyConversion";
import { useGlobalLangValues } from "../../../../context/LanguageContext";
import { useTranslation } from "react-i18next";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
export const color = [
  { label: "Mint", color: "#11AEE0A0", background: "#E9F5FF" },
  { label: "Excellent", color: "#11AEE0", background: "#E9F5FF" },
  { label: "Very Good", color: "#74D301", background: "#E2FFBF" },
  { label: "Good", color: "#FFC700", background: "#FFF2C0" },
  { label: "Fair", color: "#EF8800", background: "#FFEBD0" },
  { label: "Broken but Working", color: "red", background: "#FFDFD5" },
  { label: "Does not turn on", color: "purple", background: "#ECDAFF" },
];

const PriceRange = (props) => {
  const [condition, setCondition] = useState([]);
  const [loadingCondition, setLoading] = useState(true);
  const { loading, attributes, attribute_values_id, product, country } = props;
  const image = product?.product_image?.[0].image_path || "";

  const { t } = useTranslation();

  const { selectedLanguage, isArabic } = useGlobalLangValues();

  useEffect(() => {
    setLoading(true);
    let payload = {
      country_id: country?.id || "",
      attribute_value_ids: attribute_values_id,
    };
    getConditionPrice(product.id, payload).then((res) => {
      setCondition(res?.data?.data || []);
      setLoading(false);
    });
  }, [
    attribute_values_id.length === attributes?.length &&
      attribute_values_id.toString(),
    loading,
  ]);
  return (
    <div className="Device_box">
      {loading ? (
        <Skeleton
          className="img"
          variant="rectangular"
          width="90%"
          height="40%"
        />
      ) : (
        <img
          src={
            image && image !== "image1"
              ? process.env.REACT_APP_IMAGE_URL + image
              : NoImageFound
          }
          alt="Device"
          className="img"
        />
      )}
      <div className="bottom">
        <div className="Row">
          <div className="Condition">
            {t("Condition")}
            <LightTooltip
              className="conditionsTooltip"
              title={
                <div className={isArabic ? "arabic-component" : ""}>
                  <h6 style={{ color: "#4BA4F0" }}>{t("Mint")}</h6>
                  <p style={{ color: "#646464" }}>{t("Mint Description")}</p>
                  <h6 style={{ color: "#4BA4F0" }}>{t("Excellent")}</h6>
                  <p style={{ color: "#646464" }}>
                    {t("Excellent Description")}
                  </p>
                  <h6 style={{ color: "#4BA4F0" }}>{t("Very Good")}</h6>
                  <p style={{ color: "#646464" }}>
                    {t("veryGood Description")}
                  </p>
                  <h6 style={{ color: "#4BA4F0" }}>{t("Good")}</h6>
                  <p style={{ color: "#646464" }}>{t("Good Description")}</p>
                  <h6 style={{ color: "#4BA4F0" }}>{t("Fair")}</h6>
                  <p style={{ color: "#646464" }}>{t("fair Description")}</p>
                  <h6 style={{ color: "#4BA4F0" }}>
                    {t("Broken but working")}
                  </h6>
                  <p style={{ color: "#646464" }}>
                    {t("Broken but working Description")}
                  </p>
                  <h6 style={{ color: "#4BA4F0" }}>{t("Does not turn on")}</h6>
                  <p style={{ color: "#646464" }}>{t("No Power on")}</p>
                </div>
              }
              arrow
              placement="left"
            >
              <IconButton aria-label="info" color="primary" sx={{ padding: 0 }}>
                <SvgIcon component={InfoSVG} viewBox="0 0 16 17" />
              </IconButton>
            </LightTooltip>
          </div>
          <div className="Estimated-title">{t("Estimated Price")}</div>
        </div>
        {!loading
          ? condition?.map((item, index) => (
              <div className="Row-1" key={index}>
                <div className="Condition">
                  {condition?.length !== index + 1 ? (
                    <MedalWOBackgroundSVG
                      className="starSVG"
                      color={
                        color.find((e) => e?.label === item?.condition.name)
                          ?.color
                      }
                    />
                  ) : (
                    <RecyclableSVG className="RecyclableSVG" />
                  )}
                  {item?.condition?.id === 7 ? (
                    <p>{t("Does not turn on")}</p>
                  ) : (
                    <p>{t(item?.condition?.name)}</p>
                  )}
                </div>
                {!loadingCondition ? (
                  <div className="Estimated">
                    <span className="upto"> {isArabic ? "" : t("Up to")}</span>{" "}
                    {CConverter(item?.price) || "N/A"}{" "}
                    <span className="upto"> {isArabic ? t("Up to") : ""}</span>
                  </div>
                ) : (
                  <Skeleton width="50px" height={20} />
                )}
              </div>
            ))
          : [...Array(6)].map((_, index) => (
              <Skeleton
                key={index}
                variant="text"
                className="Row-1"
                width="90%"
              />
            ))}
      </div>
    </div>
  );
};

export default PriceRange;
