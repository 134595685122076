import { api } from "./main";

export const getAllBrands = (payload) => {
  return api
    .get("retailer/get-all-country-brands", { params: { ...payload } })
    .then((res) => res.data.data)
    .catch((err) => console.error(err));
};

export const sellAcceptOffer = (item_id, payload) => {
  return api
    .patch(`admin/change-item-action/${item_id}`, null, { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getQuestionsByID = (categoryId) => {
  return api
    .get(`get-questions-by-category/${categoryId}`)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};
export const getProductEstimationQuestion = (productSlug) => {
  return api
    .get(`retailer/product-estimation-question/${productSlug}`)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};
