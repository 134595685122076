import React, {
  useState,
  useEffect,
  Fragment,
  useRef,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import * as svg from "../../assests/icons/SVG";
import * as MUI from "@mui/material";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  TextField,
  InputLabel,
  Autocomplete,
  CircularProgress,
  InputAdornment,
} from "@mui/material";
import "./FormComponent.scss";
import { useTranslation } from "react-i18next";
import { useGlobalLangValues } from "../../context/LanguageContext";
import verifySvg from "../../assests/Images/verify-svg.png";

export const FormInput = (props) => {
  const {
    formComponent,
    onClick,
    helperText,
    disabled,
    label,
    placeholder,
    className,
    verfied,
  } = props;
  const {
    value,
    endAdornment,
    onChange,
    required,
    type,
    name,
    arabic,
    language,
    fontweight,
    borderVal,
  } = props;

  return (
    <div
      className={formComponent ? "form-input-wrapper" : ""}
      style={{ width: "100%" }}
    >
      {label && (
        <InputLabel
          sx={{
            margin: "0 0 8px",
            color: "#000",
            fontWeight: fontweight || "normal",
          }}
        >
          {language != "ar" ? (
            <p style={{ margin: "0" }}>
              {label}
              <span className="required-start">{required ? " * " : ""}</span>
              {verfied && <img src={verifySvg} width="25" />}
            </p>
          ) : (
            <>
              <p style={{ margin: "0" }}>{/* {label} */}</p>
              <p style={{ margin: "0" }}>
                {" "}
                <span className="required-start">
                  {required ? " * " : ""}
                </span>{" "}
                {arabic}{" "}
              </p>
            </>
          )}
        </InputLabel>
      )}

      <TextField
        {...props}
        required={false}
        fullWidth
        size="small"
        label=""
        type={type}
        className={className}
        value={value}
        placeholder={placeholder}
        helperText={helperText}
        disabled={disabled}
        onChange={onChange}
        onClick={onClick}
        name={name}
        InputProps={{
          endAdornment: endAdornment ? (
            <MUI.InputAdornment position={"end"}>
              {endAdornment}
            </MUI.InputAdornment>
          ) : null,
        }}
      />
    </div>
  );
};
FormInput.defaultProps = {
  formComponent: true,
  verfied: false,
  borderVal: false,
};
FormInput.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  formComponent: PropTypes.bool,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  verfied: PropTypes.bool,
};

//DROPDOWN LIST
export const FormDropdownList = (props) => {
  const {
    label,
    name,
    placeholder,
    type,
    variant,
    disabled,
    required,
    value,
    dependencyError,
    formComponent,
    arabic,
    language,
    fontweight,
    borderVal,
  } = props;
  const {
    data,
    noOptionsMessage,
    loading,
    onChange,
    helperText,
    clearErrors,
    accessValue,
  } = props;

  const [val, setVal] = useState(null);

  useEffect(() => {
    if (value) {
      setVal(value);
      if (clearErrors) {
        dependencyError ? clearErrors(dependencyError) : clearErrors(name);
      }
    }
  }, [value]);

  return (
    <div className={formComponent ? `form-input-wrapper` : "non-form-wrapper"}>
      {label && (
        <InputLabel
          sx={{
            margin: "0 0 8px",
            fontWeight: fontweight || "normal",
            color: "#000",
          }}
        >
          {language === "ar" ? (
            <>
              <p style={{ margin: "0" }}></p>
              <p style={{ margin: "0" }}>
                <span className="required-start">{required ? " * " : ""}</span>
                {arabic}
              </p>
            </>
          ) : (
            <>
              <p style={{ margin: "0" }}>
                {label}
                <span className="required-start">{required ? " * " : ""}</span>
              </p>
            </>
          )}
        </InputLabel>
      )}

      <Autocomplete
        size="small"
        disabled={disabled}
        fullWidth
        disableClearable={required}
        ListboxProps={{ style: { maxHeight: 200, overflow: "auto" } }}
        getOptionLabel={(option) =>
          option?.[accessValue]
            ? typeof option?.[accessValue] === "object"
              ? option?.[accessValue]["en"]
              : option?.[accessValue]
            : "Error displaying options"
        }
        options={data}
        value={value || null}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        loadingText={"Loading"}
        noOptionsText={noOptionsMessage ? noOptionsMessage : "No Options"}
        loading={loading}
        onChange={(event, selected) => {
          onChange(selected);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={variant}
            placeholder={placeholder}
            helperText={helperText}
            className="direction-left"
            InputProps={{
              ...params.InputProps,
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
              endAdornment: (
                <Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </Fragment>
              ),
            }}
          />
        )}
      />
    </div>
  );
};

FormDropdownList.defaultProps = {
  accessValue: "name",
  variant: "outlined",
  formComponent: true,
  borderVal: false,
};

FormDropdownList.propTypes = {
  accessValue: PropTypes.string,
  variant: PropTypes.string,
  formComponent: PropTypes.bool,
};
export const FormCheckboxDropdown = (props) => {
  const { loading, disabled, value, onChange, multiple, name, id, starticon } =
    props;
  const { options, placeholder, label, renderOption, limitTags } = props;
  const { error, helperText, getOptionDisabled, data, sx } = props;
  const filter = MUI.createFilterOptions();

  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const { t } = useTranslation();

  const allSelected = options?.length === value?.length;

  return (
    <MUI.Box sx={{ ...sx }}>
      <MUI.Autocomplete
        classes={{
          endAdornment: isArabic ? "custom-end-adornment-class" : "",
        }}
        size="small"
        disableCloseOnSelect
        sx={{ width: "100%" }}
        limitTags={limitTags || 1}
        id={id}
        name={name}
        autoHighlight
        loading={loading}
        disabled={disabled}
        value={value}
        onChange={onChange}
        options={options}
        multiple={multiple}
        getOptionDisabled={getOptionDisabled}
        renderTags={(value, getTagProps) =>
          value.slice(0, limitTags).map((option, index) => (
            <div {...getTagProps({ index })} key={index}>
              {option?.name?.[selectedLanguage]
                ? option?.name?.[selectedLanguage]
                : option?.name?.en || option?.name}
            </div>
          ))
        }
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          return [{ name: t("Select All"), value: "select-all" }, ...filtered];
        }}
        getOptionLabel={(option) =>
          option[renderOption]?.en || option?.[renderOption] || option?.label
        }
        renderOption={(props, option, { selected }) => {
          const selectAllProps =
            option.value === "select-all" // To control the state of 'select-all' checkbox
              ? { checked: allSelected }
              : {};
              

          return (
            <li {...props}>
              <MUI.Checkbox
                checked={selected}
                {...selectAllProps}
                indeterminate={
                  option.value === "select-all" && value?.length
                    ? options?.length > value?.length
                    : null
                }
              />{" "}
              {option?.name?.[selectedLanguage]
                ? option?.name?.[selectedLanguage]
                : option?.name?.en || option?.name}
            </li>
          );
        }}
        renderInput={(params) => (
          <MUI.TextField
            error={error}
            helperText={helperText}
            name={name}
            variant="outlined"
            {...params}
            placeholder={value?.length > 0 ? " " : placeholder}
            InputProps={{
              ...params.InputProps,
              style: {
                Height: 40,
                overflow: "hidden",
                minWidth: 250,
              },
            }}
          />
        )}
      />
    </MUI.Box>
  );
};
FormCheckboxDropdown.defaultProps = {
  label: "Please Enter a Label",
  placeholder: "Choose an Option",
  renderOption: "name",
};
FormCheckboxDropdown.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  starticon: PropTypes.any,
};
export const FormSearchBar = (props) => {
  const { placeholder, variant, value, onChange, className, fullWidth } = props;

  const handleInputChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <MUI.TextField
      type="text"
      size="small"
      fullWidth={fullWidth}
      className={className}
      placeholder={placeholder}
      variant={variant}
      onChange={(e) => handleInputChange(e)}
      value={value}
      InputProps={{
        endAdornment: (
          <MUI.InputAdornment className="input-adorment" position="end">
            <svg.SearchSVG />
          </MUI.InputAdornment>
        ),
      }}
    />
  );
};
FormSearchBar.defaultProps = {
  fullWidth: false,
};

FormSearchBar.propTypes = {
  fullWidth: PropTypes.bool,
};

export const FormDateRange = (props) => {
  const datepickerRef = useRef(null);
  const { t } = useTranslation();

  const { label, value, onChange, fullWidth, withPortal } = props;
  const [show, setShow] = useState(false);
  const startDate = value[0] ? new Date(value[0]) : null;

  const endDate = value[1] ? new Date(value[1]) : null;

  const handleOnChange = (dates) => {
    const [start, end] = dates;

    onChange([
      start ? moment(start).format("YYYY-MM-DD") : null,
      end ? moment(end).format("YYYY-MM-DD") : null,
    ]);
  };

  const ExampleCustomInput = forwardRef(({ value, onChange }, ref) => {
    const handleCalendarClick = () => {
      if (datepickerRef) datepickerRef?.current?.setOpen(!show);
      setShow(!show);
    };

    return (
      <TextField
        {...props}
        required={false}
        size="small"
        fullWidth={fullWidth}
        label=""
        type="text"
        value={value}
        placeholder={t("Start Date | End Date")}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <MUI.InputAdornment
              position={"end"}
              onClick={handleCalendarClick}
              className="cursor-pointer"
            >
              <svg.CalendarSVG />
            </MUI.InputAdornment>
          ),
        }}
      />
    );
  });

  return (
    <>
      {label && <MUI.InputLabel>{label}</MUI.InputLabel>}
      <div className={`react-daterangeWrapper ${fullWidth ? "fullWidth" : ""}`}>
        <DatePicker
          selected={startDate}
          onChange={handleOnChange}
          startDate={startDate}
          customInput={<ExampleCustomInput />}
          endDate={endDate}
          selectsRange
          showMonthDropdown
          showYearDropdown
          showPopperArrow={false}
          ref={datepickerRef}
          withPortal={withPortal}
        />
      </div>
    </>
  );
};

FormDateRange.defaultProps = {
  fullWidth: false,
};

FormDateRange.propTypes = {
  fullWidth: PropTypes.bool,
};

export const FormCheckBox = (props) => {
  const { onChange, checked, label, booleanValue, value, tooltipMessage } =
    props;

  return (
    <MUI.FormGroup>
      <MUI.FormControlLabel
        control={
          <MUI.Checkbox
            value={booleanValue ? checked : value}
            checked={checked}
            onChange={(e, value) => onChange(value)}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label={
          tooltipMessage ? (
            <LightTooltip title={tooltipMessage} placement={"top-end"}>
              <span className="cursor-pointer">
                {label} <svg.MoreInfoSVG />
              </span>
            </LightTooltip>
          ) : (
            label
          )
        }
      />
    </MUI.FormGroup>
  );
};
FormCheckBox.defaultProps = {
  booleanValue: true,
};

FormCheckBox.propTypes = {
  booleanValue: PropTypes.bool,
};
export const LightTooltip = MUI.styled(({ className, ...props }) => (
  <MUI.Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${MUI.tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));
