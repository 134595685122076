import React, { useContext, useState } from "react";
import "./FinalEstimation.scss";
import Button from "../../../../components/button/Button";
import CountUp from "react-countup";
import {
  MedalWOBackgroundSVG,
  RecyclableSVG,
} from "../../../../assests/icons/SVG";
import { color } from "../priceRange/PriceRange";
import { CurrencyContext } from "../../../../context/CurrencyContext";
import { CConverter } from "../../../../core/functions/CurrencyConversion";
import { useGlobalValues } from "../../../../context/GlobalContext";
import { useGlobalLangValues } from "../../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import loaderSpinner from "../../../../assests/Images/loaderspinner.png";

const FinalEstimation = (props) => {
  const { currency } = useContext(CurrencyContext);
  const { t } = useTranslation();

  const { price, state, params, isBreakable, isPicea, finish, deviceInfo } =
    props;
  const [finishstate, setFinish] = useState(true);
  const handleFinish = () => setFinish(true);

  const { selectedLanguage, isArabic } = useGlobalLangValues();

  const {
    TwoUseverfiyworkFlowState,
    piceaWipeOrganizationState,
    TwoUsewipingworkFlowState,
  } = useGlobalValues();

  console.log(TwoUseverfiyworkFlowState, "TwoUseverfiyworkFlowState");

  console.log(piceaWipeOrganizationState, "piceaWipeOrganizationState");

  return (
    <div className="Device_Estimated_Value_Box">
      <div className="resultSection">
        <div className="loaderFrame">
          {finish ? (
            <div
              className={
                isArabic
                  ? "result-content result-content-arabic"
                  : "result-content"
              }
            >
              <div className="result-view">
                <div className="result-icon">
                  {" "}
                  {price?.condition?.id !== 7 ? (
                    <MedalWOBackgroundSVG
                      className={`MedalWOBackgroundSVG ${
                        finish ? "" : "filter"
                      }`}
                      width="100"
                      height="100"
                      color={
                        color?.find(
                          (item) => item?.label === price?.condition?.name
                        )?.color
                      }
                    />
                  ) : (
                    <RecyclableSVG width="90px" height="unset" />
                  )}
                </div>
                <div className="info">
                  <span>
                    <h3 className="result-condition">
                      {price?.condition?.id === 7
                        ? t("Does not turn on")
                        : t(price?.condition?.name)}
                    </h3>

                    <p className="fade-in result-condition-price">
                      {t("Your device estimated price is")}
                    </p>

                    <h2
                      className="currency-price"
                      style={{ color: finish ? "#4ba4f0" : "black" }}
                    >
                      {currency?.code + " "}

                      {price ? (
                        <CountUp
                          end={CConverter(price?.price, true)}
                          duration={1}
                          decimals={2}
                          onEnd={handleFinish}
                        />
                      ) : (
                        "0.00"
                      )}
                    </h2>
                    {finish
                      ? (isBreakable || +price?.condition?.id === 7) && (
                          <p>{t("Your device will be used for recycling")}</p>
                        )
                      : null}
                  </span>
                </div>
              </div>
              <hr className="result-divider"></hr>
              <div className="result-buttons">
                <Button width="45%" name={t("Cancel")} href="/" />
                {isPicea ? (
                  <Button
                    href={`/sell/checkout`}
                    state={{
                      deviceInfo: deviceInfo,
                      isPicea: isPicea,
                      state: state,
                      category: params.category,
                      isWiping: piceaWipeOrganizationState,
                    }}
                    width="45%"
                    name={t("Sell Device")}
                    selected
                  />
                ) : (
                  <Button
                    href={`/sell/${Object.values(params)
                      .toString()
                      .split(",")
                      .join("/")}/${
                      (TwoUseverfiyworkFlowState &&
                        params.category == "mobiles") ||
                      (TwoUseverfiyworkFlowState &&
                        params.category == "tablets")
                        ? "verify"
                        : "checkout"
                    }`}
                    state={{
                      state: state,
                      isPicea: isPicea,
                      isWiping: TwoUseverfiyworkFlowState
                        ? TwoUsewipingworkFlowState
                        : false,
                    }}
                    width="45%"
                    name={t("Sell Device")}
                    selected
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="loadingContent">
              <p className="loading-text">{t("Loading... ")}</p>
              <img src={loaderSpinner} alt="spinner" className="spinner spin" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default FinalEstimation;
