import { useQuery } from "@tanstack/react-query";
import { api } from "./main";

export const getUserAdressesDetails = () => {
  return api
    .get(`retailer/get-all-addresses`)
    .then((res) => res.data.data)
    .catch((err) => console.error(err));
};
export const createUserAddress = (data) => {
  return api
    .post("retailer/create-address", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const deleteUserAddress = (id) => {
  return api
    .delete(`/retailer/delete-address/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const changePassword = (data) => {
  return api
    .put("auth/change-password", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const placeOrder = (data) => {
  return api
    .post("user/create-order-placement", data)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getAllOrders = (bodyRequest, page) => {
  return api
    .post(`user/get-all-orders?page=${page}`, bodyRequest)
    .then((res) => res.data.data)
    .catch((err) => console.error(err));
};
export const getSingleOrder = (order_id) => {
  return api
    .get(`user/get-single-order/${order_id}`)
    .then((res) => res.data.data[0])
    .catch((err) => console.error(err));
};

export const getSalesList = (filter, page) => {
  return api
    .post(`user/get-sales-list?page=` + page, filter)
    .then((res) => res.data)
    .catch((err) => console.error(err));
};

export const getSaleByID = (id) => {
  return api
    .get(`user/get-sale-by-id/${id}`)
    .then((res) => res.data.data)
    .catch((err) => console.error(err));
};
export const createUserPaymentGateway = (bodyRequest) => {
  return api
    .post("user/create-user-payment-gateway", bodyRequest)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getUserPaymentGatewayValues = (payload) => {
  return api
    .get(`user/get-payment-gateways-values`, {
      params: { ...payload, payment_gateway_id: 1 },
    })
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const deleteUserPaymentGateway = (id) => {
  return api
    .delete(`user/delete-user-payment-gateway/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const checkPhoneValidity = (payload) => {
  return api
    .get(`validate-phone-number`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getSelf = () => {
  return api
    .get(`/retailer/get-self-user`)
    .then((res) => res?.data?.data)
    .catch((err) => console.error(err));
};

export const useGetSelfUser = () =>
  useQuery({
    queryKey: [`get-self-user`],
    queryFn: () => getSelf().then((res) => res),
  });

export const isLoggedIn = () => {
  return api
    .get(`/is-logged-in`)
    .then((res) => res)
    .catch((err) => console.error(err));
};

export const getBranchByID = (id) => {
  return api
    .get(`/admin/get-branch-by-id/${id}`)
    .then((res) => res?.data?.data)
    .catch((err) => console.error(err));
};
