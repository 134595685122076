import React, { forwardRef, useEffect, useState } from "react";
import {
  Dialog,
  Slide,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import "./Modal.scss";
import Button from "../button/Button";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useGlobalLangValues } from "../../context/LanguageContext";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogComponent = (props) => {
  const { open, handleOpenClose, handleModal, loading } = props;
  const { buttonLabel, children, title, className } = props;
  const { i18n, t } = useTranslation();
  const { selectedLanguage, isArabic } = useGlobalLangValues();

  const [arabicComponent, setArabicComponent] = useState(false); // State to track Arabic language

  // Effect to set Arabic component class
  useEffect(() => {
    setArabicComponent(i18n.language === "ar");
  }, [i18n.language]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleOpenClose}
      className={isArabic ? "arabic-component dialogComp" : "dialogComp"}
      maxWidth="md"
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent className="modalBody">
        <div className="children">{children}</div>
        <DialogActions>
          {handleOpenClose && (
            <Button onClick={handleOpenClose} name={t("Cancel")} width="50%" />
          )}
          <Button
            onClick={handleModal}
            name={buttonLabel}
            selected
            width="50%"
            loading={loading}
            type="submit"
          />
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
DialogComponent.propTypes = {
  title: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  handleOpenClose: PropTypes.func,
  handleModal: PropTypes.func,
  className: PropTypes.string,
};
export default DialogComponent;
