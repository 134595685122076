import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "../../pages/login/Login";
//FORGOT PASSWORD
import ResetComponent from "../../pages/login/ResetPassword/reset.jsx";
import ResetFormComponent from "../../pages/login/ResetPassword/resetform.jsx";
import OTPComponent from "../../pages/login/ResetPassword/otp.jsx";
import ResetByComponent from "../../pages/login/ResetPassword/select.jsx";
import OpenTransaction from "../../pages/transactions/open-transaction/OpenTransaction";
import TransactionDetail from "../../pages/transactions/transaction-details/TransactionDetails";
import AccountDetails from "../../pages/AccountDetails/AccountDetails";
import PersonalInfo from "../../pages/AccountDetails/AccountDetailsContent/PersonalInfo/PersonalInfo";
import Signature from "../../components/sign/Signature";
import ChangePassword from "../../pages/AccountDetails/AccountDetailsContent/ChangePassword/ChangePassword";
import { AuthProvider } from "../../hooks/useAuth";
import { ProtectedLayout } from "../../layout/ProtectedLayout";
import { LoginLayout } from "../../layout/LoginLayout";
import PaymentMethod from "../../pages/paymentMethod/PaymentMethod";
import Esigniture from "../../components/esigniture/e-signiture";
import SigninPage from "../../pages/inspection/fillForm/du/siginpage";
import CategoryStep from "../../pages/inspection/Step-0-selectCategory/step-0-category.js";
import ChoosMethod from "../../pages/inspection/step-1-chooseMethod/step-1-chooseMethod.js";
import BrandStep from "../../pages/inspection/Step-1-selectBrand/step-1-brand.js";
import PiceaFlow from "../../pages/inspection/step-2-PiceaFlow/step-2-piceaFlow.js";
import SubCategoryStep from "../../pages/inspection/step-2-selectSubCategory/step-2-selectSubCategory.js";
import SelectProduct from "../../pages/inspection/step-3-selectProduct/step-3-selectProduct.js";
import DeviceQuestion from "../../pages/inspection/step-4-questions/DeviceQuestion.jsx";
import PiceaCheckout from "../../pages/inspection/fillForm/form.jsx";
import WipingFlow from "../../pages/inspection/step-6-wiping/step-6-wiping.js";
import VerifyFlow from "../../pages/inspection/step-5-verify/step-5-verify.js";
import CustomerForm from "../../pages/customerForm/customerForm.js";
import NotificationList from "../../pages/Notifications/notificationsList.jsx";
import NewBuyBack from "../../components/buyBack/BuyBack.js";
import { isLoggedIn, getSelf, getBranchByID } from "../../core/apis/users";

const MainRoute = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<LoginLayout />}>
            {/* <Route path="/" element={<Login />} /> */}
            <Route path="/login" element={<Login />} />
            <Route path="/forgot-password" element={<ResetComponent />} />
            <Route path="/reset-password" element={<ResetByComponent />} />
            <Route path="/reset-password/by-OTP" element={<OTPComponent />} />
            <Route
              path="/new-password/:token"
              element={<ResetFormComponent />}
            />
            <Route path="/new-password" element={<ResetFormComponent />} />
          </Route>

          <Route element={<ProtectedLayout />}>
            {/* NEW ROUTES */}
            <Route path="/" element={<NewBuyBack />} />
            <Route path="/sell" element={<CategoryStep />} />
            <Route
              path="/sell/:category/chooseamethod"
              element={<ChoosMethod />}
            />
            <Route path="/sell/:category" element={<BrandStep />} />
            <Route path="/sell/:category/picea" element={<PiceaFlow />} />

            <Route
              path="/sell/:category/:brand/"
              element={<SubCategoryStep />}
            />
            <Route
              path="/sell/:category/:brand/:subcategory"
              element={<SelectProduct />}
            />
            <Route
              path="/sell/:category/:brand/:subcategory/:productslug/questions"
              element={<DeviceQuestion />}
            />
            <Route path="/sell/checkout" element={<PiceaCheckout />} />

            {/* <Route
              path="/sell/:category/:brand/:subcategory/:product/checkout"
              element={<PiceaCheckout />}
            /> */}

            <Route
              path="/sell/:category/:brand/:subcategory/:product/checkout"
              element={<CustomerForm />}
            />
            <Route path="/wiping" element={<WipingFlow />} />

            <Route
              path="/sell/:category/:brand/:subcategory/:product/verify"
              element={<VerifyFlow />}
            />

            <Route path="/notifications" element={<NotificationList />} />

            <Route path="/sales" element={<OpenTransaction />} />
            <Route path="/sign" element={<SigninPage />} />
            <Route path="/sale-details/:id" element={<TransactionDetail />} />
            <Route path="/Signature" element={<Signature />} />
            <Route path="/account-details/" element={<AccountDetails />}>
              <Route
                index
                element={<Navigate to="personal-information" replace />}
              />
              <Route path="personal-information" element={<PersonalInfo />} />
              <Route path="change-password" element={<ChangePassword />} />
            </Route>
            <Route path="/Esigniture" element={<Esigniture />} />
            <Route path="/payment-method" element={<PaymentMethod />} />
            <Route path="*" element={<Navigate to="/sell" replace />} />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default MainRoute;
