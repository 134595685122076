import React from "react";
import "../AccountAccordion/AccountAccordion.scss";
import { Accordion } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";

function AccountAccordion(props) {
  const { no, title, content, link } = props;
  let location = useLocation().pathname.split("/");
  let navigate = useNavigate();
  return (
    <Accordion
      className="Accordion_account"
      onClick={() => navigate(link)}
      activeKey={location[location.length - 1] === link && no}
    >
      <Accordion.Item eventKey={no}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>{content}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccountAccordion;
