import React, { useState, useEffect } from "react";
import Button from "../../../../components/button/Button";
import { FormControlLabel, Skeleton, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import "./PersonalInfo.scss";
import {
  checkPhoneValidity,
  useGetSelfUser,
} from "../../../../core/apis/users";
import { getalllanguages } from "../../../../core/apis/newapis";
import { PhoneInput } from "../../../../components/phoneInput/PhoneInput";
import { useAxios } from "../../../../hooks/useFetch";
import {
  FormInput,
  FormDropdownList,
} from "../../../../components/formComponents/FormComponents";
import { useFormik } from "formik";
import { useGlobalLangValues } from "../../../../context/LanguageContext";
import * as yup from "yup";

const personalSchema = yup.object({
  full_name: yup
    .string("Enter your full name")
    .required("Full name is required"),
  phone_number: yup.number().required("Field is required"),
  language_id: yup.string().nullable(),
});

const PersonalInfo = () => {
  const { data: account, isLoading: loading, refetch } = useGetSelfUser();
  const [saveLoading, setSaveLoading] = useState(false);
  const [phone_number_error, setPhone_number_error] = useState("");
  const { t, i18n } = useTranslation();
  const [arabicComponent, setArabicComponent] = useState(false); // State to track Arabic language
  const { enabledLanguages } = useGlobalLangValues();

  const formik = useFormik({
    initialValues: {
      ...account,
      language_id: account?.language?.id,
      language: account?.language,
    },
    enableReinitialize: true,
    validationSchema: personalSchema,
    onSubmit: () => {
      SubmitForm();
    },
  });
  const { loading: loadingUpdate, callAPI } = useAxios(
    `retailer/update-self-user`,
    "put",
    formik?.values
  );

  //FUNCTIONS
  const SubmitForm = async () => {
    setSaveLoading(true);
    let phoneCheck = await checkPhone(formik.values.phone_number);
    if (phoneCheck) {
      callAPI();
      setSaveLoading(false);
      refetch();
    } else {
      setSaveLoading(false);
    }
  };

  const checkPhone = async (phone) => {
    let boolean = false;
    await checkPhoneValidity({ phone_number: phone }).then((res) => {
      if (!res?.data?.data) {
        boolean = false;
        setPhone_number_error(res?.data?.message);
      } else {
        boolean = true;
        setPhone_number_error("");
      }
    });

    return boolean;
  };

  const handleNumber = () => (number) => {
    formik.setFieldValue("phone_number", number);
    setPhone_number_error("");
  };

  // Effect to set Arabic component class
  useEffect(() => {
    setArabicComponent(i18n.language === "ar");
    getalllanguages();
  }, [i18n.language]);

  return (
    <div className="personal_info">
      {loading ? (
        <Skeleton />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <div className={`rows ${arabicComponent ? "arabic-component" : ""}`}>
            <FormInput
              label={t("Email")}
              fullWidth
              className="inputform"
              name="email"
              id="email"
              readOnly
              disabled
              placeholder={t("Enter Email")}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <FormInput
              label={t("Full Name")}
              placeholder={t("Enter Full Name")}
              name="full_name"
              id="full_name"
              value={formik.values.full_name}
              onChange={formik.handleChange}
              error={
                formik.touched.full_name && Boolean(formik.errors.full_name)
              }
              helperText={formik.touched.full_name && formik.errors.full_name}
            />
          </div>
          <div className={`rows ${arabicComponent ? "arabic-component" : ""}`}>
            <div style={{ width: "100%" }}>
              <PhoneInput
                fullWidth
                value={formik.values?.phone_number}
                className="inputForm direction-left"
                onChange={handleNumber}
                helperText={
                  phone_number_error !== ""
                    ? phone_number_error
                    : formik.errors.phone_number
                }
              />
            </div>
            {/* <div style={{ width: "50%" }} className="direction-left">
              <FormDropdownList
                data={enabledLanguages || []}
                name="language_id"
                label={t("Preferred language")}
                value={formik?.values?.language}
                onChange={(value) => formik.setFieldValue("language", value)}
              />
            </div> */}
          </div>
          <FormControlLabel
            style={{
              marginTop: "5px",
              marginLeft: arabicComponent ? "" : "3px",
              marginRight: arabicComponent ? "3px" : "",
            }}
            control={
              <Switch
                name="enable_notification"
                checked={formik?.values?.enable_notification}
                onChange={(_, checked) => {
                  formik.setFieldValue("enable_notification", checked);
                }}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={t("Enable Notification")}
            labelPlacement="start"
          />

          <div className="buttonContainer">
            <Button
              type="submit"
              width="25%"
              name={t("Update")}
              selected
              disabled={loadingUpdate || saveLoading}
              loading={loadingUpdate || saveLoading}
            />
          </div>
        </form>
      )}
    </div>
  );
};
export default PersonalInfo;
