import React from "react";

import { useTranslation } from "react-i18next";
import { useGlobalLangValues } from "../../../context/LanguageContext";

import Successfullyplacedimage from "../../../assests/Images/Successfullyplaced.png";
import "./DoneStep.scss";

const DoneStep = (props) => {
  const { loading, handlePrint, handlesendbyemail } = props;
  const { t } = useTranslation();
  const { selectedLanguage, isArabic } = useGlobalLangValues();

  return (
    <div className="centered-container">
      <div className="Successfullyplacedimage">
        <img
          className={loading ? "opacity-image successImage" : "successImage"}
          src={Successfullyplacedimage}
          alt="Successfully Placed"
        />
      </div>
      <span className="Successfullyplacedtext loading-text">
        {loading ? t("Loading... ") : t("Successfully placed!")}
      </span>
    </div>
  );
};

export default DoneStep;
