// GlobalContext.js
import React, { createContext, useContext, useState } from "react";

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    piceaValues: {},
  });

  // For Category Inorder to get all Catgeories BY Id
  const [selectedItemId, setSelectedItemId] = useState(null);

  const setItemId = (id) => {
    setSelectedItemId(id);
  };

  //Set 2use General WorkFlow State
  const [twoUseWorkFlowState, settwoUseWorkFlowState] = useState(false);
  const seTwoUseWorkFlowState = (value) => {
    settwoUseWorkFlowState(value);
  };

  //Set 2use Verfiy WorkFlow State
  const [TwoUseverfiyworkFlowState, settwoUseverfiyworkFlowState] =
    useState(false);
  const setTwoUseVerfiyworkFlowStatemethod = (value) => {
    settwoUseverfiyworkFlowState(value);
  };

  //Set 2use Wiping WorkFlow State
  const [TwoUsewipingworkFlowState, setwipingworkFlowState] = useState(false);
  const setWipingworkFlowStatemethod = (value) => {
    setwipingworkFlowState(value);
  };

  // Set 2USE Oragnization WIPE WorkFlows
  const [twoUseVerifyworkFlow, settwoUseVerifyworkFlow] = useState();

  const setOrganizationtwoUseVerifyworkFlow = (value) => {
    settwoUseVerifyworkFlow(value);
  };

  // Set 2USE Oragnization WIPE WorkFlows
  const [twoUseWipeWorkFlow, settwoUseWipeWorkFlow] = useState();

  const setOrganizationtwoUseWipeWorkFlow = (value) => {
    settwoUseWipeWorkFlow(value);
  };

  // Update Oragnization state with Picea
  const [piceaOrganizationState, setpiceaOrganizationState] = useState(false);
  const setOrganizationPiceaState = (value) => {
    setpiceaOrganizationState(value);
  };

  // Update Oragnization state with Picea
  const [piceaWipeOrganizationState, setpiceaWipeOrganizationState] =
    useState(false);
  const setOrganizationWipePiceaState = (value) => {
    setpiceaWipeOrganizationState(value);
  };

  // Set Picea Oragnization WorkFlows
  const [piceaOrganizationWorkFlow, setpiceaOrganizationWorkFlow] = useState();

  const setOrganizationPiceaWorkflows = (value) => {
    setpiceaOrganizationWorkFlow(value);
  };

  // Set Picea Oragnization WIPE WorkFlows
  const [piceaWipeOrganizationWorkFlow, setpiceaWipeOrganizationWorkFlow] =
    useState();

  const setOrganizationPiceaWipeWorkflows = (value) => {
    setpiceaWipeOrganizationWorkFlow(value);
  };

  //IMAGES

  const [Oragnizationimages, setOragnizationimages] = useState();

  const setOrganizationImages = (value) => {
    setOragnizationimages(value);
  };

  // ThirdPartyValue

  const [thirdPartyValue, setthirdPartyValue] = useState();

  const setThirdPartyValue = (value) => {
    setthirdPartyValue(value);
  };

  const updateGlobalState = (newState) => {
    setGlobalState((prevState) => ({ ...prevState, ...newState }));
  };

  // set the state for employee information (inorder to show it or hide based on status)
  const [employeInfoEnabled, setenabledEmployeeInFo] = useState(false);

  const setISEmployeeInfoEnabled = (value) => {
    setenabledEmployeeInFo(value);
  };

  // For Branch Country ID
  const [branchcountryID, setbranchcountryID] = useState(null);

  const setBranchID = (id) => {
    setbranchcountryID(id);
  };

  return (
    <GlobalContext.Provider
      value={{
        globalState,
        updateGlobalState,
        selectedItemId,
        setItemId,
        piceaOrganizationState,
        setOrganizationPiceaState,
        piceaOrganizationWorkFlow,
        setOrganizationPiceaWorkflows,
        twoUseWorkFlowState,
        seTwoUseWorkFlowState,
        Oragnizationimages,
        setOrganizationImages,
        TwoUseverfiyworkFlowState,
        setTwoUseVerfiyworkFlowStatemethod,
        TwoUsewipingworkFlowState,
        setWipingworkFlowStatemethod,
        setOrganizationPiceaWipeWorkflows,
        piceaWipeOrganizationWorkFlow,
        setOrganizationtwoUseWipeWorkFlow,
        twoUseWipeWorkFlow,
        setOrganizationtwoUseVerifyworkFlow,
        twoUseVerifyworkFlow,
        setOrganizationWipePiceaState,
        piceaWipeOrganizationState,
        setThirdPartyValue,
        thirdPartyValue,
        setISEmployeeInfoEnabled,
        employeInfoEnabled,
        setBranchID,
        branchcountryID
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobalValues = () => {
  return useContext(GlobalContext);
};
