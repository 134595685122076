import React, { useEffect } from "react";
import { useState } from "react";
import "./formdetails.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "../../../components/button/Button";
import { PhoneInput } from "../../../components/phoneInput/PhoneInput";
import { DialogContent, DialogTitle, Button as MUIButton } from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import { Dialog } from "@mui/material";
import { useGet } from "../../../hooks/useFetch";
import {
  FormDropdownList,
  FormInput,
} from "../../../components/formComponents/FormComponents";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import { useGlobalValues } from "../../../context/GlobalContext";

const FillFormDetails = (props) => {
  const { onSuccess, receivedphonenumber, receivedemail, sendSerial } = props;

  const [phone_number_error, setPhone_number_error] = useState("");
  const [saveLoading, setSaveLoading] = useState(false);
  const [errormsg, seterrormsg] = useState("");
  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const [serialpopUp, setSerialPopUp] = useState(false);
  const [serialNumber, setserialNumber] = useState("");
  const [dialCode, setDialCode] = useState("");

  const { thirdPartyValue, employeInfoEnabled } = useGlobalValues();

  let DuIntegration = thirdPartyValue == 2;

  const { data: countries, countriesLoading } = useGet({
    url: `get-all-countries`,
  });
  let countriesArray = countries?.data?.data;

  const { t } = useTranslation();

  const formSchema = yup.object({
    first_name: yup
      .string("Enter your full name")
      .required(t("First Name is required")),
    middle_name: yup
      .string("Enter your full name")
      .required(t("Middle Name is required")),
    last_name: yup
      .string("Enter your full name")
      .required(t("Last Name is required")),
    full_address: yup
      .string("Enter your full address")
      .required(t("Full Address is required")),
    city: yup.string("Enter your full address").required(t("City is required")),
    paper_type: yup
      .number("Enter your ID Or Passport Number")
      .required(t("ID Or Passport Number required")),
    id_or_passport_number: yup
      .string("")
      .nullable()
      .when("paper_type", {
        is: 1,
        then: yup
          .string("Enter a valid ID")
          .nullable()
          .matches(/^[0-9]+$/, "Enter a valid ID")
          .required(t("Field is required")),
        otherwise: yup
          .string("Enter a valid passport number")
          .required(t("Field is required")),
      }),

    email: yup.string("Enter your email").required(t("Field is required")),

    nationality: yup.object().nullable().required(t("Field is required")),

    phone_number: yup.number().nullable().required(t("Field is required")),
  });

  const formik = useFormik({
    initialValues: {
      email: receivedemail ? receivedemail : "",
      first_name: "",
      middle_name: "",
      last_name: "",
      nationality: null,
      phone_number: receivedphonenumber ? receivedphonenumber : null,
      paper_type: 1,
      id_or_passport_number: "",
      full_address: "",
      city: "",
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  //handle Phone Number
  const handleNumber = () => (number, info) => {
    setDialCode(info?.dialCode);
    formik.setFieldValue("phone_number", number);
    setPhone_number_error("");
  };

  useEffect(() => {
    if (employeInfoEnabled) {
      setTimeout(() => {
        setSerialPopUp(true);
      }, 800);
    }
    if (receivedphonenumber) {
      formik.setFieldValue("phone_number", receivedphonenumber);
    }
  }, []);

  const handleSubmitForm = async (formValues) => {
    setSaveLoading(true);
    onSuccess({ customerInfo: formValues });
    if (employeInfoEnabled) {
      sendSerial(serialNumber);
    }
  };

  const handleVerifyAndProceed = () => {
    seterrormsg("");
    if (serialNumber.length > 5) {
      setSerialPopUp(false);
    } else {
      seterrormsg("Field Is Not Valid");
    }
  };

  return (
    <div>
      <div
        className={
          isArabic
            ? " arabic-component pad-10  filformContainer"
            : "filformContainer "
        }
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="row width70">
            <div className="form-inputs-double-children">
              <FormInput
                required
                type="text"
                name="first_name"
                value={formik?.values?.first_name}
                placeholder={t("Enter First Name")}
                fontweight="500"
                borderVal={true}
                onChange={formik.handleChange}
                label={t("First Name")}
                helperText={
                  formik.touched?.first_name && formik.errors?.first_name
                }
              />

              <FormInput
                required
                type="text"
                name="middle_name"
                placeholder={t("Enter Middle name")}
                fontweight="500"
                borderVal={true}
                helperText={
                  formik.touched?.middle_name && formik.errors?.middle_name
                }
                value={formik?.values?.middle_name}
                onChange={formik.handleChange}
                label={t("Middle Name")}
              />

              <FormInput
                required
                type="text"
                name="last_name"
                placeholder={t("Enter Last name")}
                fontweight="500"
                borderVal={true}
                helperText={
                  formik.touched?.last_name && formik.errors?.last_name
                }
                value={formik?.values?.last_name}
                onChange={formik.handleChange}
                label={t("Last Name")}
              />
              <FormDropdownList
                required
                loading={countriesLoading}
                data={countriesArray || []}
                name="nationality"
                className="countryForm direction-left"
                label={t("Nationality")}
                fontweight="500"
                borderVal={true}
                value={formik?.values?.nationality}
                onChange={(value) => formik.setFieldValue("nationality", value)}
                helperText={
                  formik.touched?.nationality && formik.errors?.nationality
                }
              />

              <FormInput
                required
                type="text"
                name="email"
                fontweight="500"
                borderVal={true}
                verfied={receivedphonenumber ? false : true}
                disabled={receivedemail ? true : false}
                placeholder={t("Enter Email")}
                helperText={formik.touched?.email && formik.errors?.email}
                value={formik?.values?.email}
                onChange={formik.handleChange}
                label={t("Email")}
              />
              {receivedphonenumber ? (
                <PhoneInput
                  required
                  onChange={handleNumber}
                  value={receivedphonenumber.toString()}
                  verfied={true}
                  className="inputForm direction-left"
                  disabledProps={true}
                  fontweight="500"
                />
              ) : (
                <PhoneInput
                  required
                  value={formik.values?.phone_number}
                  onChange={handleNumber}
                  className="inputForm direction-left"
                  ip={!DuIntegration}
                  onlyCountries={DuIntegration ? ["lb"] : []}
                  country="lb"
                  inputlabel="Country Code"
                  enableAreaCodes={!DuIntegration}
                  disableCountryCode={DuIntegration}
                  disableDropdown={DuIntegration}
                  helperText={
                    phone_number_error !== ""
                      ? phone_number_error
                      : formik.touched?.phone_number &&
                        formik.errors?.phone_number
                  }
                  fontweight="500"
                />
              )}
            </div>
            <div className="form-inputs-double-children">
              <FormInput
                required
                type="text"
                name="full_address"
                placeholder={t("Enter Country")}
                fontweight="500"
                borderVal={true}
                helperText={
                  formik.touched?.full_address && formik.errors?.full_address
                }
                value={formik?.values?.full_address}
                onChange={formik.handleChange}
                label={t("Country")}
              />

              <FormInput
                required
                type="text"
                name="city"
                placeholder={t("Enter City")}
                fontweight="500"
                borderVal={true}
                helperText={formik.touched?.city && formik.errors?.city}
                value={formik?.values?.city}
                onChange={formik.handleChange}
                label={t("City")}
              />
              <div>
                <FormControl fullWidth>
                  <p
                    style={{
                      margin: "0px 0px",
                      fontWeight: "500",
                      fontSize: "1rem",
                      lineheight: " 0.5em",
                    }}
                  >
                    {t("ID") + " / " + t("Passport Number")}
                  </p>
                  <Select
                    labelId="paper-type-label"
                    id="paper-type"
                    name="paper_type"
                    placeholder="Select"
                    value={formik.values.paper_type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.paper_type &&
                      Boolean(formik.errors.paper_type)
                    }
                    style={{
                      height: "40px",
                      fontweight: "500",
                    }}
                  >
                    <MenuItem value={1}>{t("ID")}</MenuItem>
                    <MenuItem value={2}>{t("Passport Number")}</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <FormInput
                required
                fontweight="500"
                borderVal={true}
                name={"id_or_passport_number"}
                placeholder={` ${
                  formik.values.paper_type === 1
                    ? t("ID")
                    : t("Passport Number")
                }`}
                label={
                  formik.values.paper_type === 1
                    ? t("ID")
                    : t("Passport Number")
                }
                helperText={
                  formik.touched?.id_or_passport_number &&
                  formik.errors?.id_or_passport_number
                }
                value={formik?.values?.id_or_passport_number}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="row button-form">
            <Button
              className="placeorder"
              name={t("Next")}
              selected
              width="15%"
              type="submit"
              disabled={saveLoading}
              loading={saveLoading}
            />
          </div>
        </form>

        <Dialog
          open={serialpopUp}
          maxWidth="md"
          className={
            isArabic
              ? " selectdialogDisclaimer arabic-component"
              : " selectdialogDisclaimer"
          }
        >
          <DialogTitle className="dialogTitle my-2">
            {t("Please Add Device Serial Number / IMEI")}
          </DialogTitle>
          <DialogContent className="dialogAction my-1">
            <FormInput
              required
              type="text"
              name="serialNumber"
              placeholder={t("Serial Number")}
              value={serialNumber}
              onChange={(e) => {
                setserialNumber(e.target.value);
                seterrormsg("");
              }}
              label={t("Serial Number")}
            />
            <p className="errorMsg">{errormsg}</p>
            <Button
              name={t("Continue")}
              width="100%"
              selected
              disabled={!serialNumber}
              onClick={handleVerifyAndProceed}
            />
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};
export default FillFormDetails;
