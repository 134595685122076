import React from "react";
import "./Button.scss";
import { CardSVG } from "../../assests/icons/SVG";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const Button = (props) => {
  const { selected, href, style, width, type } = props;
  const {
    name,
    onClick,
    id,
    className,
    disabled,
    loading,
    starticon,
    endicon,
  } = props;

  const { i18n, t } = useTranslation();

  return (
    <LoadingButton
      {...props}
      endIcon={endicon ? endicon : ""}
      type={type}
      disabled={disabled}
      loading={loading}
      loadingPosition="end"
      name={id}
      component={href ? Link : "button"}
      to={href && href}
      sx={{
        backgroundColor: `${selected ? "#4ba4f0" : "#ffffff"}`,
        // fontSize: 18,
        textTransform: "none",
        borderRadius: "50px",
        color: `${!selected ? "#4ba4f0" : "#ffffff"}`,
        ...style,
        width: width,
        "& .MuiButton-endIcon": { margin: "0px 5px" },
      }}
      onClick={onClick}
      className={`buttonComponent ${className ? className : ""} ${
        selected ? "selectedButton selectedColor" : "isntSelectedButton"
      }`}
      variant={selected ? "contained" : "outlined"}
      startIcon={starticon ? starticon : ""}
    >
      {(name?.toLowerCase() === "add to cart" ||
        name?.toLowerCase() === "in cart") && (
        <CardSVG className={!selected ? "cardSVG" : ""} />
      )}
      <p name={id}>{loading ? t("Loading... ") : name}</p>
    </LoadingButton>
  );
};
Button.defaultProps = {
  selected: false,
  loading: false,
};
Button.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default Button;
