import React, { Fragment, useRef, useState } from "react";
import SignaturePad from "react-signature-canvas";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../components/button/Button";
import { LogoWithTextSVG } from "../../../../assests/icons/SVG";
import { attachdisclaimer } from "../../../../core/apis/transactions";
import { Barcodee } from "./PrintedBarcode";
import ReactToPrint from "react-to-print";
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import moment from "moment";
import "./style.scss";
import "../../../../components/disclaimer/Disclaimer.scss";
import { useGlobalLangValues } from "../../../../context/LanguageContext";
import { useTranslation } from "react-i18next";

export default function SigninPage(props) {
  const marginTop = "25px";
  const marginBottom = "25px";
  const marginLeft = "0";
  const marginRight = "5%";
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;
                    size: A4;}`;
  };
  const { t } = useTranslation();

  const { selectedLanguage, isArabic } = useGlobalLangValues();
  let langu = selectedLanguage === "ar" ? "arabic" : "english";

  const barcodeRef = useRef();
  const barprintRef = useRef();
  const printArabicRef = useRef();
  const printRef = useRef();

  let { state } = useLocation();

  let payload = {
    ...state?.payload,
  };
  let result = {
    ...state?.result,
  };

  let useremail = state?.result?.transaction_details?.fill_form?.email;

  let thirdParty = { ...state?.thirdParty };

  const { buyer_name, product_name, nationality } = result?.disclaimer || {};
  const { passport_number, date, seller_name } = result?.disclaimer || {};

  let navigate = useNavigate();
  const [loading, setloading] = useState(false);
  const [imageURL, setImageURL] = useState(null);
  const sigCanvas = useRef({});

  const clear = () => {
    setImageURL(null);
    sigCanvas.current.clear();
  };

  const save = () =>
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));

  const handleGeneratePdf = () => {
    const pdfTable = document.getElementById("Englishemaildata");
    var html = htmlToPdfmake(pdfTable.innerHTML);
    const documentDefinition = { content: html };
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
    pdfMake.createPdf(documentDefinition).getBase64((decodedString) => {
      let data = decodedString;
      handlesendbyemail(data);
    });
  };

  const printpdf = () => {
    printRef.current?.handleClick();
  };

  const handlesendbyemail = (section) => {
    let data = {
      file_content: section,
      email: useremail,
    };
    attachdisclaimer(data).then((res) => {
      if (res.data.success) {
        setTimeout(() => {
          thirdParty.id !== 2
            ? barprintRef.current?.handleClick()
            : navigate("/payment-method", {
                state: { payload: payload },
              });
        }, 50);
      } else alert(res.data.message);
    });
  };
  return (
    <div className="main-sub-body">
      <ReactToPrint
        trigger={() => <Fragment />}
        content={() => barcodeRef.current}
        ref={barprintRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            navigate("/sell");
          }, 500);
        }}
      />
      <ReactToPrint
        trigger={() => <Fragment />}
        content={() => printArabicRef.current}
        ref={printRef}
        removeAfterPrint
        onAfterPrint={() => {
          setTimeout(() => {
            thirdParty.id !== 2
              ? barprintRef.current?.handleClick()
              : navigate("/payment-method", {
                  state: { payload: payload },
                });
          }, 500);
        }}
      />
      <Barcodee ref={barcodeRef} data={result}></Barcodee>
      <div className="formContainer">
        <main>
          <style>{getPageMargins()}</style>

          <>
            {langu != "arabic" ? (
              <div className="Englishemaildata" id="Englishemaildata">
                <LogoWithTextSVG className="LogoWithTextSVG" />
                <br />
                <br />
                <p>
                  I, the undersigned{" "}
                  <b>/{seller_name || payload?.fill_form?.full_name}/</b>
                  ,citizen of (nationality)
                  <b>{nationality || sessionStorage?.getItem("nationality")}</b>
                  , holder of passport (or identity card) Number
                  <b>
                    {" "}
                    /
                    {passport_number ||
                      payload?.fill_form?.id_or_passport_number}
                    /
                  </b>
                  ,
                </p>
                <br />
                <p>
                  Hereby irrevocably confirm, warrant and acknowledge the
                  following:
                </p>
                <br />
                <ol id="l1">
                  <li data-list-text={1}>
                    <p>
                      I am the sole owner of the device
                      <b> {product_name || payload?.product?.name?.en} </b>
                      (the “<b>Device</b>”) and the data contained therein (the
                      “<b>Data</b>”) and I have the right, title and interest to
                      perform the sale of the Device in the terms and conditions
                      I deem appropriate.
                    </p>
                  </li>
                  <li data-list-text={2}>
                    <p>
                      I hereby acknowledge that I sold it to <b>{buyer_name}</b>{" "}
                      (the “<b>Buyer</b>”) and subsequently, I renounce any and
                      all right, title or interest, present or future in the
                      Device and/or in the Data.
                    </p>
                  </li>
                  <li data-list-text={3}>
                    <p>
                      The information, data and specifications related to the
                      Device provided to the Buyer are accurate and complete and
                      contain no known or suspected material inaccuracy.
                    </p>
                  </li>
                  <li data-list-text={4}>
                    <p>
                      The Purchase Price of the Device has been fully paid to me
                      and I hereby discharge the Buyer of any claim or right in
                      relation thereto.
                    </p>
                  </li>
                  <li data-list-text={5}>
                    <p>
                      As of the date hereof, the Buyer became the lawful owner
                      of the Device and has the legal capacity and authority to
                      use, sell or dispose of the Device in any way.
                    </p>
                  </li>
                  <li data-list-text={6}>
                    <p>
                      The Buyer shall have the right to delete the Data and is
                      fully discharged from any claim or demand or the like,
                      present or future relating directly or indirectly to the
                      sale of the Device as well as any claim resulting from
                      deleting the Data contained in the Device.
                    </p>
                  </li>
                  <li data-list-text={7}>
                    <p>
                      I waive any right or interest I may have at any time to
                      contest or otherwise challenge the validity or legality of
                      this deed.
                    </p>
                  </li>
                </ol>
                <br />
                <h4>
                  Name: <b>{seller_name || payload?.fill_form?.full_name}</b>
                </h4>
                <br />
                <h4>Date: {moment(date).format("YYYY-MM-DD")}</h4>
                <br />
                <h4>Signature:</h4>
                <div>
                  {imageURL ? (
                    <img src={imageURL} alt="sigimage" width="100" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            ) : (
              <div
                className="Arabicmaildata"
                style={{ direction: "rtl" }}
                id="Arabicmaildata"
                ref={printArabicRef}
              >
                <LogoWithTextSVG className="LogoWithTextSVG" />
                <p>
                  أنا الموقع/ة أدناه{" "}
                  <b>/{seller_name || payload?.fill_form?.full_name}/</b>, من
                  الجنسية
                  <b>
                    {nationality || sessionStorage?.getItem("nationality")}
                  </b>{" "}
                  وحامل/ة جواز السفر (أو الهوية رقم البطاقة
                  <b>
                    {" "}
                    /
                    {passport_number ||
                      payload?.fill_form?.id_or_passport_number}
                    /
                  </b>
                  ,
                </p>
                <br />
                <p>نؤكد بموجب هذا بشكل لا رجوع فيه ، ونضمن ونقر بما يلي</p>
                <br />
                <ol id="l1">
                  <li data-list-text={1}>
                    <p>
                      أنا المالك/ة الوحيد/ة للجهاز{" "}
                      <b> {product_name || payload?.product?.name?.en} </b>
                      <b>الجهاز</b> والبيانات الواردة فيه ("البيانات") ولدي الحق
                      والملكية والمصلحة لأداء بيع الجهاز بالشروط والأحكام التي
                      أراها مناسبة.
                    </p>
                  </li>
                  <li data-list-text={2}>
                    <p>
                      أقر بموجب هذا بأنني بعته إلى <b>{buyer_name} </b>
                      <b>المشتري</b> وبعد ذلك ، أتخلى عن أي وجميع الحقوق أو
                      الملكية أو المصلحة الحالية أو المستقبلية في الجهاز و / أو
                      في البيانات.
                    </p>
                  </li>
                  <li data-list-text={3}>
                    <p>
                      III. المعلومات والبيانات والمواصفات المتعلقة بالجهاز
                      والمقدمة للمشتري دقيقة وكاملة ولا تحتوي على معلومات غير
                      دقيقة أو مشتبه بها.
                    </p>
                  </li>
                  <li data-list-text={4}>
                    <p>
                      د تم دفع سعر شراء الجهاز بالكامل لي ، وأنا بموجب هذا أبرئ
                      المشتري من أي مطالبة أو حق متعلق بذلك
                    </p>
                  </li>
                  <li data-list-text={5}>
                    <p>
                      V. اعتبارًا من تاريخ هذا القانون ، أصبح المشتري هو المالك
                      الشرعي للجهاز ولديه الأهلية والسلطة القانونية لاستخدام
                      الجهاز أو بيعه أو التخلص منه بأي شكل من الأشكال.
                    </p>
                  </li>
                  <li data-list-text={6}>
                    <p>
                      V. اعتبارًا من تاريخ هذا القانون ، أصبح المشتري هو المالك
                      الشرعي للجهاز ولديه الأهلية والسلطة القانونية لاستخدام
                      الجهاز أو بيعه أو التخلص منه بأي شكل من الأشكال.
                    </p>
                  </li>
                  <li data-list-text={7}>
                    <p>
                      V. اعتبارًا من تاريخ هذا القانون ، أصبح المشتري هو المالك
                      الشرعي للجهاز ولديه الأهلية والسلطة القانونية لاستخدام
                      الجهاز أو بيعه أو التخلص منه بأي شكل من الأشكال.
                    </p>
                  </li>
                </ol>
                <br />
                <h4>
                  اسم: <b>{seller_name || payload?.fill_form?.full_name}</b>
                </h4>
                <br />
                <h4>Date: {moment(date).format("YYYY-MM-DD")}</h4>
                <br />
                <h4>التوقيع :</h4>
                <div>
                  {imageURL ? (
                    <img src={imageURL} alt="sigimage" width="100" />
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </>
        </main>
        <div className="Esig">
          {imageURL ? (
            " "
          ) : (
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: "signatureCanvas",
              }}
            />
          )}
          {imageURL ? (
            " "
          ) : (
            <Button
              className="placeorder"
              onClick={save}
              name={t("save")}
              selected
            />
          )}
          {imageURL ? (
            <Button
              className="placeorder"
              onClick={() => {
                langu == "arabic" ? printpdf() : handleGeneratePdf();
              }}
              name={langu == "arabic" ? t("Print") : t("Send Email")}
              selected
              loading={loading}
            />
          ) : (
            ""
          )}
          {imageURL ? (
            " "
          ) : (
            <Button className="placeorder" onClick={clear} name={t("clear")} />
          )}
        </div>
      </div>
    </div>
  );
}
