import React from "react";
import "./Disclaimer.scss";
import background from "../../assests/Images/2useBackground.png";
import { LogoWithTextSVG } from "../../assests/icons/SVG";
import Image from "../../components/image/Image";
import { useTranslation } from "react-i18next";
import { useGlobalValues } from "../../context/GlobalContext";
import { useGlobalLangValues } from "../../context/LanguageContext";

export const Barcode = React.forwardRef((props, ref) => {
  const { data } = props;
  const marginTop = "25px";
  const marginBottom = "25px";
  const marginLeft = "0";
  const marginRight = "5%";
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;
                    size: A4;}`;
  };
  const { Oragnizationimages } = useGlobalValues();
  const { t } = useTranslation();
  const { isArabic } = useGlobalLangValues();

  return (
    <div ref={ref} className="disclaimer">
      <table>
        <thead>
          <tr>
            <td>
              <div className="header-space" />
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <main>
                <style>{getPageMargins()}</style>
                {props.children ? (
                  props.children
                ) : (
                  <>
                    <div
                      className={
                        isArabic
                          ? "disclaimerText arabic-component"
                          : "disclaimerText"
                      }
                    >
                      <h2>{t("Barcode")}</h2>
                      <h3>{data?.transaction_details?.barcode || "N/A"}</h3>
                      <br />
                    </div>
                  </>
                )}
              </main>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-space"> </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <header>
        {props.header}

        <LogoWithTextSVG className="LogoWithTextSVG" />
        <Image width="100" src={Oragnizationimages?.logo} alt="logo" />
      </header>
      <img alt="background" src={background} className="watermark" />
    </div>
  );
});
