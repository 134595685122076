export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const capitalize = (string) => {
  return string.replace(
    /(^\w|\s\w)(\S*)/g,
    (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
  );
};

export const groupParamsByKey = (params) =>
  [...params.entries()].reduce((acc, tuple) => {
    // getting the key and value from each tuple
    const [key, val] = tuple;
    if (acc.hasOwnProperty(key)) {
      // if the current key is already an array, we'll add the value to it
      if (Array.isArray(acc[key])) {
        acc[key] = [...acc[key], val];
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[key] = [acc[key], val];
      }
    } else {
      // plain assignment if no special case is present
      acc[key] = val;
    }

    return acc;
  }, {});

export const objectCleaner = (obj) => {
  const cleanedObj = Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => {
      if (Array.isArray(v)) {
        return v.some((elem) => elem !== "");
      } else {
        return v != "null" && v != null && v !== "" && v;
      }
    })
  );
  return cleanedObj;
};
export const isEmpty = (obj) => !Object.keys(obj).length;

export const customSort = (a, b) => {
  const isALetter = isNaN(parseInt(a.slug.split("-")[1]));
  const isBLetter = isNaN(parseInt(b.slug.split("-")[1]));

  if (isALetter && !isBLetter) {
    return -1; // Place a before b
  } else if (!isALetter && isBLetter) {
    return 1; // Place b before a
  } else {
    // Both are either letters or numbers, sort based on numeric part
    const numA = parseInt(a.slug.split("-")[1]);
    const numB = parseInt(b.slug.split("-")[1]);
    return numA - numB;
  }
};
