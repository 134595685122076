import React, { useState, useEffect } from "react";
import "./AccountDetails.scss";
import PersonalInfo from "./AccountDetailsContent/PersonalInfo/PersonalInfo";
import AccountAccordion from "../../components/AccountAccordion/AccountAccordion";
import ChangePassword from "./AccountDetailsContent/ChangePassword/ChangePassword";
import { Tab, Tabs } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { capitalize } from "../../core/functions/Functions";
import { useTranslation } from "react-i18next";

const AccountDetails = () => {
  let location = useLocation().pathname.split("/")[2];
  let navigate = useNavigate();
  const handleChange = (_, newValue) => navigate(newValue);
  const [arabicComponent, setArabicComponent] = useState(false); // State to track Arabic language
  const { i18n, t } = useTranslation();

  const data = [
    // Changed title to use translation function t()
    {
      title: t("Personal Information"),
      route: "/account-details/personal-information",
      content: <PersonalInfo />,
    },
    {
      title: t("Change Password"),
      route: "/account-details/change-password",
      content: <ChangePassword />,
    },
  ];

  // Effect to set Arabic component class
  useEffect(() => {
    setArabicComponent(i18n.language === "ar");
  }, [i18n.language]);

  return (
    <div className="main-sub-body">
      <div
        className={`account_details ${
          arabicComponent ? "arabic-component" : ""
        }`}
      >
        <h1 style={{color:"#fff"}}>{t("Account Details")}</h1>
        <div className={"account_details_container"}>
          <Tabs
            value={location}
            onChange={handleChange}
            aria-label="tabs"
            className="tabs"
            orientation="vertical"
          >
            {data.map((item, index) => (
              // Updated value prop to use the route instead of the title
              <Tab label={item.title} value={item.route} key={index} />
            ))}
          </Tabs>
          <div className="outlet">
            <h5>{t(capitalize(location.split("-").join(" ")))}</h5>
            <hr />
            <Outlet />
          </div>
        </div>
      </div>
      <div className="Responsive">
        {data.map((comp, index) => (
          <AccountAccordion
            key={index}
            title={comp.title}
            no={index + 1}
            content={comp.content}
            link={comp.route}
          />
        ))}
      </div>
    </div>
  );
};

export default AccountDetails;
