// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { FCMToken, isUserLoggedIn } from "../core/apis/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyDvTufG39cAvAw1F55aqksb39Xwf1YpaQI",
  authDomain: "use-60481.firebaseapp.com",
  projectId: "use-60481",
  storageBucket: "use-60481.appspot.com",
  messagingSenderId: "918774636562",
  appId: "1:918774636562:web:5c1e665c4fe3f4ed2538cb",
  measurementId: "G-ETFJR0WBYT",
};
initializeApp(firebaseConfig);
const messaging = getMessaging();
// Initialize Firebase
initializeApp(firebaseConfig);

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BFpYel8zwE02t9gZX2WUerd-PQtCxt15-AZmzC8isFtBY7424rAyZof70Bog6FzfbpwzHpAoCdJUMkr0-dBv22g",
  })
    .then((currentToken) => {
      if (currentToken) {
        isUserLoggedIn() &&
          FCMToken({ fcm_token: currentToken }).then((res) => res);
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
