import { Alert, Button } from "@mui/material";
import React, { useEffect } from "react";
import "./Alert.scss";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const AlertComponent = (props) => {
  const { severity, onClick, text, visible, confirmButtonText } = props;
  const { t } = useTranslation();

  useEffect(() => {
    // Display SweetAlert when component mounts
    Swal.fire({
      icon: severity, // Type of alert (success, error, warning, info)
      text: text,
      confirmButtonText: t(confirmButtonText),
      showCancelButton: false,
      allowOutsideClick: false,
      confirmButtonColor: severity == "error" ?  "#d33" : "#3085d6",
      showClass: {
        popup: `
          animate__animated
          animate__fadeInUp
          animate__faster
        `,
      },
      hideClass: {
        popup: `
          animate__animated
          animate__fadeOutDown
          animate__faster
        `,
      },
    }).then((result) => {
      // Handle confirm button click
      if (result.isConfirmed) {
        // Optionally, you can call onClose when the alert is confirmed
        if (onClick) {
          onClick();
        }
      }
    });

    // Clean up SweetAlert instance when component unmounts
    return () => {
      Swal.close();
    };
  }, [severity, text, confirmButtonText, onClick]);

  return null; // SweetAlert is managed outside of React's rendering
};

AlertComponent.defaultProps = {
  severity: "success", // Default alert type
  confirmButtonText: "Continue", // Default confirm button text
};
export default AlertComponent;
