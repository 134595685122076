import React, { useContext, useState } from "react";
import "./ResetPassword.scss";
import { AlertContext } from "../../../context/AlertContext";
import * as AuthApi from "../../../core/apis/auth";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { Input } from "../../../components/input/Input";
import { InputAdornment } from "@mui/material";
import { MailOutline } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { useGlobalLangValues } from "../../../context/LanguageContext";

const ForgotPassword = () => {
  //1st step
  const { t } = useTranslation();
  const { isArabic } = useGlobalLangValues();

  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AlertContext);
  let navigate = useNavigate();

  const loginSchema = yup.object({
    email: yup
      .string(t("Enter your email"))
      .email(t("Enter a valid email"))
      .required(t("Email is required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const data = {
        user_type_id: 2,
        email: values.email,
      };
      setLoading(true);
      AuthApi.checkEmail(data).then((res) => {
        res.data.success &&
          navigate("/reset-password", { state: { email: data.email } });
        setAlert({
          ...alert,
          visible: true,
          text: t(res.data.message),
          type: res.data.success ? "success" : "error",
        });
      });
      setLoading(false);
    },
  });

  return (
    <div className="main-login-body">
      <form
        onSubmit={formik.handleSubmit}
        className={
          isArabic ? "forgot-password arabic-component " : "forgot-password"
        }
      >
        <h2 className="forgot-password-header">{t("Forgot password")}</h2>
        <Input
          className="curvedInput"
          type="email"
          placeholder={t("Enter your email")}
          name="email"
          id="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          InputProps={{
            startAdornment: isArabic ? undefined : (
              <InputAdornment position="start">
                <MailOutline style={{ color: "#4BA4F0" }} />
              </InputAdornment>
            ),
            endAdornment: isArabic ? (
              <InputAdornment position="end">
                <MailOutline style={{ color: "#4BA4F0", marginLeft: "10px" }} />
              </InputAdornment>
            ) : undefined,
          }}
        />
        <Button
          name={t("Continue")}
          width="100%"
          selected
          loading={loading}
          disabled={!formik.dirty || loading}
          type="submit"
        />
      </form>
    </div>
  );
};

export default ForgotPassword;
