import React, { useContext, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { UserContext } from "../../../context/UserContext";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../../core/functions/Functions";
import { useTranslation } from "react-i18next";
//COMPONENT
import { useMediaQuery } from "react-responsive";
import { FilterIcon, ResetSVG } from "../../../assests/icons/SVG";
import Button from "../../../components/button/Button";
import { useQuery } from "@tanstack/react-query";
import * as MUI from "@mui/material";
import { useGlobalLangValues } from "../../../context/LanguageContext";

import {
  FormCheckboxDropdown,
  FormDateRange,
  FormCheckBox,
  FormSearchBar,
} from "../../../components/formComponents/FormComponents";
import DialogComponent from "../../../components/modal/Modal";
//SCSS
import "./OpenTransaction.scss";
import screenSize from "../../../theme/variables.scss";
import palette from "../../../theme/color.scss";
//API
import { getTransactionStatuses } from "../../../core/apis/Statuses";
import { getTransactions } from "../../../core/apis/transactions";
import moment from "moment";
import { PhoneInput } from "../../../components/phoneInput/PhoneInput";

const PrimaryDivider = MUI.styled(MUI.Divider)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main, // This sets the divider color to the primary color
  width: "100%",
  opacity: "0.6",
  height: "1px",
}));

export const OpenTransactionsFilter = (props) => {
  const { setSearchParams, setRefetch, searchParams, isFetching } = props;
  const isMediumScreen = useMediaQuery({
    query: `(max-width: ${screenSize?.mdScreen})`,
  });

  const { user } = useContext(UserContext);
  const { t } = useTranslation();

  const [toggle, setToggle] = useState(false);
  const { alert, setAlert } = useContext(AlertContext);
  const [exportLoading, setExportLoading] = useState(false);
  const [openNotice, setOpenNotice] = useState(false);
  const [statuses, setstatuses] = useState([]);
  const { selectedLanguage, isArabic } = useGlobalLangValues();

  moment.locale("en");

  const { isLoading, data: statusValues } = useQuery({
    queryKey: [`Status-content`],
    queryFn: () =>
      getTransactionStatuses().then((res) => {
        const newData = res?.data?.data.map((item) => {
          item.name.ar = t(item.name.en);
          return item;
        });
        setstatuses(newData);
      }),
  });

  //FUNCTIONS
  const exportHandler = () => {
    if (searchParams.get("start_date") && searchParams.get("end_date")) {
      getTransactions({
        barcode: searchParams.get("barcode"),
        transaction_status_id: searchParams.getAll("transaction_status_id"),
        is_all: searchParams.get("is_all"),
        start_date: searchParams.get("start_date"),
        end_date: searchParams.get("end_date"),
      }).then((res) => {
        if (res?.status === 200) {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(new Blob([res?.data]));
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "transaction.xlsx";
          alink.click();
        }
        setExportLoading(false);
        setAlert({
          ...alert,
          visible: true,
          type: res?.status === 200 ? "success" : "error",
          text:
            res?.status === 200 ? "Exported Successfully" : "Failed to Export",
        });
      });
    } else {
      setOpenNotice(true);
    }
  };

  const handleSet = (field, value) => {
    let payload = {
      ...groupParamsByKey(searchParams),
      ...(field === "dates"
        ? { start_date: value[0], end_date: value[1] }
        : { [field]: value }),
      page: 1,
    };

    setSearchParams(objectCleaner(payload));
  };
  const handleResetFilters = () => {
    setSearchParams(objectCleaner({ page: 1 }));
    setRefetch();
  };

  //handle Phone Number
  const handleNumber = () => (number, info) => {
    handleSet("customer_phone", number);
  };

  return (
    <>
      {isMediumScreen && (
        <div className="toggle-filter">
          <MUI.IconButton
            className="filter-icon"
            component="span"
            onClick={() => setToggle(!toggle)}
          >
            <FilterIcon />
          </MUI.IconButton>

          {user?.role_id === 6 && (
            <Button
              selected
              name="Export"
              onClick={exportHandler}
              disabled={exportLoading}
              loading={exportLoading}
            />
          )}
        </div>
      )}
      {((isMediumScreen && toggle) || !isMediumScreen) && (
        <div className="transaction-filter">
          <div className="filter-elements">
            <FormSearchBar
              placeholder={t("Search by Barcode")}
              variant="outlined"
              fullWidth={isMediumScreen}
              value={searchParams?.get("barcode") || ""}
              onChange={(value) => handleSet("barcode", value)}
            />
            <FormSearchBar
              placeholder={t("Search by Name")}
              variant="outlined"
              fullWidth={isMediumScreen}
              value={searchParams?.get("customer_name") || ""}
              onChange={(value) => handleSet("customer_name", value)}
            />

            <PhoneInput
              value={searchParams?.get("customer_phone") || ""}
              ip={true}
              onChange={(value) => handleNumber(value)}
              hideinputlabel={true}
              inputclassname="label-Phone"
              className="inputForm direction-left"
            />

            {isMediumScreen && <PrimaryDivider />}
            <FormCheckboxDropdown
              limitTags={2}
              sx={isMediumScreen && { width: "100%" }}
              placeholder={t("search by status")}
              options={statuses || []}
              name="status_ids"
              id="status_ids"
              multiple
              loading={isLoading}
              value={statuses?.filter((i) =>
                searchParams
                  .getAll("transaction_status_id")
                  ?.includes(i?.id.toString())
              )}
              onChange={(_, value) => {
                handleSet(
                  "transaction_status_id",
                  value.find((option) => option.value === "select-all")
                    ? statuses?.map((i) => i.id)
                    : value.map((i) => i.id) ||
                        searchParams.getAll("transaction_status_id")
                );
              }}
            />
            {isMediumScreen && <PrimaryDivider />}
            <FormDateRange
              withPortal={isMediumScreen}
              fullWidth={isMediumScreen}
              onChange={(value) => {
                handleSet("dates", value);
              }}
              value={[
                searchParams.get("start_date"),
                searchParams.get("end_date"),
              ]}
            />

            {user?.role_id === 6 && (
              <>
                {isMediumScreen && <PrimaryDivider />}
                <FormCheckBox
                  value={JSON.parse(searchParams.get("is_all"))}
                  checked={JSON.parse(searchParams.get("is_all"))}
                  label={t("All Transactions")}
                  tooltipMessage={t(
                    "Choose the transactions you'd like to export for your records"
                  )}
                  onChange={(e) => handleSet("is_all", e)}
                />
              </>
            )}
          </div>

          <div className="filter-actions">
            <Button
              selected
              name={t("Search")}
              onClick={() => setRefetch()}
              disabled={isFetching}
              loading={isFetching}
            />
            <div className="side-filter">
              {user?.role_id === 6 || isMediumScreen ? (
                <Button
                  name={t("Reset Filter")}
                  onClick={() => handleResetFilters()}
                  starticon={
                    !isArabic ? <ResetSVG fill={palette.primaryColor} /> : ""
                  }
                  endicon={
                    isArabic ? <ResetSVG fill={palette.primaryColor} /> : ""
                  }
                  width="100%"
                  className="cursor-pointer reset-filter"
                />
              ) : (
                <MUI.IconButton
                  className="reset-icon"
                  component="span"
                  onClick={() => handleResetFilters()}
                >
                  <ResetSVG fill={palette.primaryColor} />
                </MUI.IconButton>
              )}
              {user?.role_id === 6 && !isMediumScreen && (
                //if admin retailer
                <Button
                  selected
                  name={t("Export")}
                  onClick={exportHandler}
                  disabled={exportLoading}
                  loading={exportLoading}
                  width="80%"
                />
              )}
            </div>
          </div>
        </div>
      )}{" "}
      {openNotice && (
        <DialogComponent
          title={t("Notice")}
          open={true}
          buttonLabel={t("Continue")}
          handleModal={() => setOpenNotice(false)}
        >
          <span style={{ fontSize: "18px" }}>
            {t("Please specify a start date and an end date .")}
          </span>
        </DialogComponent>
      )}
    </>
  );
};

export default OpenTransactionsFilter;
