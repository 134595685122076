import React, { useState, useRef } from "react";
import SignaturePad from "react-signature-canvas";
import "./style.scss";

function Signature() {
  const [imageURL, setImageURL] = useState(null); // create a state that will contain our image url
  const sigCanvas = useRef({});
  const clear = () => sigCanvas.current.clear();
  const save = () =>
    setImageURL(sigCanvas.current.getTrimmedCanvas().toDataURL("image/png"));

  return (
    <div className="App">
      <h1>Signature Pad Example</h1>

      <>
        <SignaturePad
          ref={sigCanvas}
          canvasProps={{
            className: "signatureCanvas",
          }}
        />
        <button onClick={save}>Save</button>
        <button onClick={clear}>Clear</button>
      </>

      <br />
      <br />
      {imageURL ? (
        <img
          src={imageURL}
          alt="my signature"
          style={{
            display: "block",
            margin: "0 auto",
            border: "1px solid black",
            width: "150px",
          }}
        />
      ) : null}
    </div>
  );
}
export default Signature;
