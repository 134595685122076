//Icons
import Verificationimage from "../../assests/Images/Verification.png";
import FillFormimageOff from "../../assests/Images/FillFormOff.png";
import FillFormimageON from "../../assests/Images/FillForm.png";
import EmployeeInfoimageON from "../../assests/Images/EmployeeInfo.png";
import DoneimageON from "../../assests/Images/Done.png";
import EmployeeInfoimageOff from "../../assests/Images/EmployeeInfoOff.png";
import DoneimageOff from "../../assests/Images/DoneOff.png";

export const categoryList = [
  {
    id: 1,
    slug: "mobiles",
    isPicea: true,
    name: {
      en: "Mobiles",
    },
    description: {
      en: "Find great deals on high-quality used mobile phones at our online store. Choose from a wide selection of popular brands and models, all fully tested and certified to ensure maximum performance and reliability. Shop now and save big on your next smartphone upgrade!",
    },
    image_path: "/products/product_type_images/2022-08-29 07:01:14mobile.png",
    is_sell: true,
    is_buy: true,
    is_active: true,
    is_coming_soon: false,
    order: 1,
    parent_id: null,
    has_child: true,
    product_with_max_price: [],
  },
  {
    id: 2,
    slug: "laptops",
    isPicea: false,
    name: {
      en: "Laptops",
    },
    description: {
      en: "Find great deals on high-quality used Laptop at our online store. Choose from a wide selection of popular brands and models, all fully tested and certified to ensure maximum performance and reliability. Shop now and save big on your next smartphone upgrade!",
    },
    image_path: "/products/product_type_images/2022-08-29 07:01:42laptop.png",
    is_sell: true,
    is_buy: true,
    is_active: true,
    is_coming_soon: false,
    order: 2,
    parent_id: null,
    has_child: true,
    product_with_max_price: [],
  },
  {
    id: 3,
    slug: "tablets",
    isPicea: true,
    name: {
      en: "Tablets",
    },
    description: {
      en: "Find great deals on high-quality Tablet at our online store. Choose from a wide selection of popular brands and models, all fully tested and certified to ensure maximum performance and reliability. Shop now and save big on your next smartphone upgrade!",
    },
    image_path: "/products/product_type_images/2022-08-29 07:01:57tablet.png",
    is_sell: true,
    is_buy: false,
    is_active: true,
    is_coming_soon: false,
    order: 3,
    parent_id: null,
    has_child: true,
    product_with_max_price: [],
  },
  {
    id: 4,
    slug: "smart-watches",
    isPicea: false,
    name: {
      en: "Smart Watches",
    },
    description: {
      en: "Find great deals on high-quality used wearable  at our online store. Choose from a wide selection of popular brands and models, all fully tested and certified to ensure maximum performance and reliability. Shop now and save big on your next smartphone upgrade!",
    },
    image_path:
      "/products/product_type_images/2022-08-29 07:04:20unknown (2).png",
    is_sell: true,
    is_buy: true,
    is_active: true,
    is_coming_soon: false,
    order: 4,
    parent_id: null,
    has_child: true,
    product_with_max_price: [],
  },
  {
    id: 5,
    slug: "computer-desktop",
    isPicea: false,
    name: {
      en: "Computer Desktop",
    },
    description: {
      en: "Find great deals on high-quality used Computer Desktop at our online store. Choose from a wide selection of popular brands and models, all fully tested and certified to ensure maximum performance and reliability. Shop now and save big on your next smartphone upgrade!",
    },
    image_path: "/products/product_type_images/2022-10-17 06:59:10tb.png",
    is_sell: false,
    is_buy: true,
    is_active: true,
    is_coming_soon: false,
    order: 5,
    parent_id: null,
    has_child: true,
    product_with_max_price: [],
  },
  {
    id: 6,
    slug: "accessories-1",
    isPicea: false,
    name: {
      en: "Accessories",
    },
    description: {
      en: null,
    },
    image_path: "/categories/category_image/_1684152510_1111.png",
    is_sell: true,
    is_buy: false,
    is_active: true,
    is_coming_soon: false,
    order: 6,
    parent_id: null,
    has_child: true,
    product_with_max_price: [],
  },
];

export const brandList = [
  {
    id: 1,
    name: {
      en: "Apple",
    },
    description: {
      en: "USED APPLE MOBILES LAPTOPS SMARTWATCHES LAPTOP PCS",
    },
    image_path: "/brand/brand_images/2022-08-29 10:36:50apple-logo.jpg",
    slug: "apple",
  },
];

export const methods = [
  { name: "Picea", id: 1 },

  { name: "2USEworkflow", id: 2 },
];

export const staticPrice = {
  condition: {
    id: 3,
    name: "Very Good",
    color: "#74D301",
  },
  price: 1801,
};

export const translations = [
  { en: "", ar: "" },
  { en: "First Name", ar: "الاسم الأول" },
  { en: "Middle Name", ar: "اسم الأب" },
  { en: "Last Name", ar: "اسم العائلة" },
  { en: "Nationality", ar: "الجنسية" },
  { en: "Email", ar: "البريد الإلكتروني" },
  { en: "Contact Number", ar: "" },
  { en: "ID", ar: "الهوية" },
  { en: "Passport Number", ar: "رقم جواز السفر" },
  { en: "Country", ar: "البلد" },
  { en: "City", ar: "المدينة" },
  { en: "Full Address", ar: "العنوان الكامل " },
  { en: "Choose The Text Languag", ar: "اختر لغة النص" },
  { en: "Verify By Email", ar: "" },
  { en: "Verify By OTP", ar: "" },
];

export const paperTypeList = [
  { name: "ID", ar: "الهوية", paper_type: 1 },
  { name: "Passport Number", ar: "رقم جواز السفر", paper_type: 2 },
];

export const piceaData = {
  status: 0,
  errors: [],
  data: {
    number: "137400236802301",
    type: "1793473",
    date: "2024-01-22T12:36:22.106Z",
    grade_code: "A",
    price: "",
    currency_code: "",
    device: {
      type: "",
      group: "",
      manufacturer: "Apple",
      model_name: "iPhone 11 Pro Max",
      configuration: "256",
      attributes: {
        model: "",
        imei: "",
        serial_number: "",
        color: "",
        capacity: 256,
        memory: "",
        os: "",
        os_version: "",
        image:
          "https://api.piceasoft.com/deviceinfo/v1/get_image?uid=8d987d84-581a-4cae-b159-82f6b26f7b51",
      },
    },
    operations: [
      {
        uid: "8279716e-36ab-4b02-9cc8-72d9a99411bf",
        type: 12,
        timestamp: "2024-01-22T14:36:20Z",
        utc_offset: 7200,
        status: 1,
        error_code: 0,
        duration: 0,
        session_id: "0047ee24-cc41-4888-9c2c-e7591306ba7f",
        session_details: {
          type: 11,
          sequence_number: 1,
          is_last: false,
          channel_type: 1,
        },
      },
      {
        type: 13,
        type_string: "CUSTOM_QUESTIONS",
        timestamp: "2024-01-22T14:36:24Z",
        utc_offset: 0,
        status: 1,
        error_code: 0,
        duration: 2,
        custom_questions_details: {
          answers: [
            {
              id: "List_1",
              question: "Carrier and iCloud status",
              answer: [
                {
                  id: "0",
                  value: "Unlocked",
                  stop: false,
                },
              ],
            },
            {
              id: "List_2",
              question: "Housing Condition",
              answer: [
                {
                  id: "0",
                  value: "No scratches",
                  stop: false,
                },
              ],
            },
            {
              id: "List_3",
              question: "Battery Health",
              answer: [
                {
                  id: "0",
                  value: "Like New",
                  stop: false,
                },
              ],
            },
            {
              id: "List_4",
              question: "Hardware",
              answer: [
                {
                  id: "0",
                  value: "Like New",
                  stop: false,
                },
              ],
            },
          ],
        },
        uid: "0047ee24-cc41-4888-9c2c-e7591306ba7f",
        session_id: "0047ee24-cc41-4888-9c2c-e7591306ba7f",
        session_details: {
          type: 11,
          sequence_number: 2,
          is_last: false,
          channel_type: 1,
        },
      },
    ],
    serviceType: "Trade-in",
  },
  signature: {
    data: {
      data: "7451ece464119ceb6a985686e684610614fb25eb2fe3da3f2261a179dca94621",
      successed: true,
      errors: null,
    },
    successed: true,
  },
};

export const productStatic = {
  id: 180,
  slug: "iphone-14-pro",
  name: {
    en: "iPhone 14 Pro",
  },
  description: {
    en: "Used iPhone",
  },
  base_price: "2320",
  order: 1,
  brand_id: 1,
  category_id: 30,
  max_price: 3280,
  product_image: [
    {
      id: 203,
      image_path: "/model/model_images/2022-11-29 14:14:01iphone 14 pro.jpg",
      product_id: 180,
    },
  ],
};
export const attributes = {
  id: 1,
  name: {
    en: "Capacity",
  },
  is_estimation_factor: true,
  order: 1,
  attribute_value: [
    {
      id: 5,
      name: {
        en: "128 GB",
      },
      attribute_id: 1,
      order: 5,
    },
    {
      id: 6,
      name: {
        en: "256 GB",
      },
      attribute_id: 1,
      order: 6,
    },
    {
      id: 7,
      name: {
        en: "512 GB",
      },
      attribute_id: 1,
      order: 7,
    },
    {
      id: 8,
      name: {
        en: "1 TB",
      },
      attribute_id: 1,
      order: 8,
    },
  ],
};

export const Country = {
  id: 118,
  name: "Lebanon",
  country_code: "LB",
  dial_code: "+961",
  latitude: "33.854721",
  longitude: "35.862285",
  currency_id: 62,
  currency: {
    id: 62,
    code: "LBP",
    name: "Lebanese Pound",
    symbol: "LB£",
    is_hidden: true,
  },
};

export const colors = [
  { label: "Mint", color: "#11AEE0A0", background: "#E9F5FF" },
  { label: "Excellent", color: "#11AEE0", background: "#E9F5FF" },
  { label: "Very Good", color: "#74D301", background: "#E2FFBF" },
  { label: "Good", color: "#FFC700", background: "#FFF2C0" },
  { label: "Fair", color: "#EF8800", background: "#FFEBD0" },
  { label: "Broken but Working", color: "red", background: "#FFDFD5" },
  { label: "Does not turn on", color: "purple", background: "#ECDAFF" },
];

export const deviceCondition = { name: "Very Good", id: "1" };
export const values = [
  {
    title: "Product Type",
    value: "Mobile",
  },
  {
    title: "Brand",
    value: "Apple",
  },
  {
    title: "Category",
    value: "iphone 14",
  },
  {
    title: "Model",
    value: "iphone 14 pro",
  },
  {
    title: "Condition",
    value: "Minit",
  },
];

export const steps = [
  {
    label: "Verification",
    activeicon: <img src={Verificationimage} className="stepperIcon" />,
    icon: <img src={Verificationimage} className="stepperIcon" />,
  },
  {
    label: "Fill Form",
    activeicon: <img src={FillFormimageON} className="stepperIcon" />,
    icon: <img src={FillFormimageOff} className="stepperIcon" />,
  },
  {
    label: "Done",
    activeicon: <img src={DoneimageON} className="stepperIcon" />,
    icon: <img src={DoneimageOff} className="stepperIcon" />,
  },
];

export const Fullsteps = [
  {
    label: "Verification",
    activeicon: <img src={Verificationimage} className="stepperIcon" />,
    icon: <img src={Verificationimage} className="stepperIcon" />,
  },
  {
    label: "Fill Form",
    activeicon: <img src={FillFormimageON} className="stepperIcon" />,
    icon: <img src={FillFormimageOff} className="stepperIcon" />,
  },
  {
    label: "Employee Details",
    activeicon: <img src={EmployeeInfoimageON} className="stepperIcon" />,
    icon: <img src={EmployeeInfoimageOff} className="stepperIcon" />,
  },
  {
    label: "Done",
    activeicon: <img src={DoneimageON} className="stepperIcon" />,
    icon: <img src={DoneimageOff} className="stepperIcon" />,
  },
];
export const notifications = [
  {
    id: 1,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "Today",
    isRead: true,
  },
  {
    id: 2,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "Yestarday",
    isRead: true,
  },
  {
    id: 3,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2 days ago",
    isRead: false,
  },
  {
    id: 4,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-15",
    isRead: false,
  },

  {
    id: 5,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-14",
    isRead: false,
  },

  {
    id: 6,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-1",
    isRead: false,
  },
  {
    id: 5,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-14",
    isRead: false,
  },

  {
    id: 6,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-1",
    isRead: false,
  },
  {
    id: 5,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-14",
    isRead: false,
  },

  {
    id: 6,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-1",
    isRead: false,
  },

  {
    id: 5,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-14",
    isRead: false,
  },

  {
    id: 6,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-1",
    isRead: false,
  },
  {
    id: 5,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-14",
    isRead: false,
  },

  {
    id: 6,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-1",
    isRead: false,
  },
  {
    id: 5,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-14",
    isRead: false,
  },

  {
    id: 6,
    details:
      "Lorem ipsum dolor sit amet. Id praesentium reprehenderit non mollitia galisum qui deleniti veniam sit sequi harum.",
    date: "2024-03-1",
    isRead: false,
  },
];

export const ColorCode = [
  {
    name: "Red",
    code: "#FF0000",
  },
  {
    name: "Green",
    code: "#008000",
  },
  {
    name: "Blue",
    code: "#0000FF",
  },
  {
    name: "Yellow",
    code: "#FFFF00",
  },
  {
    name: "Purple",
    code: "#800080",
  },
  {
    name: "Orange",
    code: "#FFA500",
  },
  {
    name: "Pink",
    code: "#FFC0CB",
  },
  {
    name: "Cyan",
    code: "#00FFFF",
  },
  {
    name: "Magenta",
    code: "#FF00FF",
  },
  {
    name: "Lime",
    code: "#00FF00",
  },
  {
    name: "Black",
    code: "#000000",
  },
  {
    name: "Gold",
    code: "#FFBF00",
  },
  {
    name: "White",
    code: "#f3e5ab",
  },
];
