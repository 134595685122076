import React, { useEffect, useState } from "react";
import "./Disclaimer.scss";
import TransactionDetails from "./transaction-details/TransactionDetails";
import background from "../../assests/Images/2useBackground.png";
import { LogoWithTextSVG } from "../../assests/icons/SVG";
import moment from "moment";
import Image from "../../components/image/Image";
import { useGlobalValues } from "../../context/GlobalContext";

export const Disclaimer = React.forwardRef((props, ref) => {
  const {
    data,
    arabic,
    children,
    employeeDetails,
    serialNumber,
    isPicea,
    isWiping,
    imeiNo,
    events,
    diagnositicData,
    piceaDetails,
    verifyDetails,
  } = props;
  const { buyer_name, product_name, nationality } = data?.disclaimer || {};
  const { passport_number, date, seller_name } = data?.disclaimer || {};
  const marginTop = "25px";
  const marginBottom = "25px";
  const marginLeft = "0";
  const marginRight = "5%";
  const getPageMargins = () => {
    return `@page { margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft} !important;
                    size: A4;}`;
  };

  const { Oragnizationimages } = useGlobalValues();
  const [piceaDDetails, setpiceaDetails] = useState();
  const [diagnositicDetails, setdiagnositicDetails] = useState();
  const [verifyDDetails, setverifyDetails] = useState();
  const [loading, setloading] = useState(false);

  const handleData = () => {
    if (data && data?.transaction_details) {
      const piceaObject = JSON.parse(
        data?.transaction_details?.picea_transaction_details
      );
      let diagnositicData = piceaObject?.events.filter(
        (item) => item.title === "Diagnostics"
      );

      let verifyData = piceaObject?.events.filter((item) =>
        item.title.includes("Verify")
      );
      setpiceaDetails(piceaObject);

      const updatedArray = diagnositicData?.map((item) => {
        const match =
          item?.description.match(/\bfailed\b/i) ||
          item?.description.match(/\passed\b/i) ||
          item?.description.toLowerCase().match(/\ Broken but Working\b/i);

        const result = match ? match[0] : null;

        const matchh = item?.description.match(
          /^(.*?)(?: passed| failed| Result| $)(.*)/i
        );
        const testName = matchh ? matchh[1].trim() : item?.description?.trim();

        // Update the item with separate test and result properties
        return {
          ...item,
          test: testName,
          result: result,
        };
      });

      setdiagnositicDetails(updatedArray);

      const upddateVerifyArray = verifyData?.map((item) => {
        const match =
          item.description.toLowerCase().match(/\bfailed\b/i) ||
          item.description.toLowerCase().match(/\bsuccess\b/i);

        const result = match ? match[0] : null;

        const matchh = item?.description
          .toLowerCase()
          .match(/^(.*?)(?: success| failed| $)(.*)/i);
        const testName = matchh ? matchh[1].trim() : item?.description?.trim();

        // Update the item with separate test and result properties
        return {
          ...item,
          test: testName,
          result: result,
        };
      });
      setverifyDetails(upddateVerifyArray);
    }
    setloading(false);
  };

  useEffect(() => {
    handleData();
  }, [data]);

  return (
    <div ref={ref} className="disclaimer">
      <table>
        <thead>
          <tr>
            <td>
              <div className="header-space" />
            </td>
          </tr>
          <tr>
            <td>
              <div className="titlespace"> {props.header}</div>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <main>
                <style>{getPageMargins()}</style>
                {children ? (
                  children
                ) : (
                  <>
                    {!arabic ? (
                      <div className="disclaimerText">
                        <p>
                          I, the undersigned <b>/{seller_name}/</b>,citizen of
                          (nationality)
                          <b>{nationality}</b>, holder of passport (or identity
                          card) Number
                          <b> /{passport_number}/</b>,
                        </p>
                        <br />
                        <p>
                          Hereby irrevocably confirm, warrant and acknowledge
                          the following:
                        </p>
                        <br />
                        <ol id="l1">
                          <li data-list-text={1}>
                            <p>
                              I am the sole owner of the device
                              <b> {product_name} </b>
                              (the “<b>Device</b>”) and the data contained
                              therein (the “<b>Data</b>”) and I have the right,
                              title and interest to perform the sale of the
                              Device in the terms and conditions I deem
                              appropriate.
                            </p>
                          </li>
                          <li data-list-text={2}>
                            <p>
                              I hereby acknowledge that I sold it to{" "}
                              <b>{buyer_name}</b> (the “<b>Buyer</b>”) and
                              subsequently, I renounce any and all right, title
                              or interest, present or future in the Device
                              and/or in the Data.
                            </p>
                          </li>
                          <li data-list-text={3}>
                            <p>
                              The information, data and specifications related
                              to the Device provided to the Buyer are accurate
                              and complete and contain no known or suspected
                              material inaccuracy.
                            </p>
                          </li>
                          <li data-list-text={4}>
                            <p>
                              The Purchase Price of the Device has been fully
                              paid to me and I hereby discharge the Buyer of any
                              claim or right in relation thereto.
                            </p>
                          </li>
                          <li data-list-text={5}>
                            <p>
                              As of the date hereof, the Buyer became the lawful
                              owner of the Device and has the legal capacity and
                              authority to use, sell or dispose of the Device in
                              any way.
                            </p>
                          </li>
                          <li data-list-text={6}>
                            <p>
                              The Buyer shall have the right to delete the Data
                              and is fully discharged from any claim or demand
                              or the like, present or future relating directly
                              or indirectly to the sale of the Device as well as
                              any claim resulting from deleting the Data
                              contained in the Device.
                            </p>
                          </li>
                          <li data-list-text={7}>
                            <p>
                              I waive any right or interest I may have at any
                              time to contest or otherwise challenge the
                              validity or legality of this deed.
                            </p>
                          </li>
                        </ol>
                        <br />
                        <h4>
                          Name: <b>{seller_name}</b>
                        </h4>
                        <br />
                        <h4>
                          Date: {moment(date).locale("en").format("YYYY-MM-DD")}
                        </h4>
                        <br />
                        {isWiping ? (
                          <h4>Signiture:</h4>
                        ) : (
                          <h4 style={{ pageBreakAfter: "always" }}>
                            Signiture:
                          </h4>
                        )}

                        <br />
                      </div>
                    ) : (
                      <div
                        className="disclaimerText"
                        style={{ direction: "rtl" }}
                      >
                        <p>
                          أنا الموقع/ة أدناه <b>/{seller_name}/</b>, من الجنسية
                          <b> {nationality} </b> وحامل/ة جواز السفر (أو الهوية
                          رقم البطاقة
                          <b>{passport_number}</b>,
                        </p>
                        <br />
                        <p>
                          نؤكد بموجب هذا بشكل لا رجوع فيه ، ونضمن ونقر بما يلي
                        </p>
                        <br />
                        <ol id="l1">
                          <li data-list-text={1}>
                            <p>
                              أنا المالك/ة الوحيد/ة للجهاز{" "}
                              <b>{product_name} </b>
                              <b>الجهاز</b> والبيانات الواردة فيه ("البيانات")
                              ولدي الحق والملكية والمصلحة لأداء بيع الجهاز
                              بالشروط والأحكام التي أراها مناسبة.
                            </p>
                          </li>
                          <li data-list-text={2}>
                            <p>
                              أقر بموجب هذا بأنني بعته إلى <b>{buyer_name} </b>
                              <b>المشتري</b> وبعد ذلك ، أتخلى عن أي وجميع الحقوق
                              أو الملكية أو المصلحة الحالية أو المستقبلية في
                              الجهاز و / أو في البيانات.
                            </p>
                          </li>
                          <li data-list-text={3}>
                            <p>
                              III. المعلومات والبيانات والمواصفات المتعلقة
                              بالجهاز والمقدمة للمشتري دقيقة وكاملة ولا تحتوي
                              على معلومات غير دقيقة أو مشتبه بها.
                            </p>
                          </li>
                          <li data-list-text={4}>
                            <p>
                              د تم دفع سعر شراء الجهاز بالكامل لي ، وأنا بموجب
                              هذا أبرئ المشتري من أي مطالبة أو حق متعلق بذلك
                            </p>
                          </li>
                          <li data-list-text={5}>
                            <p>
                              V. اعتبارًا من تاريخ هذا القانون ، أصبح المشتري هو
                              المالك الشرعي للجهاز ولديه الأهلية والسلطة
                              القانونية لاستخدام الجهاز أو بيعه أو التخلص منه
                              بأي شكل من الأشكال.
                            </p>
                          </li>
                          <li data-list-text={6}>
                            <p>
                              V. اعتبارًا من تاريخ هذا القانون ، أصبح المشتري هو
                              المالك الشرعي للجهاز ولديه الأهلية والسلطة
                              القانونية لاستخدام الجهاز أو بيعه أو التخلص منه
                              بأي شكل من الأشكال.
                            </p>
                          </li>
                          <li data-list-text={7}>
                            <p>
                              V. اعتبارًا من تاريخ هذا القانون ، أصبح المشتري هو
                              المالك الشرعي للجهاز ولديه الأهلية والسلطة
                              القانونية لاستخدام الجهاز أو بيعه أو التخلص منه
                              بأي شكل من الأشكال.
                            </p>
                          </li>
                        </ol>
                        <br />
                        <h4>
                          اسم: <b>{seller_name}</b>
                        </h4>
                        <br />
                        <h4>تاريخ: {moment(date).locale("en").format("YYYY-MM-DD")}</h4>
                        <br />
                        {isWiping ? (
                          <h4>التوقيع:</h4>
                        ) : (
                          <h4 style={{ pageBreakAfter: "always" }}>التوقيع:</h4>
                        )}

                        <br />
                      </div>
                    )}

                    {!isWiping ? (
                      <>
                        {isPicea ? (
                          <TransactionDetails
                            data={
                              data?.["transaction"] ||
                              data?.["transaction_details"] ||
                              data
                            }
                            disclaimer={data?.disclaimer}
                            diagnositicData={diagnositicDetails}
                            piceaDetails={piceaDDetails}
                            verifyDetails={verifyDDetails}
                          />
                        ) : (
                          !props.noTransaction && (
                            <TransactionDetails
                              data={
                                data?.["transaction"] ||
                                data?.["transaction_details"] ||
                                data
                              }
                              disclaimer={data?.disclaimer}
                              serialNumber={serialNumber}
                              imeiNo={imeiNo}
                              diagnositicData={
                                diagnositicData ? diagnositicData : ""
                              }
                              piceaDetails={piceaDetails ? piceaDetails : ""}
                              verifyDetails={verifyDetails ? verifyDetails : ""}
                            />
                          )
                        )}
                      </>
                    ) : null}
                  </>
                )}
              </main>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="footer-space"> </div>
            </td>
          </tr>
        </tfoot>
      </table>
      <header>
        <LogoWithTextSVG className="LogoWithTextSVG" />
        <Image src={Oragnizationimages?.logo} alt="logo"  className="comp_logo_disc" />
      </header>
      {/* <footer>
        <LogoWithTextSVG className="LogoWithTextSVG" />
      </footer> */}
      <img alt="background" src={background} className="watermark" />
    </div>
  );
});
Disclaimer.defaultProps = {
  header: <h1>Disclaimer</h1>,
};
