import React from "react";
import { Pagination } from "@mui/material";
import "./Paginator.scss";
import { useSearchParams } from "react-router-dom";
import { groupParamsByKey } from "../../core/functions/Functions";
import { useGlobalLangValues } from "../../context/LanguageContext";

const Paginator = (props) => {
  const { setRefetch } = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const handlePagination = (_, value) => {
    setSearchParams({ ...groupParamsByKey(searchParams), page: value });
    setRefetch && setRefetch();
  };
  const { isArabic } = useGlobalLangValues();

  return (
    <div
      className={`paginator-section  ${
        isArabic ? "justify-content-start-pag" : ""
      }`}
    >
      <Pagination
        className="paginator"
        color="primary"
        page={+searchParams.get("page") || 1}
        onChange={handlePagination}
        {...props}
      />
    </div>
  );
};

export default Paginator;
