import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./Login.scss";
import Button from "../../components/button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { IconButton, InputAdornment } from "@mui/material";
import { Input } from "../../components/input/Input";
import { useAuth } from "../../hooks/useAuth";
import { LockOutlined, MailOutline } from "@mui/icons-material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LogoSVG } from "../../assests/icons/SVG";
import { FormCheckBox } from "../../components/formComponents/FormComponents";
import CryptoJS from "crypto-js";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { useGlobalLangValues } from "../../context/LanguageContext";

const LoginComponent = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  const [loadingvalues, setloadingvalues] = useState(true);
  const [cookies, setCookie] = useCookies(["fd7e31fe36c9", "BPbea8eb5"]);

  const { isArabic } = useGlobalLangValues();

  const { t } = useTranslation();

  const loginSchema = yup.object({
    email: yup
      .string(t("Enter your email"))
      .email(t("Enter a valid email"))
      .required(t("Email is required")),
    password: yup
      .string(t("Enter your password"))
      .min(6, t("Password should be of minimum 6 characters length"))
      .required(t("Password is required")),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: "",
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      const data = {
        user_type_id: 2,
        email: values.email,
        password: values.password,
      };
      setLoading(true);

      login(data).then((res) => {
        if (res?.data?.data && formik.values.rememberMe) {
          const encrypted = CryptoJS.AES.encrypt(
            formik.values.password,
            "random-characters"
          ).toString();

          const encryptedEmail = CryptoJS.AES.encrypt(
            formik.values.email,
            "random-characters"
          ).toString();

          setCookie("fd7e31fe36c9", encryptedEmail, {
            expires: new Date("9999-12-31T23:59:59"),
          });
          setCookie("BPbea8eb5", encrypted, {
            expires: new Date("9999-12-31T23:59:59"),
          });
        } else {
          setCookie("fd7e31fe36c9", "");
          setCookie("BPbea8eb5", "");
        }
        setLoading(false);
      });
    },
  });

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const checkSavedCredentials = () => {
    const savedEmail = cookies.fd7e31fe36c9;
    const savedPassword = cookies.BPbea8eb5;

    if (savedEmail && savedPassword) {
      const decrypted = CryptoJS.AES.decrypt(
        savedPassword,
        "random-characters"
      ).toString(CryptoJS.enc.Utf8);

      const decryptedEmail = CryptoJS.AES.decrypt(
        savedEmail,
        "random-characters"
      ).toString(CryptoJS.enc.Utf8);

      formik.values.email = decryptedEmail;
      formik.values.password = decrypted;
      formik.values.rememberMe = true;
      setloadingvalues(false);
    } else {
      setloadingvalues(false);
    }
  };

  useEffect(() => {
    checkSavedCredentials();
    inputRef?.current?.focus();
  }, []);

  return loadingvalues ? null : (
    <div className="main-login-body">
      <div className={isArabic ? "LoginPage arabic-component" : "LoginPage"}>
        <div className="wrap1">
          <h1> {t("Retailer Log In")}</h1>
          <form className="loginForm form" onSubmit={formik.handleSubmit}>
            <Input
              className={`curvedInput ${isArabic ? "arabic-input" : ""}`}
              variant="outlined"
              type="email"
              placeholder={t("Enter Email")}
              name="email"
              id="email"
              ref={inputRef}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              InputProps={{
                startAdornment: isArabic ? undefined : (
                  <InputAdornment position="start">
                    <MailOutline style={{ color: "#4BA4F0" }} />
                  </InputAdornment>
                ),
                endAdornment: isArabic ? (
                  <InputAdornment position="end">
                    <MailOutline
                      style={{ color: "#4BA4F0", marginLeft: "15px" }}
                    />
                  </InputAdornment>
                ) : undefined,
              }}
            />
            <Input
              className={`curvedInput ${isArabic ? "arabic-input" : ""}`}
              type={showPassword ? "text" : "password"}
              placeholder={t("password")}
              name="password"
              id="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
              InputProps={{
                startAdornment: isArabic ? (
                  <InputAdornment position="start">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <InputAdornment position="start">
                    <LockOutlined style={{ color: "#4BA4F0" }} />
                  </InputAdornment>
                ),
                endAdornment: isArabic ? (
                  <InputAdornment position="end">
                    <LockOutlined style={{ color: "#4BA4F0" }} />
                  </InputAdornment>
                ) : (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className="rememberMe">
              <FormCheckBox
                name="rememberMe"
                id="rememberMe"
                value={formik.values.rememberMe}
                label={t("Remember Me")}
                checked={formik.values.rememberMe}
                onChange={(e) => {
                  formik.setFieldValue("rememberMe", e);
                }}
              />

              <Link className="forgotPassword" to="/forgot-password">
                {t("Forgot password")}
              </Link>
            </div>
            <Button
              name={t("Log In")}
              width="100%"
              selected
              loading={loading}
              disabled={loading}
              type="submit"
            />
          </form>
        </div>
        <div className={isArabic ? "wrap2 wrapArabic" : "wrap2"}>
          <LogoSVG className="logo" />
        </div>
      </div>
    </div>
  );
};
export default LoginComponent;
