export const PrepaidSVG = (props) => (
  <svg
    width="84"
    height="120"
    viewBox="0 0 84 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M57.2727 85.9091H9.54546V19.0909H57.2727M57.2727 0H9.54546C4.24773 0 0 4.24773 0 9.54545V95.4546C0 97.9862 1.00568 100.414 2.7958 102.204C4.58592 103.994 7.01384 105 9.54546 105H57.2727C59.8043 105 62.2323 103.994 64.0224 102.204C65.8125 100.414 66.8182 97.9862 66.8182 95.4546V9.54545C66.8182 4.24773 62.5227 0 57.2727 0Z"
      fill="#041C3F"
    />
    <path
      d="M45.75 82.5C44.7554 82.5 43.8016 82.8951 43.0983 83.5983C42.3951 84.3016 42 85.2554 42 86.25V93.75C44.0812 93.75 45.75 95.4375 45.75 97.5C45.75 98.4946 45.3549 99.4484 44.6516 100.152C43.9484 100.855 42.9946 101.25 42 101.25V108.75C42 109.745 42.3951 110.698 43.0983 111.402C43.8016 112.105 44.7554 112.5 45.75 112.5H75.75C76.7446 112.5 77.6984 112.105 78.4016 111.402C79.1049 110.698 79.5 109.745 79.5 108.75V101.25C78.5054 101.25 77.5516 100.855 76.8483 100.152C76.1451 99.4484 75.75 98.4946 75.75 97.5C75.75 95.4187 77.4375 93.75 79.5 93.75V86.25C79.5 84.1688 77.8125 82.5 75.75 82.5H45.75Z"
      fill="#E9F5FF"
      stroke="url(#paint0_linear_3439_8285)"
      stroke-width="3"
    />
    <line
      x1="43.5001"
      y1="105.5"
      x2="78.0001"
      y2="105.5"
      stroke="url(#paint1_linear_3439_8285)"
      stroke-width="2"
      stroke-dasharray="2 2"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3439_8285"
        x1="84.3669"
        y1="119.019"
        x2="51.7791"
        y2="75.2235"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#71E4AC" />
        <stop offset="0.615025" stopColor="#55B4DF" />
        <stop offset="1" stopColor="#4BA4F0" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3439_8285"
        x1="82.4777"
        y1="107.717"
        x2="82.4166"
        y2="105.451"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#71E4AC" />
        <stop offset="0.615025" stopColor="#55B4DF" />
        <stop offset="1" stopColor="#4BA4F0" />
      </linearGradient>
    </defs>
  </svg>
);
export const PostpaidSVG = (props) => (
  <svg
    width="84"
    height="120"
    viewBox="0 0 84 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M57.2727 85.9091H9.54546V19.0909H57.2727M57.2727 0H9.54546C4.24773 0 0 4.24773 0 9.54545V95.4546C0 97.9862 1.00568 100.414 2.7958 102.204C4.58592 103.994 7.01384 105 9.54546 105H57.2727C59.8043 105 62.2323 103.994 64.0224 102.204C65.8125 100.414 66.8182 97.9862 66.8182 95.4546V9.54545C66.8182 4.24773 62.5227 0 57.2727 0Z"
      fill="#041C3F"
    />
    <rect x="48.375" y="91.875" width="26.25" height="20.625" fill="white" />
    <path
      d="M55.875 95.625V99.375H52.125V95.625H55.875ZM63.375 95.625V99.375H59.625V95.625H63.375ZM70.875 95.625V99.375H67.125V95.625H70.875ZM74.625 82.5C75.6196 82.5 76.5734 82.8951 77.2766 83.5983C77.9799 84.3016 78.375 85.2554 78.375 86.25V112.5C78.375 113.495 77.9799 114.448 77.2766 115.152C76.5734 115.855 75.6196 116.25 74.625 116.25H48.375C46.2937 116.25 44.625 114.562 44.625 112.5V86.25C44.625 85.2554 45.0201 84.3016 45.7233 83.5983C46.4266 82.8951 47.3804 82.5 48.375 82.5H50.25V78.75H54V82.5H69V78.75H72.75V82.5H74.625ZM74.625 112.5V91.875H48.375V112.5H74.625ZM55.875 103.125V106.875H52.125V103.125H55.875ZM63.375 103.125V106.875H59.625V103.125H63.375ZM70.875 103.125V106.875H67.125V103.125H70.875Z"
      fill="url(#paint0_linear_3439_8284)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3439_8284"
        x1="82.7552"
        y1="124.399"
        x2="40.2508"
        y2="83.2705"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#71E4AC" />
        <stop offset="0.615025" stopColor="#55B4DF" />
        <stop offset="1" stopColor="#4BA4F0" />
      </linearGradient>
    </defs>
  </svg>
);
export const BackgroundSVG = (props) => (
  <svg
    width="332"
    height="227"
    viewBox="0 0 332 227"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g opacity="0.05">
      <path
        d="M256.575 -32.8605C233.366 -32.8605 210.132 -32.8605 186.922 -32.8605C186.41 -32.8605 185.922 -32.9217 185.726 -32.9217C204.152 -48.3603 222.676 -63.8398 241.176 -79.2988C241.176 -71.9568 241.176 -64.5128 241.176 -56.926H240.321H240.102C226.093 -56.0898 220.675 -47.4834 220.162 -46.6064H227.875C227.997 -46.7288 228.119 -46.8307 228.265 -46.9531C228.387 -47.0551 228.485 -47.157 228.607 -47.259C228.729 -47.361 228.875 -47.4834 229.022 -47.5853C229.754 -48.1564 230.608 -48.7274 231.633 -49.2577C231.731 -49.2985 231.804 -49.3597 231.901 -49.4004C232.341 -49.6248 232.804 -49.8491 233.317 -50.0531C233.439 -50.1143 233.561 -50.155 233.707 -50.2162C234.22 -50.3998 234.732 -50.5833 235.294 -50.7465C235.757 -50.8892 236.246 -51.0116 236.758 -51.1136C236.783 -51.1136 236.831 -51.134 236.856 -51.134C238.1 -51.4195 239.394 -51.6438 240.883 -51.7254H258.113C258.43 -51.705 258.747 -51.6642 259.065 -51.6234C265.654 -50.7669 269.901 -46.0557 268.705 -40.4676C267.729 -35.8993 262.945 -32.8605 256.575 -32.8605ZM248.033 -3.28841V18.7377C266.508 3.29903 285.008 -12.16 303.458 -27.5783C303.287 -27.5783 302.848 -27.5783 302.384 -27.5783C279.053 -27.5783 255.697 -27.5783 232.365 -27.5783C224.263 -27.5783 218.869 -22.3573 220.455 -15.8922C221.383 -12.0988 226.239 -8.97848 230.876 -8.81532C232.804 -8.75414 234.732 -8.71336 236.661 -8.73375H265.142L258.699 -3.3292L248.033 -3.28841Z"
        fill="#4BA4F0"
      />
      <path
        d="M199.897 14.745C176.687 14.745 153.453 14.745 130.244 14.745C129.731 14.745 129.243 14.6838 129.048 14.6838C147.474 -0.754852 165.998 -16.2343 184.497 -31.6934C184.497 -24.3513 184.497 -16.9073 184.497 -9.32056H183.643H183.423C169.414 -8.48438 163.996 0.122114 163.484 0.999081H171.196C171.318 0.876713 171.44 0.774734 171.587 0.652367C171.709 0.550396 171.806 0.448425 171.928 0.346451C172.05 0.244478 172.197 0.122112 172.343 0.0201397C173.075 -0.550907 173.929 -1.12196 174.955 -1.65221C175.052 -1.693 175.125 -1.75418 175.223 -1.79498C175.662 -2.01931 176.126 -2.24366 176.638 -2.4476C176.761 -2.50879 176.883 -2.54957 177.029 -2.61076C177.541 -2.79431 178.054 -2.97786 178.615 -3.14102C179.079 -3.28378 179.567 -3.40615 180.08 -3.50812C180.104 -3.50812 180.153 -3.52851 180.177 -3.52851C181.422 -3.81403 182.715 -4.03837 184.204 -4.11995H201.434C201.752 -4.09956 202.069 -4.05877 202.386 -4.01798C208.976 -3.16141 213.222 1.54973 212.026 7.13783C211.05 11.7062 206.267 14.745 199.897 14.745ZM191.355 44.3171V66.3432C209.83 50.9045 228.329 35.4454 246.78 20.0272C246.609 20.0272 246.17 20.0272 245.706 20.0272C222.374 20.0272 199.018 20.0272 175.687 20.0272C167.584 20.0272 162.19 25.2482 163.777 31.7132C164.704 35.5066 169.561 38.627 174.198 38.7901C176.126 38.8513 178.054 38.8921 179.982 38.8717H208.463L202.02 44.2763L191.355 44.3171Z"
        fill="#4BA4F0"
      />
      <path
        d="M387.439 175.268C364.23 175.268 340.996 175.268 317.786 175.268C317.274 175.268 316.786 175.207 316.59 175.207C335.016 159.769 353.54 144.289 372.04 128.83C372.04 136.172 372.04 143.616 372.04 151.203H371.185H370.966C356.957 152.039 351.539 160.646 351.026 161.523H358.739C358.861 161.4 358.983 161.298 359.129 161.176C359.251 161.074 359.349 160.972 359.471 160.87C359.593 160.768 359.739 160.646 359.886 160.544C360.618 159.973 361.472 159.401 362.497 158.871C362.595 158.83 362.668 158.769 362.765 158.728C363.205 158.504 363.668 158.28 364.181 158.076C364.303 158.015 364.425 157.974 364.571 157.913C365.084 157.729 365.596 157.546 366.158 157.382C366.622 157.24 367.11 157.117 367.622 157.015C367.647 157.015 367.695 156.995 367.72 156.995C368.964 156.709 370.258 156.485 371.747 156.403H388.977C389.294 156.424 389.611 156.465 389.929 156.505C396.518 157.362 400.765 162.073 399.569 167.661C398.593 172.23 393.809 175.268 387.439 175.268ZM378.897 204.84V226.867C397.372 211.428 415.872 195.969 434.322 180.551C434.151 180.551 433.712 180.551 433.248 180.551C409.917 180.551 386.561 180.551 363.229 180.551C355.127 180.551 349.733 185.772 351.319 192.237C352.247 196.03 357.103 199.15 361.74 199.314C363.668 199.375 365.596 199.416 367.525 199.395H396.006L389.563 204.8L378.897 204.84Z"
        fill="#4BA4F0"
      />
      <path
        d="M135.847 -37.2491C112.637 -37.2491 89.4033 -37.2491 66.1937 -37.2491C65.6812 -37.2491 65.193 -37.3103 64.9978 -37.3103C83.4239 -52.749 101.948 -68.2284 120.447 -83.6875C120.447 -76.3455 120.447 -68.9015 120.447 -61.3147H119.593H119.373C105.364 -60.4785 99.9464 -51.872 99.4339 -50.9951H107.146C107.268 -51.1174 107.39 -51.2194 107.537 -51.3418C107.659 -51.4437 107.756 -51.5457 107.878 -51.6477C108 -51.7497 108.147 -51.872 108.293 -51.974C109.025 -52.545 109.879 -53.1161 110.904 -53.6464C111.002 -53.6871 111.075 -53.7483 111.173 -53.7891C111.612 -54.0135 112.076 -54.2378 112.588 -54.4417C112.71 -54.5029 112.832 -54.5437 112.979 -54.6049C113.491 -54.7884 114.004 -54.972 114.565 -55.1352C115.029 -55.2779 115.517 -55.4003 116.03 -55.5023C116.054 -55.5023 116.103 -55.5226 116.127 -55.5226C117.372 -55.8082 118.665 -56.0325 120.154 -56.1141H137.384C137.702 -56.0937 138.019 -56.0529 138.336 -56.0121C144.926 -55.1555 149.172 -50.4444 147.976 -44.8563C147 -40.2879 142.217 -37.2491 135.847 -37.2491ZM127.305 -7.67708V14.349C145.78 -1.08965 164.279 -16.5487 182.73 -31.967C182.559 -31.967 182.12 -31.967 181.656 -31.967C158.324 -31.967 134.968 -31.967 111.637 -31.967C103.534 -31.967 98.1404 -26.746 99.7268 -20.2809C100.654 -16.4875 105.511 -13.3671 110.148 -13.204C112.076 -13.1428 114.004 -13.102 115.932 -13.1224H144.413L137.97 -7.71787L127.305 -7.67708Z"
        fill="#4BA4F0"
      />
      <path
        d="M323.389 123.274C300.179 123.274 276.945 123.274 253.736 123.274C253.223 123.274 252.735 123.213 252.54 123.213C270.966 107.774 289.49 92.295 307.989 76.8359C307.989 84.178 307.989 91.622 307.989 99.2087H307.135H306.915C292.907 100.045 287.489 108.651 286.976 109.528H294.688C294.81 109.406 294.932 109.304 295.079 109.182C295.201 109.08 295.298 108.978 295.42 108.876C295.542 108.774 295.689 108.651 295.835 108.549C296.567 107.978 297.422 107.407 298.447 106.877C298.544 106.836 298.618 106.775 298.715 106.734C299.154 106.51 299.618 106.286 300.131 106.082C300.253 106.021 300.375 105.98 300.521 105.919C301.034 105.735 301.546 105.551 302.108 105.388C302.571 105.246 303.059 105.123 303.572 105.021C303.596 105.021 303.645 105.001 303.669 105.001C304.914 104.715 306.208 104.491 307.696 104.409H324.927C325.244 104.43 325.561 104.471 325.878 104.511C332.468 105.368 336.714 110.079 335.519 115.667C334.542 120.236 329.759 123.274 323.389 123.274ZM314.847 152.846V174.872C333.322 159.434 351.821 143.975 370.272 128.556C370.101 128.556 369.662 128.556 369.198 128.556C345.867 128.556 322.51 128.556 299.179 128.556C291.076 128.556 285.683 133.777 287.269 140.243C288.196 144.036 293.053 147.156 297.69 147.319C299.618 147.381 301.546 147.421 303.474 147.401H331.955L325.512 152.806L314.847 152.846Z"
        fill="#4BA4F0"
      />
      <path
        d="M379.491 76.0145C356.282 76.0145 333.048 76.0145 309.838 76.0145C309.326 76.0145 308.838 75.9533 308.642 75.9533C327.068 60.5147 345.592 45.0352 364.092 29.5762C364.092 36.9182 364.092 44.3622 364.092 51.949H363.237H363.018C349.009 52.7852 343.591 61.3916 343.078 62.2686H350.791C350.913 62.1462 351.035 62.0443 351.181 61.9219C351.303 61.8199 351.401 61.718 351.523 61.616C351.645 61.514 351.791 61.3916 351.938 61.2897C352.67 60.7186 353.524 60.1476 354.549 59.6173C354.647 59.5765 354.72 59.5153 354.817 59.4746C355.257 59.2502 355.72 59.0259 356.233 58.8219C356.355 58.7607 356.477 58.72 356.623 58.6588C357.136 58.4752 357.648 58.2917 358.21 58.1285C358.674 57.9858 359.162 57.8634 359.674 57.7614C359.699 57.7614 359.747 57.741 359.772 57.741C361.016 57.4555 362.31 57.2312 363.799 57.1496H381.029C381.346 57.17 381.663 57.2108 381.981 57.2516C388.57 58.1081 392.817 62.8193 391.621 68.4074C390.645 72.9757 385.861 76.0145 379.491 76.0145ZM370.949 105.587V127.613C389.424 112.174 407.924 96.715 426.374 81.2967C426.203 81.2967 425.764 81.2967 425.3 81.2967C401.969 81.2967 378.613 81.2967 355.281 81.2967C347.179 81.2967 341.785 86.5177 343.371 92.9828C344.299 96.7762 349.155 99.8965 353.792 100.06C355.72 100.121 357.648 100.162 359.577 100.141H388.058L381.615 105.546L370.949 105.587Z"
        fill="#4BA4F0"
      />
      <path
        d="M258.763 71.6259C235.553 71.6259 212.319 71.6259 189.109 71.6259C188.597 71.6259 188.109 71.5647 187.914 71.5647C206.34 56.126 224.863 40.6466 243.363 25.1875C243.363 32.5295 243.363 39.9735 243.363 47.5603H242.509H242.289C228.28 48.3965 222.862 57.003 222.35 57.8799H230.062C230.184 57.7576 230.306 57.6556 230.452 57.5332C230.574 57.4313 230.672 57.3293 230.794 57.2273C230.916 57.1253 231.062 57.003 231.209 56.901C231.941 56.33 232.795 55.7589 233.82 55.2286C233.918 55.1879 233.991 55.1267 234.089 55.0859C234.528 54.8615 234.992 54.6372 235.504 54.4333C235.626 54.3721 235.748 54.3313 235.895 54.2701C236.407 54.0866 236.92 53.903 237.481 53.7398C237.945 53.5971 238.433 53.4747 238.945 53.3727C238.97 53.3727 239.019 53.3524 239.043 53.3524C240.288 53.0668 241.581 52.8425 243.07 52.7609H260.3C260.617 52.7813 260.935 52.8221 261.252 52.8629C267.841 53.7194 272.088 58.4306 270.892 64.0187C269.916 68.5871 265.132 71.6259 258.763 71.6259ZM250.221 101.198V123.224C268.696 107.785 287.195 92.3263 305.645 76.908C305.475 76.908 305.035 76.908 304.572 76.908C281.24 76.908 257.884 76.908 234.552 76.908C226.45 76.908 221.056 82.129 222.643 88.5941C223.57 92.3875 228.427 95.5079 233.064 95.671C234.992 95.7322 236.92 95.773 238.848 95.7526H267.329L260.886 101.157L250.221 101.198Z"
        fill="#4BA4F0"
      />
      <path
        d="M372.901 -24.9991C349.691 -24.9991 326.457 -24.9991 303.248 -24.9991C302.735 -24.9991 302.247 -25.0603 302.052 -25.0603C320.478 -40.499 339.002 -55.9784 357.501 -71.4375C357.501 -64.0955 357.501 -56.6515 357.501 -49.0647H356.647H356.427C342.418 -48.2285 337 -39.622 336.488 -38.7451H344.2C344.322 -38.8674 344.444 -38.9694 344.59 -39.0918C344.712 -39.1937 344.81 -39.2957 344.932 -39.3977C345.054 -39.4997 345.201 -39.622 345.347 -39.724C346.079 -40.295 346.933 -40.8661 347.958 -41.3964C348.056 -41.4371 348.129 -41.4983 348.227 -41.5391C348.666 -41.7635 349.13 -41.9878 349.642 -42.1917C349.764 -42.2529 349.886 -42.2937 350.033 -42.3549C350.545 -42.5384 351.058 -42.722 351.619 -42.8852C352.083 -43.0279 352.571 -43.1503 353.084 -43.2523C353.108 -43.2523 353.157 -43.2726 353.181 -43.2726C354.426 -43.5582 355.719 -43.7825 357.208 -43.8641H374.438C374.756 -43.8437 375.073 -43.8029 375.39 -43.7621C381.98 -42.9056 386.226 -38.1944 385.03 -32.6063C384.054 -28.0379 379.271 -24.9991 372.901 -24.9991ZM364.359 4.57293V26.599C382.834 11.1604 401.333 -4.2987 419.784 -19.717C419.613 -19.717 419.174 -19.717 418.71 -19.717C395.378 -19.717 372.022 -19.717 348.691 -19.717C340.588 -19.717 335.194 -14.496 336.781 -8.0309C337.708 -4.23752 342.565 -1.11715 347.202 -0.953995C349.13 -0.892807 351.058 -0.852028 352.986 -0.872421H381.467L375.024 4.53213L364.359 4.57293Z"
        fill="#4BA4F0"
      />
      <path
        d="M316.72 22.3114C293.511 22.3114 270.277 22.3114 247.067 22.3114C246.555 22.3114 246.067 22.2502 245.871 22.2502C264.297 6.81155 282.821 -8.6679 301.321 -24.127C301.321 -16.7849 301.321 -9.34092 301.321 -1.75415H300.466H300.247C286.238 -0.917974 280.82 7.68852 280.307 8.56549H288.02C288.142 8.44312 288.264 8.34114 288.41 8.21877C288.532 8.1168 288.63 8.01483 288.752 7.91286C288.874 7.81088 289.02 7.68852 289.167 7.58655C289.899 7.0155 290.753 6.44445 291.778 5.91419C291.876 5.8734 291.949 5.81222 292.046 5.77143C292.486 5.54709 292.949 5.32275 293.462 5.1188C293.584 5.05762 293.706 5.01683 293.852 4.95565C294.365 4.7721 294.877 4.58855 295.439 4.42539C295.903 4.28263 296.391 4.16026 296.903 4.05829C296.928 4.05829 296.976 4.0379 297.001 4.0379C298.245 3.75237 299.539 3.52803 301.028 3.44646H318.258C318.575 3.46685 318.892 3.50764 319.21 3.54843C325.799 4.405 330.046 9.11614 328.85 14.7042C327.874 19.2726 323.09 22.3114 316.72 22.3114ZM308.178 51.8835V73.9096C326.653 58.4709 345.153 43.0118 363.603 27.5936C363.432 27.5936 362.993 27.5936 362.529 27.5936C339.198 27.5936 315.842 27.5936 292.51 27.5936C284.408 27.5936 279.014 32.8146 280.6 39.2796C281.528 43.073 286.384 46.1934 291.021 46.3566C292.949 46.4177 294.877 46.4585 296.806 46.4381H325.287L318.844 51.8427L308.178 51.8835Z"
        fill="#4BA4F0"
      />
    </g>
  </svg>
);
export const GiftSVG = (props) => (
  <svg
    width="74"
    height="68"
    viewBox="0 0 74 68"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M27.1999 0.43373C20.8999 0.400397 14.7666 6.46707 17.5666 14.0004H6.99992C5.23181 14.0004 3.53612 14.7028 2.28587 15.953C1.03563 17.2033 0.333252 18.899 0.333252 20.6671V27.3337C0.333252 28.2178 0.684441 29.0656 1.30956 29.6908C1.93468 30.3159 2.78253 30.6671 3.66659 30.6671H33.6666V20.6671H40.3333V30.6671H70.3333C71.2173 30.6671 72.0652 30.3159 72.6903 29.6908C73.3154 29.0656 73.6666 28.2178 73.6666 27.3337V20.6671C73.6666 18.899 72.9642 17.2033 71.714 15.953C70.4637 14.7028 68.768 14.0004 66.9999 14.0004H56.4333C60.3333 3.1004 45.6666 -4.5996 38.8999 4.8004L36.9999 7.33373L35.0999 4.73373C32.9999 1.76706 30.0999 0.467064 27.1999 0.43373ZM26.9999 7.33373C29.9666 7.33373 31.4666 10.9337 29.3666 13.0337C27.2666 15.1337 23.6666 13.6337 23.6666 10.6671C23.6666 9.78301 24.0178 8.93516 24.6429 8.31004C25.268 7.68492 26.1159 7.33373 26.9999 7.33373M46.9999 7.33373C49.9666 7.33373 51.4666 10.9337 49.3666 13.0337C47.2666 15.1337 43.6666 13.6337 43.6666 10.6671C43.6666 9.78301 44.0178 8.93516 44.6429 8.31004C45.268 7.68492 46.1159 7.33373 46.9999 7.33373M3.66659 34.0004V60.6671C3.66659 62.4352 4.36896 64.1309 5.61921 65.3811C6.86945 66.6314 8.56514 67.3337 10.3333 67.3337H63.6666C65.4347 67.3337 67.1304 66.6314 68.3806 65.3811C69.6309 64.1309 70.3333 62.4352 70.3333 60.6671V34.0004H40.3333V60.6671H33.6666V34.0004H3.66659Z"
      fill="#4BA4F0"
    />
  </svg>
);
