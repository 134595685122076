import React, { useEffect, useState } from "react";
import PhoneInput2 from "react-phone-input-2";
import { getAllCountries } from "../../core/apis/countries";
import "./PhoneInput.scss";
import "react-phone-input-2/lib/material.css";
import { FormControl, FormHelperText, InputLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useGlobalLangValues } from "../../context/LanguageContext";
import verifySvg from "../../assests/Images/verify-svg.png";

export const PhoneInput = (props) => {
  const {
    phoneRef,
    onChange,
    value,
    ip,
    className,
    helperText,
    labelType,
    inputlabel,
    inputclassname,
    language,
    arabic,
    fontweight,
    disabledProps,
    verfied,
    borderVal,
    hideinputlabel,
  } = props;
  const { required } = props;

  const [countries, setCountries] = useState([]);
  const [api, setApi] = useState({});

  const { t } = useTranslation();
  const { selectedLanguage, isArabic } = useGlobalLangValues();

  useEffect(() => {
    getAllCountries().then((res) => setCountries(res));
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((response) => setApi(response))
      .catch((data) => console.error("Request failed:", data));
  }, []);

  return (
    <div className={className ? className : ""}>
      {hideinputlabel === false && (
        <InputLabel
          sx={{
            margin: "0 0 8px",
            fontWeight: fontweight || "normal",
            color: "#000",
          }}
          className={`${isArabic ? "arabic" : ""} ${inputclassname}`}
        >
          {inputlabel ? t(inputlabel) : t("Contact Number")}
          {`  ${required ? " * " : ""}`}
          {verfied && <img src={verifySvg} width="25" />}
        </InputLabel>
      )}

      <PhoneInput2
        containerClass="phoneInput"
        inputClass={borderVal ? "inputClass borderInput" : "inputClass"}
        buttonClass={borderVal ? "buttonClass borderInput" : "buttonClass"}
        ref={phoneRef}
        enableSearch
        country={ip && api?.country?.toLowerCase()}
        preferredCountries={["lb", "ae", "qa"]}
        onChange={onChange(countries)}
        value={value}
        enableAreaCodeStretch
        masks={{ lb: ".. ... ...", qa: ".... ...." }}
        countryCodeEditable={false}
        disabled={disabledProps}
      />
      {helperText && (
        <FormControl>
          <FormHelperText style={{color:"#ff0000"}}>{helperText}</FormHelperText>
        </FormControl>
      )}
    </div>
  );
};

PhoneInput.defaultProps = {
  valueCode: {
    country_code: "AE",
    country_name: "United Arab Emirates",
    dial_code: "+971",
    id: 228,
  },
  label: { code: "Code", number: "Contact Number" },
  borderVal: false,
  hideinputlabel: false,
};
