import React, { useState, useEffect } from "react";

import "./Notification.scss"; // Import your CSS file for styling
import Notification from "../../assests/Images/notification.png";

const NotificationComponent = ({ isRead, details, date }) => {
  const notificationClass = isRead
    ? "notification read"
    : "notification unread";

  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  let maxChars = 300;

  return (
    <div className={notificationClass}>
      <div className="icon-container">
        <img src={Notification} alt="Icon" className="icon-bell" />
      </div>
      <div className="details-container">
        <p className="details">
          {expanded ? details : `${details.slice(0, maxChars)}...  `}
          {details.length > maxChars && (
            <span className="showMore" onClick={toggleExpanded}>
              {expanded ? "Show Less" : "Show More"}
            </span>
          )}
        </p>
        <p className="date">{date}</p>
      </div>
    </div>
  );
};

export default NotificationComponent;
