import React, { useContext, useState, useEffect } from "react";
import "./TransactionDetails.scss";
import Barcode from "react-barcode/lib/react-barcode";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useGlobalValues } from "../../../context/GlobalContext";

const TransactionDetails = (props) => {
  const {
    data,
    disclaimer,
    serialNumber,
    imeiNo,
    diagnositicData,
    piceaDetails,
    verifyDetails,
  } = props;

  const { t } = useTranslation();
  const { employeInfoEnabled, thirdPartyValue } = useGlobalValues();
  const { selectedLanguage, isArabic } = useGlobalLangValues();

  const handletrime = (value) => {
    return value?.match(/\d+/)[0];
  };

  const summary = [
    {
      title: t("Customer Details"),
      items: [
        { label: t("Customer Name"), value: disclaimer?.seller_name },
        { label: t("Phone Number"), value: data?.fill_form?.phone_number },
        {
          label: t("Address"),
          value: data?.fill_form?.address_line,
          className: "address",
        },
      ],
    },
    {
      title: t("Order Summary"),
      items: [
        {
          label: t("Order Date"),
          value: moment(disclaimer?.date).locale("en").format("YYYY-MM-DD"),
        },
        {
          label: t("Payment Method"),
          value: data?.payment_gateway?.name?.en || "N/A",
        },
        {
          label: t("Total"),
          value:
            Number(data?.inspection?.[0]?.inspection_value)?.toFixed(2) +
            " " +
            data?.currency?.code,
        },
      ],
    },
  ];

  if (employeInfoEnabled) {
    summary.push({
      title: t("Employee Details"),
      items: [
        {
          label: t("Employee Name"),
          value: data?.fill_form?.employee_name
            ? data?.fill_form?.employee_name
            : t("no data"),
        },
        {
          label: t("Employee Code"),
          value: data?.fill_form?.employee_code
            ? data?.fill_form?.employee_code
            : t("no data"),
        },
      ],
    });
  }

  return (
    <div
      className={
        isArabic
          ? "Order_detailsDisclaimer marginButton pdleft6"
          : " Order_detailsDisclaimer marginButton pdleft5"
      }
    >
      <div
        className={
          isArabic ? "arabic-component Items_summary" : "Items_summary"
        }
      >
        {data?.inspection?.map((item, index) => (
          <table
            className={!index ? "tableDisclaimer" : "tableDisclaimer printHide"}
            key={index}
          >
            <thead className="thead">
              <tr>
                <td
                  className={
                    isArabic ? "arabic-component h1 h6-right h1-arabic" : "h1"
                  }
                >
                  {t("Sales Details")}
                </td>
                <td></td>
              </tr>
              <tr>
                <td
                  className={isArabic ? "arabic-component h6 h6-right" : "h6"}
                >
                  {t("Items Summary")}
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>
                  {item?.item?.product?.name?.[selectedLanguage]
                    ? item?.item?.product?.name?.[selectedLanguage]
                    : item?.item?.product?.name?.en}
                </th>
                <th>
                  {`${data?.currency?.code} ${Number(
                    item?.inspection_value
                  )?.toFixed(2)}`}
                </th>
              </tr>
              <tr>
                <td>{t("Inspection ID")}</td>
                <td>{item?.id}</td>
              </tr>
              <tr>
                <td>{t("Barcode")}</td>
                <td className="barcode">
                  <Barcode
                    value={data?.barcode}
                    textPosition={"left"}
                    fontSize={10}
                    height={25}
                    margin={0}
                  />
                </td>
              </tr>
              {thirdPartyValue === 1 && (
                <tr>
                  <td>{t("coupon code")}</td>
                  <td
                    className="barcode"
                    style={{
                      border: "1px solid #ff0000",
                      padding: "10px 10px",
                      borderStyle: "dashed",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Barcode
                      value={handletrime(data?.third_party[0]?.value)}
                      textPosition={"left"}
                      fontSize={10}
                      height={25}
                      margin={0}
                    />
                  </td>
                </tr>
              )}
              {thirdPartyValue === 1 && (
                <tr>
                  <td></td>
                  <td
                    style={{
                      color: "#ff0000",
                      fontSize: "12px",
                      textTransform: "none",
                    }}
                  >
                    {t("Scan the coupon barcode to redeem the gift card")}
                  </td>
                </tr>
              )}

              {data?.retailer_fill_form?.[0]?.promo_code ? (
                <tr>
                  <td>{t("Promo Code")}</td>
                  <td> {data?.retailer_fill_form?.[0]?.promo_code}</td>
                </tr>
              ) : null}
              <tr>
                <td>{t("Estimated Value")}</td>
                <td>
                  {`${data?.currency?.code} ${Number(
                    item?.inspection_value
                  )?.toFixed(2)}`}
                </td>
              </tr>
              <tr>
                <td>{t("Brand")}</td>
                <td>
                  {" "}
                  {item?.item?.product?.brand?.name?.[selectedLanguage]
                    ? item?.item?.product?.brand?.name?.[selectedLanguage]
                    : item?.item?.product?.brand?.name?.en}{" "}
                </td>
              </tr>
              <tr>
                <td>{t("Category")}</td>
                <td>
                  {" "}
                  {item?.item?.product?.category?.name?.[selectedLanguage]
                    ? item?.item?.product?.category?.name?.[selectedLanguage]
                    : item?.item?.product?.category?.name?.en}
                </td>
              </tr>
              <tr>
                <td>{t("Product")}</td>
                <td>
                  {" "}
                  {item?.item?.product?.name?.[selectedLanguage]
                    ? item?.item?.product?.name?.[selectedLanguage]
                    : item?.item?.product?.name?.en}
                </td>
              </tr>
              {employeInfoEnabled && serialNumber ? (
                <tr>
                  <td>{t("Serial Number")}</td>
                  <td> {serialNumber}</td>
                </tr>
              ) : (
                " "
              )}
              {imeiNo ? (
                <tr>
                  <td>{t("IMEI")}</td>
                  <td> {imeiNo}</td>
                </tr>
              ) : (
                " "
              )}
              {item?.attribute_value?.map((attribute) => (
                <tr key={attribute?.id}>
                  <td>
                    {" "}
                    {attribute?.attribute?.name?.[selectedLanguage]
                      ? attribute?.attribute?.name?.[selectedLanguage]
                      : attribute?.attribute?.name?.en}
                  </td>
                  <td>
                    {" "}
                    {attribute?.name?.[selectedLanguage]
                      ? attribute?.name?.[selectedLanguage]
                      : attribute?.name?.en}
                  </td>
                </tr>
              ))}

              {item?.question_answer?.map((question) => (
                <tr key={question.id}>
                  <td>
                    {" "}
                    {question?.question?.question?.[selectedLanguage]
                      ? question?.question?.question?.[selectedLanguage]
                      : question?.question?.question?.en}
                  </td>
                  <td>
                    {" "}
                    {question?.answer?.[selectedLanguage]
                      ? question?.answer?.[selectedLanguage]
                      : question?.answer?.en}
                  </td>
                </tr>
              ))}
              {piceaDetails || verifyDetails || diagnositicData ? (
                <tr>
                  <th>
                    <b>{t("Auto Inspection Details")}</b>
                  </th>
                  <th></th>
                </tr>
              ) : (
                ""
              )}

              {piceaDetails?.device &&
                Object.entries(piceaDetails?.device).map(
                  ([key, value], index) =>
                    value != null ? (
                      <tr key={index}>
                        <td> {key}</td>
                        <td> {value}</td>
                      </tr>
                    ) : (
                      ""
                    )
                )}

              {verifyDetails &&
                verifyDetails.map((item, index) =>
                  index == 0 ? (
                    ""
                  ) : (
                    <tr key={item.id}>
                      <td> {item?.test.replace(/:/g, "")}</td>
                      <td> {item?.result}</td>
                    </tr>
                  )
                )}
              {diagnositicData &&
                diagnositicData.map((item, index) =>
                  index == 0 ? (
                    ""
                  ) : (
                    <tr key={item.id}>
                      <td> {item?.test}</td>
                      <td> {item?.result}</td>
                    </tr>
                  )
                )}
            </tbody>
          </table>
        ))}
      </div>
      <div
        className={
          isArabic
            ? "arabic-component Details_row"
            : thirdPartyValue === 1
            ? "Details_row pagebreakbefor"
            : employeInfoEnabled
            ? "Details_row pagebreakbefor"
            : "Details_row"
        }
      >
        {summary?.map((item, index) => (
          <div className="Summary" key={index}>
            <h4>{item?.title}</h4>
            <div className="Info">
              {item?.items?.map((body, index) => (
                <div className={"Sub_row "} key={index}>
                  <p className="Left">{body?.label}</p>
                  <p className={body?.className || null}>{body?.value}</p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TransactionDetails;
