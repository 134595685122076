import { api } from "./main";

export const getLiveRate = (payload) => {
  return api
    .get("/apilayer-live", { params: payload })
    .then((res) => res)
    .catch((err) => console.error(err));
};


export const getCurrencyFromDb = async (id) => {
  return await api
    .get(`/retailer/get-currency-conversion-rate/${id}`)
    .then((res) => res)
    .catch((err) => console.error(err));
};