import React, { Component, useRef, useState, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "../button/Button";
import "./style.scss";

const Esigniture = () => {
  const sigRef = useRef();
  const [loading, setloading] = useState(false);
  const [signature, setSignature] = useState(null);
  const handleSignatureEnd = () => {
    setSignature(sigRef.current.toDataURL());
  };
  const clearSignature = () => {
    sigRef.current.clear();
    setSignature(null);
  };

  console.log(signature ? signature : "no data");

  useEffect(() => {
    console.log(signature);
  }, [signature]);

  return (
    <div className="Esig">
      <SignatureCanvas
        penColor="green"
        canvasProps={{ className: "signature" }}
        ref={sigRef}
        onEnd={handleSignatureEnd}
      />
      <Button onClick={clearSignature} name="Clear" loading={loading} />
    </div>
  );
};
export default Esigniture;
