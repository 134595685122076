import React, { useContext, useState, useEffect } from "react";
import "./ChangePassword.scss";
import * as UsersApi from "../../../../core/apis/users";
import Button from "../../../../components/button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { IconButton, InputAdornment } from "@mui/material";
import DialogComponent from "../../../../components/modal/Modal";
import { FormInput } from "../../../../components/formComponents/FormComponents";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { AlertContext } from "../../../../context/AlertContext";
import { useTranslation } from "react-i18next";

const changePasswordSchema = yup.object({
  current_password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  password: yup
    .string("Enter your password")
    .min(6, "Password should be of minimum 6 characters length")
    .required("Password is required"),
  password_confirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match"),
});
const ChangePassword = () => {
  const { setAlert } = useContext(AlertContext);
  const [loading, setloading] = useState(false);
  const [open, setOpen] = useState(false);
  const [pass, setPass] = useState({ 0: false, 1: false, 2: false });
  const handleShow = (index) => setPass({ ...pass, [index]: !pass[index] });
  const handleMouseDownPassword = (event) => event.preventDefault();
  const handleOpenClose = () => setOpen(!open);
  const { i18n, t } = useTranslation();
  const [arabicComponent, setArabicComponent] = useState(false); // State to track Arabic language

  const inputs = [
    { label: t("Current Password"), name: "current_password" },
    { label: t("New Password") + "*", name: "password" },
    { label: t("Confirm New Password"), name: "password_confirmation" },
  ];

  const formiks = useFormik({
    initialValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    validationSchema: changePasswordSchema,
    onSubmit: () => {
      setOpen(true);
    },
  });
  const handleModal = async () => {
    setloading(true);
    await UsersApi.changePassword(formiks.values).then((res) => {
      setAlert({
        visible: true,
        type: res.data.success ? "success" : "error",
        text: res.data.message,
      });
      setloading(false);
      res.data.success && formiks.resetForm();
    });
    setOpen(false);
  };
  // Effect to set Arabic component class
  useEffect(() => {
    setArabicComponent(i18n.language === "ar");
  }, [i18n.language]);

  return (
    <form
      className={`justify-content-center change_password ${
        arabicComponent ? "arabic-component" : ""
      }`}
      onSubmit={formiks.handleSubmit}
    >
      {inputs.map((item, index) => (
        <FormInput
          key={index}
          label={item.label}
          type={pass?.[index] ? "text" : "password"}
          placeholder={`${t("Enter")} ${item.label}`}
          name={item.name}
          id={item.name}
          value={formiks.values[item.name]}
          onChange={formiks.handleChange}
          error={
            formiks.touched[item.name] && Boolean(formiks.errors[item.name])
          }
          helperText={formiks.touched[item.name] && formiks.errors[item.name]}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => handleShow(index)}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {pass[index] ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      ))}
      <Button
        className="submit"
        name={t("Submit")}
        type="submit"
        width="30%"
        selected
        disabled={!formiks.dirty}
        onClick={formiks.isValid && handleOpenClose}
      />
      <DialogComponent
        open={open}
        handleOpenClose={handleOpenClose}
        buttonLabel="Change"
        loading={loading}
        handleModal={handleModal}
      >
        {t("Are you sure you want to change your password?")}
      </DialogComponent>
    </form>
  );
};
export default ChangePassword;
