import React, { useState, useEffect } from "react";
import "./BuyBack.scss";
import { useNavigate } from "react-router";
// Common Component
import Button from "../button/Button";
// Slider
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Api
import { getallretailerhomepagecards } from "../../core/apis/buyback";
// Language
import { useTranslation } from "react-i18next";
import { useGlobalLangValues } from "../../context/LanguageContext";
// Images
import NextArrow from "../../assests/Images/Next-arrow.png";
import PreArrow from "../../assests/Images/Pre-arrow.png";
import loaderSpinner from "../../assests/Images/loaderspinner.png";
import StartIcon from "@mui/icons-material/Start";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import FormatIndentDecreaseIcon from "@mui/icons-material/FormatIndentDecrease";
import FormatIndentIncreaseIcon from "@mui/icons-material/FormatIndentIncrease";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";

const CustomPrevArrow = (props) => (
  <img
    src={PreArrow}
    alt="Previous"
    className={`slick-arrow ${props.className}`}
    style={{
      ...props.style,
      display: props.currentSlide === 0 ? "none" : "block",
    }}
    onClick={props.onClick}
  />
);

const CustomNextArrow = (props) => (
  <img
    src={NextArrow}
    alt="Next"
    className={`slick-arrow ${props.className}`}
    style={{
      ...props.style,
      display: props.currentSlide === props.slideCount - 1 ? "none" : "block",
      width: "48px",
      height: "48px",
    }}
    onClick={props.onClick}
  />
);

function NewBuyBack(props) {
  const { t } = useTranslation();
  const { selectedLanguage, isArabic } = useGlobalLangValues();

  const [retailerCards, setRetailerCards] = useState([]);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const fetchDetails = async () => {
      setLoading(true);
      try {
        const response = await getallretailerhomepagecards();
        const sortedRetailerCards = response.data.data.sort(
          (a, b) => a.order - b.order
        );
        setRetailerCards([...sortedRetailerCards]);
      } catch (error) {
        console.error("Error fetching retailer cards:", error);
      } finally {
        setTimeout(() => {
          setLoading(false);
        }, 400);
      }
    };

    fetchDetails();
  }, []);

  const modifiedRetailerCards = retailerCards.map((item, index) => {
    const modifiedItem = { ...item };
    switch (index) {
      case 1:
        modifiedItem.image = process.env.REACT_APP_IMAGE_URL + "/" + item?.icon;
        break;
      case 2:
        modifiedItem.image = process.env.REACT_APP_IMAGE_URL + "/" + item?.icon;
        break;
      case 3:
        modifiedItem.image = process.env.REACT_APP_IMAGE_URL + "/" + item?.icon;
        break;
      case 4:
        modifiedItem.image = process.env.REACT_APP_IMAGE_URL + "/" + item?.icon;
        break;
      default:
        modifiedItem.image = process.env.REACT_APP_IMAGE_URL + "/" + item?.icon;
    }
    return modifiedItem;
  });

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    fade: true,
    prevArrow: (
      <CustomPrevArrow className={isArabic ? "arabic-component" : ""} />
    ),
    nextArrow: (
      <CustomNextArrow className={isArabic ? "arabic-component" : ""} />
    ),
    appendDots: (dots) => (
      <div className="custom-dots">
        <ul> {dots} </ul>
      </div>
    ),
  };

  const handleSales = () => {
    navigate("/sales");
  };

  const handlestart = () => {
    navigate("/sell");
  };

  return (
    <div className="overLayContainer">
      <div className="container">
        {loading ? (
          <div>
            {" "}
            <div className="loadingContent">
              <img src={loaderSpinner} alt="spinner" className="spinner spin" />
            </div>
          </div>
        ) : (
          <Slider {...settings}>
            {modifiedRetailerCards?.map((item, index) => (
              <div key={index}>
                <div
                  className={`slide-content  ${
                    isArabic ? "arabic-component" : ""
                  }`}
                >
                  <div className="text">
                    <h3 className="slideNumber">{"0" + (index + 1)}</h3>
                    <h1>
                      {item?.title?.[selectedLanguage] || item?.title?.en}
                    </h1>
                    <p className="description">
                      {item?.description?.[selectedLanguage] ||
                        item?.description?.en}
                    </p>
                    <div className="buttons-wrapper">
                      <Button
                        name={t("Start")}
                        onClick={() => handlestart()}
                        className="minwidth150"
                        endicon={
                          isArabic ? (
                            <KeyboardDoubleArrowLeftIcon />
                          ) : (
                            <KeyboardDoubleArrowRightIcon />
                          )
                        }
                      />
                      <Button
                        name={t("Transactions")}
                        selected
                        className="minwidth150"
                        onClick={() => handleSales()}
                        endicon={
                          isArabic ? (
                            <StackedBarChartIcon />
                          ) : (
                            <StackedBarChartIcon />
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="image">
                    <img src={item?.image} alt={`Slide ${index + 1}`} />
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default NewBuyBack;
