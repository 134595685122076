import React, { useContext, useEffect, useState } from "react";
import { CurrencyContext } from "../../../context/CurrencyContext.js";
import { useGlobalValues } from "../../../context/GlobalContext.js";
import "./step-2-piceaFlow.scss";
import Button from "../../../components/button/Button.js";
import { ArrowSVG, CheckSVG } from "../../../assests/icons/SVG.js";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import * as ProductsApi from "../../../core/apis/products.js";
import * as estimate from "../../../core/apis/sellmydevice.js";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControlLabel,
  Radio,
} from "@mui/material";
import PriceRange from "../step-4-questions/priceRange/PriceRange.js";
import { CountryContext } from "../../../context/CountryContext.js";
import {
  getPiceaToken,
  getPiceaDeviceInfo,
  getworkflowByType,
} from "../../../core/apis/newapis.js";
import { AlertContext } from "../../../context/AlertContext.js";
import FinalEstimation from "../step-4-questions/finalEstimation/FinalEstimation.js";
import Swal from "sweetalert2";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";

const PiceaFlow = () => {
  let params = useParams();
  let { state } = useLocation();
  let navigate = useNavigate();

  const { country } = useContext(CountryContext);
  const { selectedLanguage, isArabic } = useGlobalLangValues();

  const {
    updateGlobalState,
    piceaOrganizationWorkFlow,
    setOrganizationPiceaWipeWorkflows,
    setOrganizationWipePiceaState,
    setOrganizationPiceaWorkflows,
    twoUseWorkFlowState,
    piceaOrganizationState,
  } = useGlobalValues();

  const [piceaData, setPiceadata] = useState({});
  const [deviceInfo, setdeviceInfo] = useState();
  const [completed, setTransactionCompleted] = useState(false);
  const [QnA, setQnA] = useState({});
  const [result, setResult] = useState();
  const [loading, setLoading] = useState(true);
  const { setAlert } = useContext(AlertContext);
  const [allQuestions, setAllQuestions] = useState([]);
  const [attributes, setAttributes] = useState();
  const [error, setError] = useState(false);
  const [screenValue, setScreenValue] = useState({ screen1: {} });
  const [step, setStep] = useState(1);
  const [page, setPage] = useState(true);
  const [isBreakable, setBreakable] = useState(false);
  const [attributeID, setattributeID] = useState();
  const [attributeValueID, setattributeValueID] = useState([]);

  const { t } = useTranslation();

  const incrementStep = () => setStep(step + 1);

  // Update values
  const handleUpdate = async (tx) => {
    await updateGlobalState({
      piceaValues: tx,
    });
    if (tx.data) {
      if (tx.status == 0) {
        getDeviceInfo(tx);
      } else if (tx.status == 2) {
        showErrorPopup(
          t(
            "Due to a test failure, the process has been declined. Please check your phone to find out why"
          )
        );
        await new Promise((resolve) => setTimeout(resolve, 5000));
        navigate("/sell");
      } else if (tx.status == 1) {
        navigate("/sell");
      }
    }
  };

  useEffect(() => {
    console.log(twoUseWorkFlowState, "twouseworkflowstate");
    console.log(piceaOrganizationState, "piceaOrganizationState");
    getWorkFlows();
    incrementStep();
  }, []);

  const getWorkFlows = () => {
    getworkflowByType(2).then((res) => {
      const filterByWorkflowType = (workflowTypeId) =>
        res.data.data.filter(
          (item) =>
            item.picea_workflow.picea_workflow_type.id === workflowTypeId
        );
      const retailerbuyBack = filterByWorkflowType(2);
      const wipePicea = filterByWorkflowType(1);
      setOrganizationPiceaWorkflows(retailerbuyBack);
      setOrganizationPiceaWipeWorkflows(wipePicea);
      if (wipePicea.length > 0) {
        setOrganizationWipePiceaState(true);
      }
      getToken(retailerbuyBack);
    });
  };

  const getQuestions = (value) => {
    ProductsApi.getProductEstimationQuestion(value).then((res) => {
      setAttributes(res.data?.attributes?.estimation_attributes);
      let filteredData = res.data?.questions?.[0].filter(
        (item) => item.picea_enabled === true
      );
      setAllQuestions(filteredData || []);
      setLoading(false);
      !res.success && setError(true);
    });
  };

  const onTransactionCompleted = async (tx) => {
    setPiceadata(tx);
    handleUpdate(tx);
    console.log(tx, "completedFLOW");
  };

  const getToken = (data) => {
    let payload = {
      picea_workflow_id: data[0]?.picea_workflow.picea_workflow_id,
    };
    let id = parseInt(data[0]?.picea_workflow.picea_workflow_id);

    getPiceaToken(payload).then((res) => {
      if (res?.data?.data?.token) {
        window.picea.run(
          { token: res?.data?.data?.token, autorun: id, responseVersion: 2 },
          onTransactionCompleted
        );
      }
    });
  };

  const getDeviceInfo = (tx) => {
    let payload = {
      category: params.category,
      piceaWidgetData: tx,
    };
    getPiceaDeviceInfo(payload).then((res) => {
      if (res.data.data.length != 0) {
        setdeviceInfo(res.data.data[0]);
        getQuestions(res.data.data[0].slug);
        getDeviceCapacityInfo(
          tx.data.device?.attributes?.capacity,
          res.data.data[0].attribute[0].attribute_value
        );
        localStorage.setItem("ModelName", res.data.data[0].slug);
      } else {
        showErrorPopup(
          t(
            "This phone was not found in our system. Please try again later or contact our help team."
          )
        );
      }
    });
  };
  //AD CONDTION FOR GB OT TB
  const getDeviceCapacityInfo = async (valueToFind, array) => {
    const matchingAttributeValue = array.find(
      (item) =>
        item.name.en.toLowerCase() === (valueToFind + " gb").toLowerCase() ||
        item.name.en.toLowerCase() === (valueToFind + " tb").toLowerCase()
    );
    console.log(matchingAttributeValue);
    if (matchingAttributeValue) {
      setattributeValueID(matchingAttributeValue?.id);
      setattributeID(matchingAttributeValue?.attribute_id);
      setTransactionCompleted(true);
    } else {
      showErrorPopup(
        t(
          "This phone Capacity was not found in our system. Please try again later or contact our help team."
        )
      );
    }
  };

  //handle Question values onClick: Radio Button
  const setScreen = async (item) => {
    setResult(); //reset evaluate value
    setScreenValue({ ...screenValue, ["screen" + step]: item.id });
    setQnA({ ...QnA, [item?.question_id]: item });
    setBreakable({ ...isBreakable, [step]: item.is_breakable });
    step !== allQuestions.length + 2 && incrementStep();
  };

  //final button: Evaluate
  const handleEvaluateContinue = () => {
    Object.values(screenValue).length === allQuestions.length + 1 &&
      screenValue[`screen${step - 1}`] !== (0 || undefined) &&
      Evaluate();
  };

  //Evaluate Function
  const Evaluate = () => {
    const payload = {
      country_id: country.id,
      product_id: deviceInfo.id,
      attributes_answers: [
        {
          attribute_id: attributeID,
          attribute_value_id: attributeValueID,
        },
      ],
      questions: Object.values(QnA).map((item) => ({
        question_id: item.question_id,
        answer_id: item.id,
      })),
    };
    if (piceaData.data.grade_name != "") {
      payload.picea_grade = piceaData?.data?.grade_name;
    } else {
      console.log(piceaData);
    }

    estimate.evaluate(payload).then((res) => {
      res.data.success
        ? setResult(res?.data?.data)
        : setAlert({ visible: true, type: "error", text: res.data.message });
    });
    setPage(false);
  };

  const showErrorPopup = (text) => {
    let timerInterval;
    Swal.fire({
      icon: "error",
      title: "Error !",
      html: text,
      timer: 5000,
      timerProgressBar: true,
      confirmButtonText: "continue",
      showClass: {
        popup: "swal2-show",
        backdrop: "swal2-backdrop-show",
        icon: "swal2-icon-show",
      },
      customClass: {
        container: "custom-swal-container",
        background: "custom-swal-background",
      },
      didOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {
          const timeLeft = Math.ceil(Swal.getTimerLeft() / 1000);
        }, 10);
      },
      willClose: () => {
        navigate("/sell");
      },
    });
  };

  return (
    <div className="main-sub-body">
      {completed ? (
        <div>
          <h1 className={isArabic ? " arabic-component" : " "}>
            {t("Questions")}
          </h1>
          <div className={isArabic ? "step4 arabic-component" : "step4 "}>
            <PriceRange
              country={country}
              loading={loading}
              product={deviceInfo}
              attributes={deviceInfo?.attribute[0]?.attribute_value}
              estimationvalues={deviceInfo?.attribute[0]?.attribute_value}
              attribute_values_id={[attributeValueID]}
            />
            <div className="Questions_box">
              <div className="QnA fade-in">
                <Accordion disableGutters sx={{ border: "none" }}>
                  <AccordionSummary sx={{ backgroundColor: "#C4C4C410" }}>
                    <h5>{t("Device Info")}</h5>

                    <p>{piceaData.data.device?.attributes?.capacity}</p>
                  </AccordionSummary>
                </Accordion>
                <Accordion
                  disableGutters
                  sx={{ border: "none" }}
                  expanded={step !== 1}
                >
                  <AccordionSummary
                    sx={{ backgroundColor: "#C4C4C410" }}
                    expandIcon={<ArrowSVG width="15" />}
                  >
                    {t("Answer the Questions")}
                  </AccordionSummary>
                  <AccordionDetails>
                    {allQuestions?.map((item, index) => (
                      <Accordion
                        key={index}
                        className="internalAccordion"
                        disableGutters
                        sx={{ border: "none" }}
                        expanded={step === index + 2}
                        disabled={
                          step < index + 2 &&
                          !Object.values(QnA)?.[index - 1]?.answer
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          (Object.values(QnA)?.[index - 1]?.answer || !index) &&
                            setStep(index + 2);
                        }}
                      >
                        <AccordionSummary>
                          <span>
                            <h5>{`${index + 1}. ${
                              item?.question?.[selectedLanguage]
                                ? item?.question?.[selectedLanguage]
                                : item?.question?.en
                            }`}</h5>
                            <p>
                              {Object.values(QnA)?.find(
                                (qna) => qna.question_id === item.id
                              )?.answer?.[selectedLanguage]
                                ? Object.values(QnA)?.find(
                                    (qna) => qna.question_id === item.id
                                  )?.answer?.[selectedLanguage]
                                : Object.values(QnA)?.find(
                                    (qna) => qna.question_id === item.id
                                  )?.answer?.en}
                            </p>
                          </span>
                          {Object.values(QnA)?.[index]?.answer ? (
                            <CheckSVG fill="#4ba4f0" />
                          ) : null}
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="Answers">
                            {item.answer
                              ?.sort((a, b) => a.id - b.id) // Sort answers based on _id
                              .map((answerItem) => (
                                <span
                                  className="spanContainer"
                                  key={answerItem.id}
                                >
                                  <FormControlLabel
                                    control={<Radio color="primary" />}
                                    onChange={() => setScreen(answerItem)}
                                    checked={
                                      screenValue["screen" + step] ===
                                      answerItem?.id
                                    }
                                    label={
                                      answerItem?.answer?.[selectedLanguage]
                                        ? answerItem?.answer?.[selectedLanguage]
                                        : answerItem?.answer?.en
                                    }
                                  />
                                  <p>
                                    {answerItem?.description?.[selectedLanguage]
                                      ? answerItem?.description?.[
                                          selectedLanguage
                                        ]
                                      : answerItem?.description?.en}
                                  </p>
                                </span>
                              ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    <div className="Buttons">
                      {!loading &&
                        Object.values(screenValue).length ===
                          allQuestions?.length + 1 && (
                          <Button
                            name={t("Evaluate")}
                            onClick={handleEvaluateContinue}
                            selected
                            width="30%"
                            disabled={
                              screenValue["screen" + (step - 1)] ===
                              (0 || undefined)
                            }
                          />
                        )}
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              <Dialog open={!page} onClose={() => setPage(true)}>
                <DialogContent
                  className={
                    isArabic
                      ? "estimationDialog arabic-component"
                      : "estimationDialog "
                  }
                >
                  <FinalEstimation
                    isBreakable={Object.values(isBreakable).some(
                      (item) => item === true
                    )}
                    handleClose={setPage}
                    price={result}
                    setResult={setResult}
                    state={{
                      attributes_answers: [
                        {
                          attribute_id: attributeID,
                          attribute_value_id: attributeValueID,
                        },
                      ],
                      questions: Object.values(QnA).map((item) => ({
                        question_id: item.question_id,
                        answer_id: item.id,
                      })),
                      price: result,
                      imei_1: "staticstatic",
                      attributes: attributeID,
                      picea_transaction_id: piceaData.data?.number,
                      picea_grade: piceaData?.data?.grade_name,
                      ...state,
                    }}
                    question={QnA}
                    data={allQuestions}
                    params={params}
                    isPicea={true}
                    deviceInfo={deviceInfo}
                  />
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Skeleton width="100%" height="80px" />
          <Skeleton width="100%" height="80px" />
          <Skeleton width="100%" height="80px" />
          <Skeleton width="100%" height="80px" />
          <Skeleton width="100%" height="80px" />
          <Skeleton width="100%" height="80px" />
          <Skeleton width="100%" height="80px" />
        </div>
      )}
    </div>
  );
};

export default PiceaFlow;
