import React, { Fragment, useContext, useEffect } from "react";
import { useState } from "react";
import "./employeeform.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import Button from "../../../components/button/Button";
import { FormInput } from "../../../components/formComponents/FormComponents";
import * as OTP from "../../../core/apis/PhoneVerification";
import { useGlobalLangValues } from "../../../context/LanguageContext";
import { useTranslation } from "react-i18next";

const EmployeeForm = (props) => {
  const { onSuccess } = props;
  const { selectedLanguage, isArabic } = useGlobalLangValues();
  const { t } = useTranslation();
  const [saveLoading, setSaveLoading] = useState(false);

  const formSchema = yup.object({
    employeeID: yup.string().nullable().required(t("Field is required")),
    employeeName: yup.string().nullable().required(t("Field is required")),
  });

  const formik = useFormik({
    initialValues: {
      employeeName: "",
      employeeID: "",
    },
    enableReinitialize: true,
    validationSchema: formSchema,
    onSubmit: (values) => {
      handleSubmitForm(values);
    },
  });

  const handleSubmitForm = async (formValues) => {
    onSuccess({ employeInfo: formValues });
  };

  return (
    <div className="employeeformContainer">
      <div className={isArabic ? " arabic-component pad-10  " : " "}>
        <form onSubmit={formik.handleSubmit}>
          <div className="row rowContainer ">
            <div>
              <FormInput
                required
                type="text"
                name="employeeName"
                placeholder={t("Full Name")}
                helperText={
                  formik.touched?.employeeName && formik.errors?.employeeName
                }
                value={formik?.values?.employeeName}
                onChange={formik.handleChange}
                label={t("Full Name")}
                fontweight="500"
                borderVal={true}
              />
            </div>
            <div>
              <FormInput
                required
                type="text"
                name="employeeID"
                placeholder={t("ID")}
                helperText={
                  formik.touched?.employeeID && formik.errors?.employeeID
                }
                value={formik?.values?.employeeID}
                onChange={formik.handleChange}
                label={t("ID")}
                fontweight="500"
                borderVal={true}
              />
            </div>
            <Button
              className="placeorder"
              name={t("Submit")}
              selected
              type="submit"
              disabled={saveLoading}
              loading={saveLoading}
              width="100%"
            />
          </div>
        </form>
      </div>
    </div>
  );
};
export default EmployeeForm;
