import { api } from "./main";

export const getOrderStatuses = () => {
  return api
    .get(`common/get-order-statuses`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getProductStatuses = () => {
  return api
    .get(`common/get-product-statuses`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
export const getTransactionStatuses = () => {
  return api
    .get(`get-all-transaction-statuses`)
    .then((res) => res)
    .catch((err) => console.error(err));
};
